import { Box, Stack, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { RiCheckboxBlankCircleFill } from 'react-icons/ri';
import { planPreviewTopBar_style } from './style';
import { ExpertNotePad } from '../../../atoms/icons/iconSet8';
import { DeleteIcon, EditIcon } from '../../../atoms';

function PlanPreviewTopBar(props) {
  const {
    className = '',
    rootStyle = {},
    plan,
    isDelete,
    duration,
    showOpr,
    isShowEditButton,
    onClick = () => {},
    ...rest
  } = props;

  const string = plan;
  const length = 35;
  const trimmedString = string?.substring(0, length);

  return (
    <Box
      sx={{
        ...planPreviewTopBar_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Box
        sx={{ cursor: 'pointer' }}
        display="flex"
        justifyContent="space-between"
        alignItems="end"
        height="40px"
      >
        <Stack flexDirection="row" alignItems="center">
          <ExpertNotePad rootStyle={{ color: '#5C6266' }} />
          <Typography sx={{ fontWeight: 600, color: '#0E1824' }} fontSize={12}>
            &nbsp; {plan?.length <= 30 ? plan : `${trimmedString} ...`} &nbsp;
          </Typography>
          <RiCheckboxBlankCircleFill
            style={{ fontSize: '6px', ml: 2, color: 'grey' }}
          />
          <Typography fontSize={10} sx={{ fontWeight: 400, color: '#5C6266' }}>
            &nbsp; {duration}
          </Typography>
        </Stack>
        {showOpr && (
          <Box
            id={isDelete ? 'delete' : 'edit'}
            sx={{
              background: isDelete ? ' #FEEAEA ' : '#F4F2FF',
              px: '4px',
              py: '2px',
              cursor: 'pointer',
              borderRadius: '4px',
              display: 'flex',
              alignItems: 'center',
            }}
            onClick={onClick}
          >
            <Box pr={0.5}>
              {isDelete ? (
                <DeleteIcon />
              ) : (
                <EditIcon
                  rootStyle={{
                    color: '#6552CC',
                    height: '12px',
                    width: '12px',
                  }}
                />
              )}
            </Box>

            <Typography
              sx={{
                color: isDelete ? '#F44F5A' : '#6552CC',
                ml: 0.2,
              }}
              fontSize={12}
              fontWeight={500}
            >
              {isDelete ? 'Delete' : 'Edit'}
            </Typography>
          </Box>
        )}
      </Box>
    </Box>
  );
}

PlanPreviewTopBar.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  plan: PropTypes.string,
  duration: PropTypes.string,
  isDelete: PropTypes.bool,
  showOpr: PropTypes.bool,
  isShowEditButton: PropTypes.bool,
  onClick: PropTypes.func,
};

export { PlanPreviewTopBar };
