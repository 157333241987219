import { ExpertLayout, ExpertVerification } from '@hc/ui/components';
import { Box } from '@mui/material';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import PropTypes from 'prop-types';
import React from 'react';

import { localStorageKeys, parseJwt } from '@hc/utils';
import Byme from './byMe';
import DefaultPlan from './default';
import { master_style } from './style';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{
        backgroundColor: '#F7F7F7 ',
        height: '70vh',
        overflow: 'hidden',
        borderRadius: '10px',
      }}
      {...other}
    >
      {value === index && <Box sx={{ p: 1.5 }}>{children}</Box>}
    </div>
  );
}
TabPanel.propTypes = {
  children: PropTypes.element,
  value: PropTypes.number,
  index: PropTypes.number,
};

export default function Master(props) {
  const { className = '', ...rest } = props;
  const authToken = localStorage.getItem(localStorageKeys.authToken);
  const tokenData = parseJwt(authToken);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <ExpertLayout appBarRequired footBarRequired rootStyle={{ px: 0 }}>
      <Box sx={master_style.root} className={`${className}`} {...rest}>
        <Box
          height={!tokenData?.is_verified ? '60vh' : ''}
          display="flex"
          bgcolor="#F7F7F7"
          justifyContent="center"
        >
          {!tokenData?.is_verified && (
            <Box alignSelf="center">
              <ExpertVerification />
            </Box>
          )}
        </Box>
        {tokenData?.is_verified && (
          <Box>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                backgroundColor: '#F7F7F7 ',
                mt: 1.3,
              }}
            >
              <Tabs
                sx={{ backgroundColor: '#F7F7F7 ' }}
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
                TabIndicatorProps={{
                  style: {
                    backgroundColor: ' rgb(101, 82, 204)',
                  },
                }}
                id="masterTab"
                data-active={value ? false : true}
              >
                <Tab
                  disableRipple
                  disableFocusRipple
                  label="Default"
                  id="tab-0"
                  aria-labelledby={`simple-tab-${0}`}
                  sx={master_style.tabButtonSx}
                />
                <Tab
                  disableRipple
                  disableFocusRipple
                  label="By me"
                  id="tab-1"
                  aria-labelledby={`simple-tab-${1}`}
                  sx={master_style.tabButtonSx}
                />
              </Tabs>
            </Box>

            <TabPanel value={value} index={0}>
              <Box>
                <DefaultPlan />
              </Box>
            </TabPanel>

            <TabPanel value={value} index={1}>
              <Byme />
            </TabPanel>
          </Box>
        )}
      </Box>
    </ExpertLayout>
  );
}

Master.propTypes = {
  className: PropTypes.string,
};
