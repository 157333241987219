export const expertRequestCard_style = {
  rootSx: {
    bgcolor: '#EEEEEE',
    borderRadius: '8px',
    cursor: 'pointer',
    border:'1px solid #EEEEEE',
    // boxShadow: 'rgba(149, 157, 165, 0.2) 0px 8px 24px',
  },
  requestCardRootSx: {
    bgcolor: '#fff',
    border: '1px solid #E3E3E3',
    borderRadius: '8px',
    p: 1.6,
    cursor: 'pointer',
  },
};
