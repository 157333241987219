/* eslint-disable prefer-const */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-dupe-else-if */
import { expertRoutes } from '@hc/routes';
import {
  useCreateAndAddActivity,
  useExpertRequest,
  useMasters,
  usePlanAddEditTool,
} from '@hc/store';
import { useByMePlan } from '@hc/store/expert/byMePlan';
import { useExpertDraft } from '@hc/store/expert/draft';
import {
  BrainIcon,
  Button,
  DataTabs,
  Drawer,
  ExpertNotePad,
  EyeIcon,
  FileSearchIllustration,
  FoodIcon,
  Input,
  Label,
  RuningIcon,
  ToggleButton,
} from '@hc/ui/atoms';
import {
  AddEditToolEmptyState,
  ExpertPlanCalendar,
  ExpertPlanCard,
  HomeEmptyState,
  PlanPreviewTopBar,
  Screenlayout,
} from '@hc/ui/components';
import { localStorageKeys, parseJwt, queryClient } from '@hc/utils';
import { Box, Divider, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { toast } from 'react-hot-toast';
import { MdAdd } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { plan_add_edit_tool_style } from './style';

export default function PlanAddEditTool(props) {
  const { className = '', ...rest } = props;
  const authToken = localStorage.getItem(localStorageKeys.authToken);
  const tokenData = parseJwt(authToken);
  const planAddEditToolStateData = localStorage.getItem(
    localStorageKeys.planAddEditToolState,
  );
  const planAddEditToolData = JSON.parse(planAddEditToolStateData);

  const navigate = useNavigate();

  const {
    planAddEditToolState,
    constructCalanderData,
    constructDayActivityData,
    getLocalStorage,
    upsertPublishOrDraftPlan,
    deletePlanData,
    onExpertNotesUpdate,
    onUpdateToolData,
    upsertplanTemplate,
    onDurationUpdation,
    draftLoading,
    publishLoading,
  } = usePlanAddEditTool(
    (state) => ({
      upsertplanTemplate: state.upsertplanTemplate,
      onUpdateToolData: state.onUpdateToolData,
      onDurationUpdation: state.onDurationUpdation,
      deletePlanData: state.deletePlanData,
      onExpertNotesUpdate: state.onExpertNotesUpdate,
      upsertPublishOrDraftPlan: state.upsertPublishOrDraftPlan,
      constructDayActivityData: state.constructDayActivityData,
      planAddEditToolState: state.planAddEditToolState,
      getLocalStorage: state.getLocalStorage,
      constructCalanderData: state.constructCalanderData,
      draftLoading: state.draftLoading,
      publishLoading: state.publishLoading,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const {
    userData,
    expert,
    isDraft,
    isHistory,
    isScratch,
    masterByDefault,
    masterByMe,
    error,
    calenderData,
    planData,
    planDurationData,
    overAllConstructedPlanData,
  } = planAddEditToolState;

  const { getPlanDurationType, mastersState } = useMasters(
    (state) => ({
      getPlanDurationType: state.getPlanDurationType,
      mastersState: state.mastersState,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const { planDurationType } = mastersState;

  const { onStateUpdate, clearCreateNewActivityData } = useCreateAndAddActivity(
    (state) => ({
      onStateUpdate: state.onStateUpdate,
      clearCreateNewActivityData: state.clearCreateNewActivityData,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const { getDraftDataLocalStorage } = useExpertDraft(
    (state) => ({
      getDraftDataLocalStorage: state.getDraftDataLocalStorage,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const { getRequestById } = useExpertRequest(
    (state) => ({
      getRequestById: state.getRequestById,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const [tabIndex, setTabIndex] = useState('1');
  const [viewOnlyState, setViewOnlyState] = useState(false);
  const [selectedValue, setSelectedValue] = useState(1);
  const [index, setIndex] = useState(0);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [cancelDrawer, setCancelDrawer] = useState(false);
  const [drawerData, setDrawerData] = useState({
    plan_name: planDurationData?.plan_name,
    duration_type: planDurationData?.duration_type,
    duration: planDurationData?.duration,
  });

  const appbarString =
    isDraft || isHistory
      ? userData?.name ?? ' '
      : userData?.mbf_user?.profile_data?.first_name?.length > 0
      ? userData?.mbf_user?.profile_data?.first_name
      : planDurationData?.plan_name;

  const length = 30;
  const trimmedString = appbarString?.substring(0, length);

  const { clearByMeState } = useByMePlan((state) => ({
    clearByMeState: state.clearByMeState,
  }));

  const appbarTitle =
    isDraft || isHistory
      ? userData?.name.length <= 30
        ? userData?.name
        : `${trimmedString} ...`
      : masterByDefault || masterByMe
      ? planDurationData?.plan_name?.length <= 30
        ? planDurationData?.plan_name
        : `${trimmedString} ...`
      : userData?.mbf_user?.profile_data?.first_name?.length > 0
      ? userData?.mbf_user?.profile_data?.first_name?.length <= 30
        ? userData?.mbf_user?.profile_data?.first_name
        : `${trimmedString} ...`
      : `${trimmedString} ...`;

  const breaks = [
    {
      label: (
        <Box display="flex">
          <BrainIcon color={tabIndex === '1' ? '#EE8438' : '#888888'} />
          <Typography alignSelf="center" ml="6px" fontSize={12}>
            Mind
          </Typography>
        </Box>
      ),
      value: '1',
    },
    {
      label: (
        <Box display="flex">
          <RuningIcon color={tabIndex === '2' ? '#EE8438' : '#888888'} />
          <Typography alignSelf="center" ml="6px" fontSize={12}>
            Body
          </Typography>
        </Box>
      ),
      value: '2',
    },
    {
      label: (
        <Box display="flex">
          <FoodIcon color={tabIndex === '3' ? '#EE8438' : '#888888'} />
          <Typography alignSelf="center" ml="6px" fontSize={12}>
            Food
          </Typography>
        </Box>
      ),
      value: '3',
    },
  ];

  const onCancel = () => {
    setCancelDrawer(false);
  };

  const onProceed = () => {
    navigate(expertRoutes?.editplan, {
      state: {
        isPlanEdit: true,
        isPublishPlan: false,
      },
    });
  };

  const onNotesClick = () => {
    const requestStateData = localStorage.getItem(
      localStorageKeys.requestState,
    );
    const requestData = JSON.parse(requestStateData);
    const arr = [
      {
        isValue: tokenData?.is_mind_expert,
        parentKey: 'mind',
        childKey: 'isAddNotes',
      },
      {
        isValue: tokenData?.is_body_expert,
        parentKey: 'body',
        childKey: 'isAddNotes',
      },
      {
        isValue: tokenData?.is_food_expert,
        parentKey: 'food',
        childKey: 'isAddNotes',
      },
    ];
    for (const obj of arr) {
      onExpertNotesUpdate(obj?.parentKey, obj?.childKey, obj?.isValue);
    }
    for (const obj of arr) {
      if (isDraft || isHistory) {
        if (userData?.[obj?.parentKey] === 'Completed') {
          onExpertNotesUpdate(obj?.parentKey, 'isAddNotes', false);
          onExpertNotesUpdate(obj?.parentKey, 'isNotesAdded', false);
          onExpertNotesUpdate(obj?.parentKey, 'isAddedByMe', false);
        }
      } else if (
        requestData?.requestDataById?.[obj?.parentKey] === 'Completed'
      ) {
        onExpertNotesUpdate(obj?.parentKey, 'isAddNotes', false);
        onExpertNotesUpdate(obj?.parentKey, 'isNotesAdded', false);
        onExpertNotesUpdate(obj?.parentKey, 'isAddedByMe', false);
      }
    }
    if (planAddEditToolData?.previuousExpertData?.noteData) {
      for (const data of planAddEditToolData?.previuousExpertData?.noteData) {
        onExpertNotesUpdate(
          data?.expert_category_id === 1
            ? 'mind'
            : data?.expert_category_id === 2
            ? 'body'
            : 'food',
          'data',
          planAddEditToolData?.expertNotes?.[
            data?.expert_category_id === 1
              ? 'mind'
              : data?.expert_category_id === 2
              ? 'body'
              : 'food'
          ]?.data
            ? planAddEditToolData?.expertNotes?.[
                data?.expert_category_id === 1
                  ? 'mind'
                  : data?.expert_category_id === 2
                  ? 'body'
                  : 'food'
              ]?.data
            : data?.expert_notes,
        );
        onExpertNotesUpdate(
          data?.expert_category_id === 1
            ? 'mind'
            : data?.expert_category_id === 2
            ? 'body'
            : 'food',

          'isNotesAdded',
          true,
        );
      }
    }
    navigate(expertRoutes?.expertNotes);
  };

  const onAddExistingClick = () => {
    navigate(expertRoutes.selectActivity, {
      state: {
        category_id: tabIndex,
      },
    });
  };

  const isValidToSave = () => {
    const errorCopy = JSON.parse(JSON.stringify(error));
    let isValid = true;

    // checking plan name
    if (!drawerData?.plan_name.length > 0) {
      isValid = false;
      errorCopy.plan_name = 'Please enter the plan name';
    } else {
      errorCopy.plan_name = '';
    }

    // checking plan duratio
    if (!drawerData?.duration_type > 0) {
      isValid = false;
      errorCopy.duration_type = 'Please select the duration type ';
    } else {
      errorCopy.duration_type = '';
    }

    // checking plan duration name
    if (!drawerData?.duration > 0) {
      isValid = false;
      errorCopy.duration = 'Please enter the duration';
    } else {
      errorCopy.duration = '';
    }
    onUpdateToolData('error', errorCopy);
    return isValid;
  };

  const previewPlanValidation = () => {
    let error = {
      isValid: true,
      message: '',
    };
    if (
      expert?.is_mind_expert &&
      !expert?.is_body_expert &&
      !expert?.is_food_expert
    ) {
      if (
        !overAllConstructedPlanData?.mind?.planDayData
          .map((val) => val.plan.length > 0)
          .filter((valid) => valid === true).length > 0
      ) {
        error.isValid = false;
        error.message = 'Please add any mind activity to proceed';
      } else {
        error.message = '';
      }
    } else if (
      expert?.is_body_expert &&
      !expert?.is_mind_expert &&
      !expert?.is_food_expert
    ) {
      if (
        !overAllConstructedPlanData?.body?.planDayData
          .map((val) => val.plan.length > 0)
          .filter((valid) => valid === true).length > 0
      ) {
        error.isValid = false;
        error.message = 'Please add any body activity to proceed';
      } else {
        error.message = '';
      }
    } else if (
      expert?.is_food_expert &&
      !expert?.is_body_expert &&
      !expert?.is_mind_expert
    ) {
      if (
        !overAllConstructedPlanData?.food?.planDayData
          .map((val) => val.plan.length > 0)
          .filter((valid) => valid === true).length > 0
      ) {
        error.isValid = false;
        error.message = 'Please add any food activity to proceed';
      } else {
        error.message = '';
      }
    } else if (
      expert?.is_mind_expert &&
      expert?.is_body_expert &&
      !expert?.is_food_expert
    ) {
      if (
        !overAllConstructedPlanData?.mind?.planDayData
          .map((val) => val.plan.length > 0)
          .filter((valid) => valid === true).length > 0 ||
        !overAllConstructedPlanData?.body?.planDayData
          .map((val) => val.plan.length > 0)
          .filter((valid) => valid === true).length > 0
      ) {
        error.isValid = false;
        error.message = 'Please add any mind or body activity to proceed';
      } else {
        error.message = '';
      }
    } else if (
      expert?.is_mind_expert &&
      expert?.is_food_expert &&
      !expert?.is_body_expert
    ) {
      if (
        !overAllConstructedPlanData?.mind?.planDayData
          .map((val) => val.plan.length > 0)
          .filter((valid) => valid === true).length > 0 ||
        !overAllConstructedPlanData?.food?.planDayData
          .map((val) => val.plan.length > 0)
          .filter((valid) => valid === true).length > 0
      ) {
        error.isValid = false;
        error.message = 'Please add any mind or food activity to proceed';
      } else {
        error.message = '';
      }
    } else if (
      expert?.is_body_expert &&
      expert?.is_food_expert &&
      !expert?.is_mind_expert
    ) {
      if (
        !overAllConstructedPlanData?.body?.planDayData
          .map((val) => val.plan.length > 0)
          .filter((valid) => valid === true).length > 0 ||
        !overAllConstructedPlanData?.food?.planDayData
          .map((val) => val.plan.length > 0)
          .filter((valid) => valid === true).length > 0
      ) {
        error.isValid = false;
        error.message = 'Please add any body or food activity to proceed';
      } else {
        error.message = '';
      }
    } else if (
      expert?.is_mind_expert &&
      expert?.is_body_expert &&
      expert?.is_food_expert
    ) {
      if (
        !overAllConstructedPlanData?.mind?.planDayData
          .map((val) => val.plan.length > 0)
          .filter((valid) => valid === true).length > 0 &&
        !overAllConstructedPlanData?.body?.planDayData
          .map((val) => val.plan.length > 0)
          .filter((valid) => valid === true).length > 0 &&
        !overAllConstructedPlanData?.food?.planDayData
          .map((val) => val.plan.length > 0)
          .filter((valid) => valid === true).length > 0
      ) {
        error.isValid = false;
        error.message = 'Please include an activity to continue';
      } else {
        error.message = '';
      }
    }
    return error;
  };

  const onUpdate = () => {
    const result = isValidToSave();
    if (result) {
      onDurationUpdation('plan_name', drawerData?.plan_name);
      onDurationUpdation('duration_type', drawerData?.duration_type);
      onDurationUpdation('duration', drawerData?.duration);
      setOpenDrawer(false);
    }
  };

  const onPriviewPlan = () => {
    const result = previewPlanValidation();
    if (result?.isValid) {
      navigate(expertRoutes?.editplan, {
        state: {
          isPlanEdit: false,
          isPublishPlan: true,
        },
      });
    } else {
      toast?.error(result?.message);
    }
  };

  const onSelect = (index) => {
    setIndex(index);
    setSelectedValue(index);
    constructDayActivityData(
      tabIndex === '1' ? 'mind' : tabIndex === '2' ? 'body' : 'food',
      index,
    );
  };

  const onSaveAndClose = async () => {
    if (!planAddEditToolState?.isHistory && !planAddEditToolState?.masterByMe) {
      queryClient.invalidateQueries({
        queryKey: [`plan-save-publish`],
      });
      const response = await upsertPublishOrDraftPlan(1);
      if (response?.code === 200) {
        localStorage.removeItem(localStorageKeys?.basicInfoState);
        localStorage.removeItem(localStorageKeys?.requestState);
        localStorage.removeItem(localStorageKeys?.planAddEditToolState);
        navigate(expertRoutes?.home);
      }
    } else if (planAddEditToolState?.masterByMe) {
      queryClient.invalidateQueries({
        queryKey: [`planTemplate-upsert-plan`],
      });
      const response = await upsertplanTemplate(1);
      if (response?.message === 'Plan Name Already Exist') {
        setOpenDrawer(true);
      } else if (response?.code === 200) {
        clearByMeState();
        navigate(expertRoutes?.customPlan, {
          state: {
            addtemplate: false,
          },
        });
      }
    } else {
      setCancelDrawer(true);
    }
  };

  const onDelete = (planId) => {
    deletePlanData(
      planId,
      planData?.day,
      tabIndex === '1' ? 'mind' : tabIndex === '2' ? 'body' : 'food',
    );

    onSelect(index);
    constructCalanderData(
      tabIndex === '1' ? 'mind' : tabIndex === '2' ? 'body' : 'food',
    );
  };

  const onEdit = (id) => {
    let obj = {};
    for (const data of planData?.plan) {
      if (id === data?.id) {
        obj = {
          id: data?.id,
          activity_name: data?.activity_name,
          activity_image: data?.activity_image,
          category_id: data?.category_id,
          goal_id: data?.goal_id,
          is_measurable: data?.is_measurable,
          quantity: data?.quantity,
          unit: data?.unit,
          goal_name: data?.goal_name,
          activity_days: data?.activity_days,
          activity_time: data?.activity_time,
          specific_time: data?.specific_time,
          activity_notes: data?.activity_notes,
          activity_references: data?.activity_references,
          activity_dont: data?.activity_dont,
          is_active: data?.is_active,
          activity_time_name: data?.activity_time_name,
          category_name: data?.category_name,
          unit_name: data?.unit_name,
          isNew: data?.isNew,
        };
        onStateUpdate('createNewActivityData', obj);
      }
    }

    navigate(expertRoutes?.addActivity, {
      state: { category_id: tabIndex, isEdit: true, isCustomEdit: false },
    });
  };

  const setTabIndexValue = () => {
    if (
      expert?.is_mind_expert &&
      !expert?.is_body_expert &&
      !expert?.is_food_expert
    ) {
      setTabIndex('1');
    } else if (
      expert?.is_body_expert &&
      !expert?.is_mind_expert &&
      !expert?.is_food_expert
    ) {
      setTabIndex('2');
    } else if (
      expert?.is_food_expert &&
      !expert?.is_body_expert &&
      !expert?.is_mind_expert
    ) {
      setTabIndex('3');
    } else if (
      expert?.is_mind_expert &&
      expert?.is_body_expert &&
      !expert?.is_food_expert
    ) {
      if (
        userData.mind === 'Completed' &&
        userData.body !== 'Completed' &&
        userData.food !== 'Completed'
      ) {
        setTabIndex('2');
      } else if (
        userData.mind === 'Completed' &&
        userData.body !== 'Completed' &&
        userData.food !== 'Completed'
      ) {
        setTabIndex('1');
      } else if (
        userData.mind !== 'Completed' &&
        userData.body !== 'Completed' &&
        userData.food === 'Completed'
      ) {
        setTabIndex('1');
      }
    } else if (
      expert?.is_mind_expert &&
      expert?.is_food_expert &&
      !expert?.is_body_expert
    ) {
      if (
        userData.mind === 'Completed' &&
        userData.body !== 'Completed' &&
        userData.food !== 'Completed'
      ) {
        setTabIndex('3');
      } else if (
        userData.food === 'Completed' &&
        userData.mind !== 'Completed' &&
        userData.body !== 'Completed'
      ) {
        setTabIndex('1');
      } else if (
        userData.mind !== 'Completed' &&
        userData.food !== 'Completed' &&
        userData.body === 'Completed'
      ) {
        setTabIndex('1');
      }
    } else if (
      expert?.is_body_expert &&
      expert?.is_food_expert &&
      !expert?.is_mind_expert
    ) {
      if (
        userData.body === 'Completed' &&
        userData.mind !== 'Completed' &&
        userData.food !== 'Completed'
      ) {
        setTabIndex('3');
      } else if (
        userData.food === 'Completed' &&
        userData.food !== 'Completed' &&
        userData.body !== 'Completed'
      ) {
        setTabIndex('2');
      } else if (
        userData.mind !== 'Completed' &&
        userData.food !== 'Completed' &&
        userData.body === 'Completed'
      ) {
        setTabIndex('2');
      }
    } else if (
      expert?.is_mind_expert &&
      expert?.is_body_expert &&
      expert?.is_food_expert
    ) {
      if (
        userData.mind === 'Completed' &&
        userData.body !== 'Completed' &&
        userData.food !== 'Completed'
      ) {
        setTabIndex('2');
      } else if (
        userData.body === 'Completed' &&
        userData.mind !== 'Completed' &&
        userData.food !== 'Completed'
      ) {
        setTabIndex('1');
      } else if (
        userData.food === 'Completed' &&
        userData.food !== 'Completed' &&
        userData.body !== 'Completed'
      ) {
        setTabIndex('1');
      } else if (
        userData.mind === 'Completed' &&
        userData.body === 'Completed' &&
        userData.food !== 'Completed'
      ) {
        setTabIndex('3');
      } else if (
        userData.mind === 'Completed' &&
        userData.food === 'Completed' &&
        userData.body !== 'Completed'
      ) {
        setTabIndex('2');
      } else if (
        userData.body === 'Completed' &&
        userData.food === 'Completed' &&
        userData.mind !== 'Completed'
      ) {
        setTabIndex('1');
      }
    }
  };

  const handleChange = (value, limit) => {
    if (limit) {
      if (value.length > limit) {
        return;
      }
    }
    // HANDLE PROFILE DATA CAHNGE
    setDrawerData({
      ...drawerData,
      duration: value,
    });
  };

  const onViewProfile = async (planId) => {
    const response = await getRequestById(planId, {}, 'profile');
    if (response?.code === 200) {
      navigate(expertRoutes?.userProfile);
    }
  };

  const getPlanDurationMasterData = async () => {
    await getPlanDurationType();
  };

  useEffect(() => {
    getLocalStorage();

    if (tabIndex === '1') {
      if (!tokenData?.is_mind_expert) {
        setViewOnlyState(true);
      } else if (userData.mind === 'Completed') {
        setViewOnlyState(true);
      } else {
        setViewOnlyState(false);
      }
      constructCalanderData('mind');
      setSelectedValue(0);
      constructDayActivityData('mind', 0);
    } else if (tabIndex === '2') {
      if (!tokenData?.is_body_expert) {
        setViewOnlyState(true);
      } else if (userData.body === 'Completed') {
        setViewOnlyState(true);
      } else {
        setViewOnlyState(false);
      }
      constructCalanderData('body');
      setSelectedValue(0);
      constructDayActivityData('body', 0);
    } else if (tabIndex === '3') {
      if (!tokenData?.is_food_expert) {
        setViewOnlyState(true);
      } else if (userData.food === 'Completed') {
        setViewOnlyState(true);
      } else {
        setViewOnlyState(false);
      }
      constructCalanderData('food');
      setSelectedValue(0);
      constructDayActivityData('food', 0);
    }
  }, [tabIndex]);

  const getWhichExpert = () => {
    const obj = {
      is_mind_expert: tokenData?.is_mind_expert,
      is_body_expert: tokenData?.is_body_expert,
      is_food_expert: tokenData?.is_food_expert,
    };

    onUpdateToolData('expert', obj);
  };

  useEffect(() => {
    getLocalStorage();
    setTabIndexValue();
    getPlanDurationMasterData();
    getDraftDataLocalStorage();
    clearCreateNewActivityData();
    if (Object.keys(expert).length === 0) {
      getWhichExpert();
    }
  }, []);

  return (
    <Screenlayout
      title={appbarTitle}
      backRequired
      backRoute={
        isDraft
          ? expertRoutes.home
          : isScratch
          ? expertRoutes?.basicInfoSteper
          : expertRoutes.editplan
      }
      stateValue={{
        state: {
          isPlanEdit: false,
          isPublishPlan: false,
        },
      }}
      viewProfile={
        planAddEditToolState?.masterByDefault
          ? false
          : planAddEditToolState?.masterByMe
          ? false
          : true
      }
      onViewProfile={() =>
        onViewProfile(
          isHistory || isDraft
            ? userData?.plan_id ?? userData?.id
            : userData?.id,
        )
      }
      notshowFooter={viewOnlyState}
      isExpertAppBar
      stepperRootStyle={plan_add_edit_tool_style.steperChildRootSx}
      stepperformWithAppbar
      footer={
        <Box>
          <Grid container columnSpacing={2}>
            <Grid item xs={6} sm={6} md={6}>
              <Button
                onClick={() => onSaveAndClose()}
                sx={plan_add_edit_tool_style.buttonSx}
                loading={draftLoading}
              >
                {planAddEditToolState?.isHistory ? 'Cancel' : '  Save & Close'}
              </Button>
            </Grid>
            <Grid item xs={6} sm={6} md={6}>
              <Button
                onClick={() => onPriviewPlan()}
                buttonStyle={{ textTransform: 'capitalize' }}
                loading={publishLoading}
              >
                Preview Plan
              </Button>
            </Grid>
          </Grid>
        </Box>
      }
      stepperChildren={
        <PlanPreviewTopBar
          showOpr
          onClick={() => {
            setOpenDrawer(true);
          }}
          plan={planDurationData?.plan_name}
          isShowEditButton
          isDelete={false}
          duration={`${planDurationData?.duration} ${planDurationData?.duration_type}`}
        />
      }
      appBarFirstChild={
        <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
          <DataTabs
            isResponsive
            tabs={breaks}
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
            totalStyle={plan_add_edit_tool_style.tabStyleSx}
            underTabSx={plan_add_edit_tool_style.underTabStyleSx}
            tabStyle={plan_add_edit_tool_style.tabSx}
          />
        </Box>
      }
      appbarSecChild={
        <Box>
          <ExpertPlanCalendar
            onSelect={onSelect}
            value={selectedValue}
            data={calenderData}
          />
        </Box>
      }
      viewOnlyChid={
        viewOnlyState && (
          <Box sx={plan_add_edit_tool_style.viewOnlyBoxSx}>
            <Typography alignSelf="center" fontSize={12} color="#FACC15">
              <EyeIcon /> View Only
            </Typography>
          </Box>
        )
      }
    >
      <Box
        sx={plan_add_edit_tool_style.rootSx}
        className={`${className}`}
        {...rest}
      >
        <Box
          pt={viewOnlyState ? 5 : 2}
          px={2.5}
          pb={4}
          width="100%"
          display="flex"
          justifyContent="center"
        >
          {planData?.plan?.length > 0 ? (
            <Box width="100%">
              {planData?.plan?.map((val, i) => (
                <Box key={i}>
                  <ExpertPlanCard
                    data={val}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    viewOnly={
                      tabIndex === '1'
                        ? !tokenData?.is_mind_expert
                          ? true
                          : userData?.mind === 'Completed'
                          ? true
                          : false
                        : tabIndex === '2'
                        ? !tokenData?.is_body_expert
                          ? true
                          : userData?.body === 'Completed'
                          ? true
                          : false
                        : tabIndex === '3'
                        ? !tokenData?.is_food_expert
                          ? true
                          : userData?.food === 'Completed'
                          ? true
                          : false
                        : ''
                    }
                  />
                </Box>
              ))}
            </Box>
          ) : (
            <Box>
              {!viewOnlyState && (
                <AddEditToolEmptyState onClick={() => onAddExistingClick()} />
              )}
            </Box>
          )}
        </Box>
        {!viewOnlyState && (
          <Box sx={{ display: 'flex', justifyContent: 'end', p: 2.5 }}>
            <Box
              sx={{
                position: 'fixed',
                bottom: 110,
                display: 'flex',
                justifyContent: 'end',
              }}
            >
              <Box
                id="add"
                sx={{ ...plan_add_edit_tool_style.speedDialRootSx }}
                onClick={() => onAddExistingClick()}
              >
                <Box alignSelf="center">
                  <MdAdd
                    color="#fff"
                    style={{
                      marginTop: '5px',
                      height: '35px',
                      width: '35px',
                    }}
                  />
                </Box>
              </Box>
            </Box>
          </Box>
        )}
        {false && (
          <HomeEmptyState
            illustartion={<FileSearchIllustration />}
            title="No activities found"
          />
        )}
        {!masterByMe && (
          <Box>
            {!viewOnlyState && (
              <Box sx={{ position: 'fixed', bottom: 110 }}>
                <Box
                  sx={{
                    border: '1px solid #6552CC',
                    borderLeft: 0,
                    borderRadius: '0px 25px 25px 0px',
                    height: '40px',
                    width: '80px',
                    bgcolor: '#F4F2FF',
                    boxShadow: '0px 0px 10px #00000029',
                    display: 'flex',
                    justifyContent: 'center',
                    cursor: 'pointer',
                  }}
                  onClick={() => onNotesClick()}
                >
                  <ExpertNotePad
                    rootStyle={{ color: '#6552CC', mr: 1, mt: '10.4px' }}
                  />
                  <Typography
                    alignSelf="center"
                    mb={0.2}
                    fontSize={12}
                    color="#6552CC"
                  >
                    Notes
                  </Typography>
                </Box>
              </Box>
            )}
          </Box>
        )}
      </Box>
      {/* Plan from scratch drawer */}

      <Drawer
        show={openDrawer}
        rootStyle={plan_add_edit_tool_style.drawerSx}
        header="Plan from scratch"
        drawerRightClose
        childrenStyle={{ p: 0 }}
        onCloseDrawer={() => setOpenDrawer(false)}
        headerStyle={{
          fontSize: '16px',
          fontWeight: 600,
          color: '#101010',
          textTransform: 'capitalize',
        }}
        closeStyle={{ color: 'grey', height: '14px', width: '12px' }}
        footer={
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button
              onClick={() => setOpenDrawer(false)}
              // disabled={loading === true}
              buttonStyle={plan_add_edit_tool_style.cancelBtnSx}
            >
              Cancel
            </Button>
            <Button
              onClick={() => onUpdate()}
              // loading={loading}
              buttonStyle={plan_add_edit_tool_style.applyBtnSx}
            >
              Update
            </Button>
          </Box>
        }
      >
        <Box p={2}>
          <Grid container>
            <Grid item xs={12} pb={2} sm={12} md={12}>
              <Label>Name of the plan</Label>
              <Box mt={0.5}>
                <Input
                  id="planName"
                  value={drawerData?.plan_name}
                  onChange={(e) =>
                    setDrawerData({ ...drawerData, plan_name: e.target?.value })
                  }
                  placeholder="Give a name to identify in future"
                  isError={error?.plan_name?.length > 0}
                  errorMessage={error?.plan_name}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Label>Plan Duration</Label>
              <Box display="flex">
                <Box width={161} mt={0.5}>
                  <Input
                    id="planDuration"
                    value={drawerData?.duration}
                    placeholder="Type duration"
                    type="number"
                    onChange={(e) => handleChange(e.target.value, 2)}
                    isError={error?.duration?.length > 0}
                    errorMessage={error?.duration}
                  />
                </Box>
                <Box px={0.5}>
                  <Divider
                    orientation="vertical"
                    sx={{ height: '32px', mt: 1 }}
                  />
                </Box>

                <Box>
                  <Grid container display="flex" columnSpacing={1}>
                    <Grid item>
                      <ToggleButton
                        options={planDurationType}
                        value={drawerData?.duration_type}
                        RootStyle={{ overflow: 'scroll' }}
                        buttonactiveStyle={
                          plan_add_edit_tool_style.buttonActiveNumSx
                        }
                        buttoninactiveStyle={
                          plan_add_edit_tool_style.buttonInactiveNumSx
                        }
                        onChange={(e) =>
                          setDrawerData({
                            ...drawerData,
                            duration_type: e,
                          })
                        }
                      />
                      {error?.duration_type.length > 0 && (
                        <Typography fontSize={12} color="	#ff3333">
                          {error?.duration_type}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Drawer>
      <Drawer
        show={cancelDrawer}
        rootStyle={plan_add_edit_tool_style.drawerSx}
        childrenStyle={{ p: 0 }}
        isStyledDrawer
        IsStyledDrawerContent="Are you sure you want to leave this page? Any unsaved changes will be lost."
        onCloseDrawer={() => setCancelDrawer(false)}
        headerStyle={{
          fontSize: '16px',
          fontWeight: 600,
          color: '#101010',
          textTransform: 'capitalize',
        }}
        footer={
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button
              onClick={onCancel}
              buttonStyle={plan_add_edit_tool_style.cancelBtnSx}
            >
              Cancel
            </Button>
            <Button
              onClick={onProceed}
              // loading={loading}
              buttonStyle={{ ...plan_add_edit_tool_style.isNotApplyButtonSx }}
            >
              Proceed
            </Button>
          </Box>
        }
      />
    </Screenlayout>
  );
}

PlanAddEditTool.propTypes = {
  className: PropTypes.string,
};
