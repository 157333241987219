export const home_style = {
  tabStyleSx: {
    backgroundColor: 'transparent',
    width: ' 100%',
    margin: '0px auto',
    display: 'flex',

    borderBottom: '2px solid #EBFAF7',
    '& .MuiTabs-root': {
      minHeight: 0,
    },
  },
  promptSx: {
    position: 'fixed',
    top: 48,
    right: 0,
    left: 0,
    maxWidth: 425,
    marginLeft: 'auto',
    marginRight: 'auto',
    backgroundColor: 'white',
    zIndex: 1000,
  },
  cardListSx: {
    pt: 7,
  },
  underTabStyleSx: {
    '& .MuiTabs-indicator': {
      backgroundColor: 'primary.main',
      textAlign: 'left',
    },
    '& .MuiTabs-flexContainer': {
      display: 'grid',
      gridTemplateColumns: '128px 107px 38px',
      boxSizing: 'border-box',
      width: 425,
      mx: 0.5,
      gridColumnGap: '30px',
      textAlign: 'left',
    },
  },
  numberCountSx: {
    width: '24px',
    height: '24px',
    fontSize: '14px',
  },
  tabSx: {
    textTransform: 'capitalize',
    // minHeight: 0,

    textAlign: 'left',
    color: '#808080',
    whiteSpace: 'nowrap',
    fontWeight: 500,
    '&.Mui-selected': {
      textAlign: 'left',
      fontSize: '14px',
      fontWeight: 600,
    },
  },
  drawerSx: {
    '& .MuiDrawer-paperAnchorBottom': {
      boxSizing: 'border-box',
      maxWidth: {
        sm: 428,
      },
      right: 1,
      mx: {
        sm: 'auto',
      },
      borderRadius: '24px 24px 0px 0px',
    },
    p: 2.5,
  },
  cancelBtnSx: {
    textTransform: 'none',
    backgroundColor: '#F3F0FF',
    color: 'primary.main',
    '&:hover': {
      backgroundColor: '#F3F0FF',
    },
  },
  applyBtnSx: {
    textTransform: 'none',
  },
  suggestionSx: {
    display: 'flex',
    flexWrap: 'wrap',
  },

  buttonActiveNumSx: {
    border: '1px solid',
    width: 'max-content',

    minHeight: '40px',
    borderRadius: '10px',
    padding: '5px 10px',
    backgroundColor: '#EE8438',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#EE8438',
      color: '#fff',
    },
  },
  buttonInactiveNumSx: {
    border: '1px solid ',
    borderColor: '#E3E3E3',
    borderRadius: '10px',
    width: 'max-content',

    minHeight: '40px',
    padding: '5px 10px',
    color: '#5C6266',
    '&:hover': {
      backgroundColor: '#fff',
      color: '#5C6266',
    },
  },
};
