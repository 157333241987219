export const editPlan_style = {
  appBarStyleSx: {
    backgroundColor: 'purple.A100',
  },
  screenLayoutChildernSx: {
    pt: 3,
    pb: 8.5,
  },
  buttonActiveNumSx: {
    border: '2px solid #EE8438',
    width: 'max-content',
    minHeight: '40px',
    borderRadius: '10px',
    padding: '5px 10px',
    bgcolor: '#FFF6F0',
    color: '#EE8438',
    '&:hover': {
      backgroundColor: '#FFF6F0',
      color: '#EE8438',
    },
  },
  buttonInactiveNumSx: {
    border: '1px solid ',
    borderRadius: '10px',
    width: 'max-content',
    bgcolor: '#FFF6F0',
    minHeight: '40px',
    padding: '5px 10px',
    color: '#5C6266',
    '&:hover': {
      backgroundColor: '#FFF6F0',
      color: '#5C6266',
    },
  },
  drawerSx: {
    '& .MuiDrawer-paperAnchorBottom': {
      boxSizing: 'border-box',
      maxWidth: {
        sm: 428,
      },
      right: 1,
      mx: {
        sm: 'auto',
      },
      borderRadius: '24px 24px 0px 0px',
    },
    p: 2.5,
  },
  steperChildRootSx: {
    pt: 1.5,
    pb: 1.2,
    borderRadius: '0px 0px 16px 16px',
    border: '1px solid #E2E2E2',
    boxShadow: 0,
  },
  appBarFirstChildSx: {
    bgcolor: '#fff',
    zIndex: '2',
  },

  buttonSx: {
    background: ' #6552CC',
    borderRadius: '8px',
    width: '150px',
    color: ' #FFFFFF',
    '&:hover': {
      background: ' #6552CC',
    },
    textTransform: 'capitalize',
  },
  backbuttonSx: {
    width: '150px',
    background: '#F3F0FF',
    color: ' #6552CC',
    '&:hover': {
      background: '#F3F0FF',
    },
    textTransform: 'capitalize',
  },
  expertAppbarSx: {
    pl: 2.5,
    pr: 2.5,
    py: 2,
    boxSizing: 'border-box',
    bgcolor: '#fff',
    borderRadius: '0px 0px 24px 24px',
    boxShadow: '0px 0px 10px #00000029',
    position: 'fixed',
    top: '6%',
    zIndex: '1000',
    left: 0,
    right: 0,
    width: '100%',
    maxWidth: {
      sm: 425,
    },
    mx: {
      sm: 'auto',
    },
  },
  tabStyleSx: {
    backgroundColor: 'transparent',
    width: ' 100%',
    margin: '0px auto',
    display: 'flex',
    '& .MuiTabs-root': {
      minHeight: 0,
    },
  },
  underTabStyleSx: {
    '& .MuiTabs-indicator': {
      backgroundColor: '#EB6D13',
      textAlign: 'left',
    },
    '& .MuiTabs-flexContainer': {
      display: 'grid',
      gridTemplateColumns: ' 58px 60px 47px 0px',
      boxSizing: 'border-box',
      width: 425,

      gridColumnGap: '30px',
      textAlign: 'left',
    },
  },

  tabSx: {
    textTransform: 'capitalize',
    minHeight: 0,
    textAlign: 'left',
    color: '#808080',
    padding: '5px',
    fontWeight: 500,
    '&.Mui-selected': {
      textAlign: 'left',
      fontSize: '14px',
      fontWeight: 600,
      color: '#EB6D13',
      bgcolor: '#FFF6F0',
      borderRadius: '12px 12px 0px 0px',
    },
  },

  cancelBtnSx: {
    textTransform: 'none',
    backgroundColor: '#F3F0FF',
    color: 'primary.main',
    '&:hover': {
      backgroundColor: '#F3F0FF',
    },
  },
  applyBtnSx: {
    textTransform: 'none',
  },

  deleteBtnSx: {
    textTransform: 'none',
    bgcolor: '#F44F5A',
    '&:hover': {
      bgcolor: '#F44F5A',
    },
  },
  isNotApplyButtonSx: {
    textTransform: 'none',
    bgcolor: '#F44F5A',
    '&:hover': {
      bgcolor: '#F44F5A',
    },
  },
  drawerCenterBoxSx: {
    bgcolor: '#FCF8E8',
    borderRadius: '50%',
    height: '56px',
    width: '56px',
    border: '2px solid #FBE27F',
    display: 'flex',
    justifyContent: 'center',
    position: 'absolute',
    zIndex: 2,
    marginTop: '-30px',
    marginLeft: '13px',
  },

  drawerCenterIconBoxSx: {
    height: '16px',
    width: '16px',
    borderRadius: '4px',
    bgcolor: '#FACC15',
    transform: 'rotate(45deg)',
    alignSelf: 'center',
    display: 'flex',
    justifyContent: 'center',
  },
  iconTextSx: {
    transform: 'rotate(-45deg)',
    mt: '-4px',
    mr: '4px',
  },
  drawerBoxSx: {
    bgcolor: '#FCF8E8',
    borderBottom: '2px solid #FBE27F',
    display: 'flex',
    justifyContent: 'center',
    height: '74px',
  },
};
