/* eslint-disable no-unused-vars */
import { log } from '@hc/logger';
import { localStorageKeys, parseJwt, queryClient } from '@hc/utils';
import { toast } from 'react-hot-toast';
import { create } from 'zustand';

export const useExpertNotification = create((set, get) => ({
  expertNotificationState: {
    expertNotificationData: [],
  },
  loading: null,
  error: null,
  getExpertNotification: async () => {
    try {
      const authToken = localStorage.getItem(localStorageKeys.authToken);
      const tokenData = parseJwt(authToken);
      set({ loading: true });
      // For preventing duplicate request, we're using react-query
      const { status, data, error, isFetching } = await queryClient.fetchQuery([
        '/notifications/getAll',
        'post',
        { id: tokenData?.user_id ?? '' },
      ]);
      return set((state) => ({
        loading: false,
        expertNotificationState: {
          ...state.expertNotificationState,
          expertNotificationData: data,
        },
      }));
    } catch (error) {
      set({ loading: false });
      log('error', error);
      return toast.error(
        error?.response?.data?.status?.message ??
          error?.message ??
          'Something went wrong please try again!'
      );
    }
  },
  softDeleteNotification: async (notification_id) => {
    try {
      set({ loading: true });
      // For preventing duplicate request, we're using react-query
      const { status } = await queryClient.fetchQuery([
        '/notifications/status',
        'post',
        { notification_id },
      ]);
      if (status?.code === 200) {
        set({ loading: false });
        return status;
      }
      set({ loading: false });
    } catch (error) {
      log('error', error);
      set({ loading: false });
      return toast.error('Something went wrong please try again!');
    }
  },
}));
