import { expertRoutes } from '@hc/routes';
import { useExpertOnboarding } from '@hc/store';
import { localStorageKeys, parseJwt } from '@hc/utils';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { CustomTextArea, Input, Label, MobileInput } from '../../../atoms';
import { profilePersonalInfo_style } from './style';

function ProfilePersonalInfo(props) {
  const {
    className = '',
    showTitle,
    handleChange = () => {},
    rootStyle = {},
    ...rest
  } = props;
  const location = useLocation();
  const authToken = localStorage.getItem(localStorageKeys.authToken);
  const tokenData = parseJwt(authToken);
  const { expertOnboardingState } = useExpertOnboarding((state) => ({
    expertOnboardingState: state.expertOnboardingState,
  }));

  return (
    <Box
      sx={{
        ...profilePersonalInfo_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      {showTitle && (
        <Typography mb={1} fontSize={14} fontWeight={600}>
          Personal info
        </Typography>
      )}

      <Box sx={profilePersonalInfo_style.inputGroupSx}>
        <Label rootStyle={profilePersonalInfo_style.labelStyle} isRequired>
          Name
        </Label>
        <Input
          id="name"
          errorMessage={expertOnboardingState?.error?.name}
          isError={expertOnboardingState?.error?.name?.length > 0}
          disabled={
            location?.pathname === expertRoutes?.addProfile &&
            expertOnboardingState?.name.length > 0 &&
            tokenData?.is_verified === false
          }
          value={expertOnboardingState?.name ?? ''}
          onChange={(e) => handleChange('name', e.target.value)}
          textFieldStyle={profilePersonalInfo_style.customtextSx}
        />
      </Box>
      {/* Registration Number */}
      <Box sx={profilePersonalInfo_style.inputGroupSx}>
        <Label rootStyle={profilePersonalInfo_style.labelStyle}>
          Phone Number
        </Label>
        <MobileInput
          isReadonly
          isReadonlyCC
          id="phoneNumber"
          helperText={expertOnboardingState?.error?.mobile}
          isError={expertOnboardingState?.error?.mobile?.length > 0}
          disabled={
            location?.pathname === expertRoutes?.addProfile &&
            expertOnboardingState?.mobile?.length > 0 &&
            tokenData?.is_verified === false
          }
          value={{
            mobile_code: expertOnboardingState?.country_code ?? '+91',
            mobile: expertOnboardingState?.mobile ?? '',
          }}
          onChange={(e) => handleChange('mobile', e)}
          rootWapperstyle={{ height: '40px' }}
        />
      </Box>
      {/* Qualification */}
      <Box sx={profilePersonalInfo_style.inputGroupSx}>
        <Label rootStyle={profilePersonalInfo_style.labelStyle} isRequired>
          Qualification
        </Label>
        <Input
          id="qualification"
          errorMessage={expertOnboardingState?.error?.qualification}
          isError={expertOnboardingState?.error?.qualification?.length > 0}
          value={expertOnboardingState?.qualification ?? ''}
          onChange={(e) => handleChange('qualification', e.target.value)}
          textFieldStyle={profilePersonalInfo_style.customtextSx}
        />
      </Box>

      {/* Address */}
      <Box sx={profilePersonalInfo_style.inputGroupSx}>
        <Label isRequired rootStyle={profilePersonalInfo_style.labelStyle}>
          Address
        </Label>
        <CustomTextArea
          id="address"
          isError={expertOnboardingState?.error?.address?.length > 0}
          helperText={expertOnboardingState?.error?.address ?? ''}
          placeholder="123 Main St, Anytown, USA 12345"
          textFieldStyle={profilePersonalInfo_style.customtextSx}
          value={expertOnboardingState?.address}
          onChange={(e) => handleChange('address', e.target.value)}
          customWrapperSx={{
            '& .MuiTextField-root': {
              backgroundColor: '#F7F7F7',
              '&:hover': {
                borderColor: 'border.main',
              },
            },
          }}
        />
      </Box>
    </Box>
  );
}

ProfilePersonalInfo.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  showTitle: PropTypes.bool,
  handleChange: PropTypes.func,
};

export { ProfilePersonalInfo };
