import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { ForwardArrowIcon } from '../../../atoms';
import { expertChoosePlanCard_style } from './style';

function ExpertChoosePlanCard(props) {
  const {
    className = '',
    onClick = () => {},
    cardImage = '',
    cardHeader = '',
    cardContent = '',
    count,
    rootStyle = {},
    ...rest
  } = props;

  return (
    <Box
      sx={{
        ...expertChoosePlanCard_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Box sx={expertChoosePlanCard_style.cardRootSx} onClick={onClick}>
        <Grid container alignItems="center">
          <Grid item xs={3.5} sm={3.5} md={3.5}>
            <Box>{cardImage}</Box>
          </Grid>
          <Grid item xs={8.5} sm={8.5} md={8.5}>
            <Grid container>
              <Grid item xs={12} sm={12} md={12} pb={0.6} pt={1}>
                <Typography sx={expertChoosePlanCard_style.cardTypoSx}>
                  {cardHeader}
                </Typography>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Typography fontSize={12} color="#6552CC">
                  {`${count} ${cardContent} `}
                  <ForwardArrowIcon
                    rootStyle={{ color: '#6552CC', marginBottom: ' -2px' }}
                  />
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

ExpertChoosePlanCard.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  onClick: PropTypes.string,
  cardImage: PropTypes.string,
  cardHeader: PropTypes.string,
  cardContent: PropTypes.string,
  count: PropTypes.number,
};

export { ExpertChoosePlanCard };
