import { getDateFormat } from '@hc/dayjs';
import { expertRoutes } from '@hc/routes';
import { useExpertRequest, usePlanAddEditTool } from '@hc/store';
import { useByMePlan } from '@hc/store/expert/byMePlan';
import {
  Button,
  Drawer,
  FileSearchIllustration,
  Input,
  Label,
  SearchIcon,
  ToggleButton,
} from '@hc/ui/atoms';
import { HomeEmptyState, SkeletonCard } from '@hc/ui/components';
import { PlanDetailCard } from '@hc/ui/components/expert/planDetailCard';
import { queryClient } from '@hc/utils';
import { Box, Divider, Grid, IconButton, Typography } from '@mui/material';
import { isEqual } from 'date-fns';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';
import { MdAdd } from 'react-icons/md';
import { useNavigate } from 'react-router-dom';
import { Byme_style } from './style';

const useIntersectionObserver = (ref, options) => {
  const [isIntersecting, setIsIntersecting] = React.useState(false);

  React.useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsIntersecting(entry.isIntersecting);
    }, options);

    if (ref.current) {
      observer.observe(ref.current);
    }
  }, []);
  return isIntersecting;
};

export default function Byme(props) {
  const { className, ...rest } = props;
  const navigate = useNavigate();

  const [search, setSearch] = React.useState('');
  const [offset, setOffset] = React.useState(0);
  const IntersectionRef = useRef();
  const onScreen = useIntersectionObserver(IntersectionRef, { threshold: 1 });
  const [openAddActicityDrawer, setAddActicityDrawer] = React.useState(false);

  const {
    planAddEditToolState,
    onDurationUpdation,
    getPlanDetailsById,
    onUpdateToolData,
    clearPlanDetailes,
  } = usePlanAddEditTool(
    (state) => ({
      clearPlanDetailes: state.clearPlanDetailes,
      planAddEditToolState: state.planAddEditToolState,
      onDurationUpdation: state.onDurationUpdation,
      onUpdateToolData: state.onUpdateToolData,
      getPlanDetailsById: state.getPlanDetailsById,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const { planDurationData, error } = planAddEditToolState;

  const [drawerData, setDrawerData] = React.useState({
    plan_name: planDurationData?.plan_name,
    duration_type: planDurationData?.duration_type,
    duration: planDurationData?.duration,
  });

  // ------------------------STORE--------------------------------
  const { getAllByMePlanListData, useByMePlanState, loading } = useByMePlan(
    (state) => ({
      useByMePlanState: state.useByMePlanState,
      getAllByMePlanListData: state.getAllByMePlanListData,
      loading: state.loading,
    }),
  );
  const { planMasterByMeDataCount, planMasterByMeData } = useByMePlanState;

  // =--------------------------TEMPLATE STORE--------------------------

  const { getTemplateDataById } = useExpertRequest(
    (state) => ({
      getTemplateDataById: state.getTemplateDataById,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const filters = [
    {
      name: 'Day',
      value: 'day',
    },
    {
      name: 'Week',
      value: 'week',
    },
    {
      name: 'Month',
      value: 'month',
    },
  ];

  // ----------------------Handle INFINITE SCROLL-----------------------

  const handleObserver = async () => {
    if (
      planMasterByMeDataCount > 0 &&
      planMasterByMeDataCount > planMasterByMeData.length
    ) {
      setOffset(offset + 1);
      await getAllByMePlanListData(search, offset + 1, false);
    }
  };
  // -----------------------Handle SEACRH------------------------------------------

  const handleChange = async (val) => {
    setOffset(0);
    setSearch(val);
    await getAllByMePlanListData(val, 0, true);
  };

  // -------------------------CARD CLICK-----------------------
  const handleCardClick = async (id) => {
    onUpdateToolData('isHistory', false);
    onUpdateToolData('isDraft', false);
    onUpdateToolData('isScratch', false);
    onUpdateToolData('masterByMe', true);
    onUpdateToolData('masterByDefault', false);
    queryClient.invalidateQueries({
      queryKey: ['get-plan-data-by-plan-id'],
    });
    const result = await getPlanDetailsById(id ?? '');
    if (result?.status?.code === 200) {
      const response = await getTemplateDataById(id, result?.data);
      if (response?.code === 200) {
        navigate(expertRoutes.editplan, {
          state: {
            isPlanEdit: false,
            isPublishPlan: false,
          },
        });
      }
    }
  };

  // --------------------------ADD ACTIVITY---------------------
  const isValidToSave = () => {
    // const planDataCopy = JSON.parse(JSON.stringify(planDurationData));
    const errorCopy = JSON.parse(JSON.stringify(error));
    let isValid = true;

    // checking plan name
    if (!drawerData?.plan_name.length > 0) {
      isValid = false;
      errorCopy.plan_name = 'Please enter the plan name';
    } else {
      errorCopy.plan_name = '';
    }

    // checking plan duratio
    if (!drawerData?.duration_type > 0) {
      isValid = false;
      errorCopy.duration_type = 'Please select the duration type ';
    } else {
      errorCopy.duration_type = '';
    }

    // checking plan duration name
    if (!drawerData?.duration > 0) {
      isValid = false;
      errorCopy.duration = 'Please enter the duration';
    } else {
      errorCopy.duration = '';
    }
    onUpdateToolData('error', errorCopy);
    return isValid;
  };

  const onScratchPlanNext = () => {
    const result = isValidToSave();
    if (result) {
      onDurationUpdation('plan_name', drawerData?.plan_name);
      onDurationUpdation('duration_type', drawerData?.duration_type);
      onDurationUpdation('duration', drawerData?.duration);
      onUpdateToolData('isHistory', false);
      onUpdateToolData('isDraft', false);
      onUpdateToolData('isScratch', false);
      onUpdateToolData('masterByMe', true);
      onUpdateToolData('masterByDefault', false);
      navigate(expertRoutes.planAddEditTool);
    }
  };

  // -------------------------FEtch ON SCROLL------------------------------
  useEffect(() => {
    handleObserver();
  }, [onScreen]);

  // -------------------------FETCH INITIAL STATE--------------------------------
  const initialState = async () => {
    await getAllByMePlanListData('', offset, true);
  };

  useEffect(() => {
    clearPlanDetailes();
    initialState();
  }, []);

  return (
    <Box sx={Byme_style.root} className={`${className}`} {...rest}>
      <Box>
        <Box sx={Byme_style.SearchSelectSx}>
          <Input
            value={search}
            id="doctorSearch"
            placeholder="Search plan"
            onChange={(e) => {
              handleChange(e.target.value);
            }}
            textFieldStyle={Byme_style.textFieldStyle}
            startAdornment={
              <IconButton aria-label="" edge="start" disableRipple>
                <SearchIcon rootStyle={{ color: 'grey.500' }} />
              </IconButton>
            }
          />
        </Box>
      </Box>

      <Box mt={2}>
        <Typography mb={1} sx={{ color: 'grey', fontSize: '12px' }}>
          {`${planMasterByMeDataCount} plans`}
        </Typography>
        {loading && (
          <Box sx={{ height: '58vh', overflow: 'scroll' }}>
            {loading &&
              Array(5)
                .fill(1)
                .map((val, i) => (
                  <SkeletonCard
                    color="#e0ddf3"
                    key={i}
                    isCardDetailsRequired={false}
                    isByme
                  />
                ))}
          </Box>
        )}
        {!loading && (
          <Box pb={2} sx={{ height: '58vh', overflow: 'scroll' }}>
            {Array.isArray(planMasterByMeData) &&
              planMasterByMeData.length > 0 &&
              planMasterByMeData.map((data, i) => {
                return (
                  <Box py={1} key={i}>
                    <PlanDetailCard
                      onClickFun={() =>
                        handleCardClick(
                          data.id,
                          data.status === 'Publish' ? true : false,
                        )
                      }
                      Date={getDateFormat(data.created_at, 'DD MMM YYYY')}
                      duration={data.duration}
                      durationType={data.duration_type}
                      activities={data.activity_count}
                      planName={data.plan_name}
                      category={data.category_id}
                      draft={data.status === 'Draft'}
                      publish={data.status === 'Publish'}
                    />
                  </Box>
                );
              })}
            <div
              ref={IntersectionRef}
              style={{ marginTop: '-20px', opacity: 0 }}
            >
              intersection
            </div>
          </Box>
        )}

        <Box sx={{ display: 'flex', justifyContent: 'end', p: 2.5 }}>
          <Box
            sx={{
              position: 'fixed',
              bottom: 110,
              display: 'flex',
              justifyContent: 'end',
            }}
          >
            <Box
              sx={Byme_style.speedDialRootSx}
              onClick={() => setAddActicityDrawer(true)}
            >
              <Box alignSelf="center">
                <MdAdd
                  id="add"
                  color="#fff"
                  style={{
                    marginTop: '5px',
                    height: '35px',
                    width: '35px',
                  }}
                />
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      {/* )} */}
      {planMasterByMeData.length === 0 && (
        <HomeEmptyState
          illustartion={<FileSearchIllustration />}
          title="No plan templates"
          description={
            <span>
              Create a plan template by clicking <br /> on &apos;+&apos; icon
            </span>
          }
        />
      )}

      {openAddActicityDrawer && (
        <Drawer
          show={openAddActicityDrawer}
          rootStyle={Byme_style.drawerSx}
          header="Plan from scratch"
          drawerRightClose
          childrenStyle={{ p: 0 }}
          onCloseDrawer={() => setAddActicityDrawer(false)}
          headerStyle={{
            fontSize: '16px',
            fontWeight: 600,
            color: '#101010',
            textTransform: 'capitalize',
          }}
          closeStyle={{ color: 'grey', height: '14px', width: '12px' }}
          footer={
            <Box sx={{ display: 'flex', gap: 2 }}>
              <Button
                onClick={() => setAddActicityDrawer(false)}
                // disabled={loading === true}
                buttonStyle={Byme_style.cancelBtnSx}
              >
                Cancel
              </Button>
              <Button
                onClick={() => onScratchPlanNext()}
                // loading={loading}
                buttonStyle={Byme_style.applyBtnSx}
              >
                Next
              </Button>
            </Box>
          }
        >
          <Box p={2}>
            <Grid container>
              <Grid item xs={12} pb={2} sm={12} md={12}>
                <Label>Name of the plan</Label>
                <Box mt={0.5}>
                  <Input
                    id="planName"
                    value={drawerData?.plan_name}
                    onChange={(e) =>
                      setDrawerData({
                        ...drawerData,
                        plan_name: e.target?.value,
                      })
                    }
                    placeholder="Give a name to identify in future"
                    isError={error?.plan_name?.length > 0}
                    errorMessage={error?.plan_name}
                  />
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={12}>
                <Label>Plan Duration</Label>
                <Box display="flex">
                  <Box width={161} mt={0.5}>
                    <Input
                      id="planDuration"
                      value={drawerData?.duration}
                      placeholder="Type duration"
                      onChange={(e) =>
                        setDrawerData({
                          ...drawerData,
                          duration: e.target?.value,
                        })
                      }
                      isError={error?.duration?.length > 0}
                      errorMessage={error?.duration}
                    />
                  </Box>
                  <Box px={0.5}>
                    <Divider
                      orientation="vertical"
                      sx={{ height: '32px', mt: 1 }}
                    />
                  </Box>

                  <Box>
                    <Grid container display="flex" columnSpacing={1}>
                      <Grid item>
                        <ToggleButton
                          options={filters}
                          value={drawerData?.duration_type}
                          RootStyle={{ overflow: 'scroll' }}
                          buttonactiveStyle={Byme_style.buttonActiveNumSx}
                          buttoninactiveStyle={Byme_style.buttonInactiveNumSx}
                          onChange={(e) =>
                            setDrawerData({
                              ...drawerData,
                              duration_type: e,
                            })
                          }
                        />

                        {error?.duration_type.length > 0 && (
                          <Typography fontSize={12} color="	#ff3333">
                            {error?.duration_type}
                          </Typography>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Drawer>
      )}
    </Box>
  );
}

Byme.propTypes = {
  className: PropTypes.string,
};
