/* eslint-disable prefer-const */
/* eslint-disable no-restricted-syntax */
import { envConfig } from '@hc/config';
import { log } from '@hc/logger';
import { expertRoutes } from '@hc/routes';
import {
  httpRequest,
  localStorageKeys,
  parseJwt,
  queryClient,
} from '@hc/utils';
import { routeTo } from '@hc/utils/helperFunctions';
import { toast } from 'react-hot-toast';
import { create } from 'zustand';
import { useRouting } from '../common/routing';

export const useExpertOnboarding = create((set, get) => ({
  expertOnboardingState: {
    email: '',
    password: '',
    confirmPassword: '',
    name: '',
    mobile: '',
    qualification: '',
    country_code: '',
    address: '',
    about: '',
    languages: [],
    expertCategory: [],
    error: {
      email: '',
      password: '',
      confirmPassword: '',
      name: '',
      mobile: '',
      qualification: '',
      address: '',
      about: '',
      languages: [],
    },
  },
  loading: null,

  signUp: async (user_id, location) => {
    try {
      const { expertOnboardingState } = get();
      set({ loading: true });
      let payload = {};
      if (location === expertRoutes?.createAccount) {
        payload = {
          user_id,
          password: expertOnboardingState?.password ?? '',
        };
      } else if (location === expertRoutes?.resetPassword) {
        payload = {
          email: expertOnboardingState?.email ?? '',
          password: expertOnboardingState?.password ?? '',
        };
      }

      // Hitting the signup API
      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['expert-set-new-password'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.auth_url}/expert/${
              location === expertRoutes?.createAccount
                ? 'set-new-password'
                : 'update-password'
            }`,
            {
              ...payload,
            },
            true
          );
          return data;
        },
        staleTime: 120000,
      });

      // If the user is new
      if (status?.code === 200) {
        set({ loading: false });
        localStorage.setItem(localStorageKeys.authToken, data?.auth_token);
        toast.success(status?.message);
        routeTo(useRouting, expertRoutes?.addProfile);
      }
      return status;
    } catch (err) {
      set({ loading: false });
      log('error', err);
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!'
      );
    }
  },
  signIn: async () => {
    try {
      const { expertOnboardingState } = get();
      set({ loading: true });
      const payload = {
        email: expertOnboardingState?.email ?? '',
        password: expertOnboardingState?.password ?? '',
      };

      // Hitting the signIn API
      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['expert-sign-in', payload],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.auth_url}/expert/sign-in`,
            {
              ...payload,
            },
            true
          );
          return data;
        },
        staleTime: 120000,
      });

      if (status?.code === 200) {
        set({ loading: false });
        localStorage.setItem(localStorageKeys.authToken, data?.auth_token);
        toast.success(status?.message);
        routeTo(useRouting, expertRoutes?.home);
      }
      if (status?.code === 401) {
        set({ loading: false });
        toast.error(status?.message);
      }
      return status;
    } catch (err) {
      set({ loading: false });
      log('error', err);
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!'
      );
    }
  },
  forgotPassword: async () => {
    try {
      const { expertOnboardingState } = get();
      set({ loading: true });
      const payload = {
        email: expertOnboardingState?.email ?? '',
      };

      // Hitting the forgotPassword API
      const { status } = await queryClient.fetchQuery({
        queryKey: ['expert-forgot-password', payload],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.auth_url}/expert/reset-password/get-link`,
            {
              ...payload,
            },
            true
          );
          return data;
        },
        staleTime: 120000,
      });

      if (status?.code === 200) {
        set({ loading: false });
        toast.success(status?.message);
        routeTo(useRouting, expertRoutes?.home);
      }
      return status;
    } catch (err) {
      set({ loading: false });
      log('error', err);
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!'
      );
    }
  },

  refreshAuthToken: async () => {
    try {
      set({ loading: true });
      // Hitting the signup API
      const response = await httpRequest(
        'post',
        `${envConfig.auth_url}/expert-auth-refresh`,
        {},
        true
      );
      // If the user is new
      if (response?.data?.status?.code === 200) {
        set({ loading: false });
        localStorage.setItem(
          localStorageKeys.authToken,
          response?.data?.data?.auth_token
        );
      }
      set({ loading: false });
      return response?.data?.status?.code;
    } catch (err) {
      set({ loading: false });
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!'
      );
    }
  },

  userProfileUpsert: async () => {
    try {
      const { expertOnboardingState } = get();
      const authToken = localStorage.getItem(localStorageKeys.authToken);
      const tokenData = parseJwt(authToken);
      set({ loading: true });
      const payload = {
        user_id: tokenData?.user_id ?? '',
        name: expertOnboardingState?.name ?? '',
        qualification: expertOnboardingState?.qualification ?? '',
        address: expertOnboardingState?.address ?? '',
        about: expertOnboardingState?.about ?? '',
        language: expertOnboardingState?.languages ?? '',
      };

      // Hitting the signup API
      const { status } = await queryClient.fetchQuery({
        queryKey: ['expert-profile-update'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.api_url}/expert/profile/update`,
            {
              ...payload,
            },
            true
          );
          return data;
        },
        staleTime: 120000,
      });

      if (status?.code === 200) {
        set({ loading: false });
        toast.success(status?.message);
        routeTo(useRouting, expertRoutes?.home);
      }
      return status;
    } catch (err) {
      set({ loading: false });
      log('error', err);
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!'
      );
    }
  },
  verifyOnboardUrl: async () => {
    try {
      set({ verifyingInviteToken: true });
      const { data } = await queryClient.fetchQuery({
        queryKey: ['verifyOnboardUrl'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'get',
            `${envConfig.auth_url}/invite-link-expired-check`,
            {},
            true
          );
          return data;
        },
        staleTime: 30000,
      });
      set({ verifyingInviteToken: false });
      if (data?.isExpired === true) {
        localStorage.clear();
      }
      return data;
    } catch (err) {
      set({ verifyingInviteToken: false });
      log('error', err);
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!'
      );
    }
  },

  getProfile: async () => {
    try {
      const { expertOnboardingState } = get();
      const authToken = localStorage.getItem(localStorageKeys.authToken);
      const tokenData = parseJwt(authToken);
      set({ loading: true });

      // Hitting the signup API
      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['expert-profile-get'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'get',
            `${envConfig.api_url}/expert/profile/get?user_id=${tokenData?.user_id}`,
            {},
            true
          );
          return data;
        },
        staleTime: 120000,
      });
      let language = [];
      let category = [];

      for (const val of data?.user_languages) {
        const obj = {
          value: val?.language_id ?? '',
          label: val?.master_language?.language ?? '',
        };
        language.push(obj);
      }
      for (const val of data?.expert_category) {
        const obj = {
          value: val ?? '',
          label:
            val === '1'
              ? 'Mind'
              : val === '2'
              ? 'Body'
              : val === '3'
              ? 'Food'
              : '',
        };
        category.push(obj);
      }

      if (status?.code === 200) {
        set({
          loading: false,
          expertOnboardingState: {
            ...expertOnboardingState,
            name: data?.first_name ?? '',
            mobile: data?.mobile_no ?? '',
            qualification: data?.education ?? '',
            country_code: data?.country_code ?? '',
            address: data?.address ?? '',
            about: data?.about ?? '',
            languages: language,
            expertCategory: category,
          },
        });
      }
      return status;
    } catch (err) {
      set({ loading: false });

      log('error', err);
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!'
      );
    }
  },
  updateOnboarding: (key, value) => {
    const { expertOnboardingState } = get();
    if (key === 'languages') {
      const arr = expertOnboardingState?.languages ?? [];
      arr.push(value);
      set({
        expertOnboardingState: {
          ...expertOnboardingState,
          [key]: arr,
        },
      });
    } else if (key === 'mobile') {
      set({
        expertOnboardingState: {
          ...expertOnboardingState,
          mobile: value?.mobile,
          country_code: value?.mobileCode,
        },
      });
    } else {
      set({
        expertOnboardingState: {
          ...expertOnboardingState,
          [key]: value,
        },
      });
    }
  },
  onClearLanguage: () => {
    const { expertOnboardingState } = get();
    set({
      expertOnboardingState: {
        ...expertOnboardingState,
        languages: [],
      },
    });
  },
  onDeleteLanguage: (i) => {
    const { expertOnboardingState } = get();
    const resArr = [];

    const languageCopy = JSON.parse(
      JSON.stringify(expertOnboardingState?.languages)
    );
    languageCopy?.forEach((data, index) => {
      if (index !== i) {
        resArr.push(data);
      }
    });

    set({
      expertOnboardingState: {
        ...expertOnboardingState,
        languages: resArr,
      },
    });
  },
  clearOnboardState: () => {
    const { expertOnboardingState } = get();
    set({
      expertOnboardingState: {
        ...expertOnboardingState,
        email: '',
        password: '',
        confirmPassword: '',
        name: '',
        mobile: '',
        qualification: '',
        country_code: '',
        address: '',
        about: '',
        languages: [],
      },
    });
  },
}));
