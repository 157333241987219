/* eslint-disable react/jsx-key */
import { getDate } from '@hc/dayjs';
import { expertRoutes } from '@hc/routes';
import { useExpertNotification, useExpertOnboarding } from '@hc/store';
import { NotificationIllustrator } from '@hc/ui/atoms';
import { NotificationCard } from '@hc/ui/components';
import { Screenlayout } from '@hc/ui/components/screenLayout';
import { localStorageKeys } from '@hc/utils';
import { diffYMDHMS, parseJwt } from '@hc/utils/helperFunctions';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import isEqual from 'react-fast-compare';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

export function ExpertNotification() {
  const authToken = localStorage.getItem(localStorageKeys.authToken);
  const data = parseJwt(authToken);
  const navigate = useNavigate();

  const { refreshAuthToken } = useExpertOnboarding((state) => ({
    refreshAuthToken: state.refreshAuthToken,
  }));

  const {
    getExpertNotification,
    softDeleteNotification,
    expertNotificationState,
    loading,
  } = useExpertNotification(
    (state) => ({
      softDeleteNotification: state.softDeleteNotification,
      getExpertNotification: state.getExpertNotification,
      expertNotificationState: state.expertNotificationState,
      loading: state.loading,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const { expertNotificationData } = expertNotificationState;

  const refreshAuthTokenFnc = async () => {
    const responseCode = await refreshAuthToken();
    if (responseCode === 200) {
      toast('Your account is verified... Redirecting to home page...');
      navigate(expertRoutes?.home);
      setTimeout(() => {
        window.location.reload();
      }, 2000);
    }
  };

  const redirectFnc = async (url, val) => {
    if (val?.is_actionable === false && val?.is_read === false) {
      await softDeleteNotification(val?.id);
      await getExpertNotification(data?.user_id);
    }
    if (val?.is_read === false && url === '/?verificationDone=true') {
      refreshAuthTokenFnc();
    } else {
      navigate(url);
    }
  };

  useEffect(() => {
    if (
      Array.isArray(expertNotificationData) &&
      expertNotificationData?.length > 0
    ) {
      const verfifyArr = expertNotificationData?.filter(
        (val) =>
          JSON.parse(val?.content)?.actionUrl === '/?verificationDone=true' &&
          val?.is_read === false
      );
      if (verfifyArr?.length > 0) {
        setTimeout(() => {
          softDeleteNotification(verfifyArr?.[0]?.id);
          refreshAuthTokenFnc();
        }, 3000);
      }
    }
  }, [expertNotificationData]);

  useEffect(() => {
    getExpertNotification(data?.user_id);
  }, []);

  return (
    <Screenlayout
      title="Notifications"
      notshowFooter
      isExpertAppBar
      backRequired
      backRoute={-1}
    >
      {loading && (
        <Box display="flex" height="100vh" justifyContent="center">
          <Box alignSelf="center">
            <CircularProgress sx={{ color: 'mbf.main' }} />
          </Box>
        </Box>
      )}
      <Box display={loading ? 'none' : ''}>
        {Array.isArray(expertNotificationData) &&
        expertNotificationData?.length > 0 ? (
          <Box>
            {expertNotificationData.map((data, i) => (
              <NotificationCard
                key={i}
                isExpert
                prescription={JSON.parse(data?.content)?.message ?? ''}
                notificationTime={diffYMDHMS(getDate(data?.updatedAt))}
                data={data}
                redirectFnc={redirectFnc}
                is_read={data?.is_read}
                url={JSON.parse(data?.content)?.actionUrl ?? ''}
              />
            ))}
          </Box>
        ) : (
          <Grid
            container
            direction="column"
            justifyContent="start"
            alignItems="center"
          >
            <Grid item mt={9}>
              <Box>
                <NotificationIllustrator />
              </Box>
            </Grid>
            <Grid item mt={1.6}>
              <Typography fontSize={14} fontWeight={500} color="#5c6266">
                No Notifications Yet
              </Typography>
            </Grid>
            <Grid item mt={1}>
              <Box width={250} textAlign="center">
                <Typography fontSize={12} color="#888888">
                  Come back later!
                </Typography>
              </Box>
            </Grid>
          </Grid>
        )}
      </Box>
    </Screenlayout>
  );
}
