export const planDetailCard_style = {
  cardContainerSx: {
    border: '1px solid #E3E3E3',
    background: '#FFFFFF',
    boxShadow: '0px 10px 20px #0000000A',
    borderRadius: '8px',
    cursor: 'pointer',
  },
  cardTitleSx: {
    fontSize: '12px',
    textAlign: 'left',
    letterSpacing: '0px',
    color: '#888888',
    opacity: 1,
  },
  cardSubTitleSx: {
    fontSize: '10px',
    textAlign: 'left',
    letterSpacing: '0px',
    fontWeight: 500,
    color: '#5C6266',
    opacity: 1,
  },
  draftTextSx: {
    color: '#EE8438',
    background: '#FFF6F0 ',
    fontSize: '10px',
    display: 'grid',
    placeItems: 'center',
    padding: '2px 8px',
  },
  publishTextSx: {
    color: '#4CAF50',
    background: '#E8FCF0  ',
    fontSize: '10px',
    display: 'grid',
    placeItems: 'center',
    padding: '2px 8px',
  },
  planFontSx: {
    color: '#6552CC',
    fontSize: '14px',
    fontWeight: 500,
    textTransform: 'capitalize',
  },
  monthFontSx: {
    textAlign: 'left',
    letterSpacing: '0px',
    color: '#888888',
    fontSize: '12px',
    fontWeight: 400,
  },
  activityFontSx: {
    textAlign: 'left',
    color: '#888888',
    fontSize: '12px',
    fontWeight: 400,
  },
  activityTypeFont: {
    fontSize: '10px',
    fontWeight: 500,
    color: '#97C540',
  },
};
