/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-const */
import {
  AlarmClockIcon,
  Button,
  Dropdown,
  ExpertDontsIcons,
  ExpertEditDontsIcons,
  ExpertEditNotesIcon,
  ExpertEditReferenceIcon,
  ExpertNotesIcon,
  ExpertReferenceIcon,
  Input,
  Label,
  Switch,
  ToggleButton,
  UploadCloseIcon,
  UploadImageIcon,
} from '@hc/ui/atoms';
import {
  DaySelector,
  PatientReportUploadCard,
  Screenlayout,
} from '@hc/ui/components';
import { Avatar, Box, Divider, Grid, Stack, Typography } from '@mui/material';
// import { useActivityTemplate } from '@hc/store/backoffice/activityTemplate';
import { expertRoutes } from '@hc/routes';
import {
  useCreateAndAddActivity,
  useMasters,
  usePlanAddEditTool,
} from '@hc/store';
import { handleUploadFile, queryClient } from '@hc/utils';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useLocation, useNavigate } from 'react-router-dom';
import { Addactivity_style } from './style';

export default function AddActivity(props) {
  const { className = '', ...rest } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const [isImageURLValid, setIsImageURLValid] = useState(false);
  const [activityDaysText, setActivityDaysText] = useState('');

  const {
    activityState,
    createActivity,
    onEditCustomplan,
    onStateUpdate,
    clearCreateNewActivityData,
    loading,
  } = useCreateAndAddActivity(
    (state) => ({
      clearCreateNewActivityData: state.clearCreateNewActivityData,
      onEditCustomplan: state.onEditCustomplan,
      activityState: state.activityState,
      onStateUpdate: state.onStateUpdate,
      createActivity: state.createActivity,
      loading: state.loading,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  // console.log(activityState,"activityState")

  console.log(loading, 'loading');

  const { mastersState, getActivityUnits, getGoalTag } = useMasters(
    (state) => ({
      getGoalTag: state.getGoalTag,
      getActivityUnits: state.getActivityUnits,
      mastersState: state.mastersState,
    }),
  );

  const { createPlanData, editPlanData } = usePlanAddEditTool(
    (state) => ({
      editPlanData: state.editPlanData,
      createPlanData: state.createPlanData,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const { goalTag, activityUnits } = mastersState;

  const { createNewActivityData, activity_image_obj, error } = activityState;

  const Days = [
    { value: '1', label: 'S', disabled: false },
    { value: '2', label: 'M', disabled: false },
    { value: '3', label: 'T', disabled: false },
    { value: '4', label: 'W', disabled: false },
    { value: '5', label: 'T', disabled: false },
    { value: '6', label: 'F', disabled: false },
    { value: '7', label: 'S', disabled: false },
  ];

  const DurationList = [
    {
      name: 'Morning',
      value: 1,
    },
    {
      name: 'Afternoon',
      value: 2,
    },
    {
      name: 'Evening',
      value: 3,
    },
    {
      name: 'Anytime',
      value: 4,
    },
    {
      name: 'All Day',
      value: 5,
    },
    {
      name: 'Specific time ',
      value: 6,
    },
  ];

  // SWITCH FOR MEASURABLE
  const onStatusChange = (event) => {
    onStateUpdate('is_measurable', event.target.checked);
    if (!event.target.checked) {
      onStateUpdate('quantity', 0);
      onStateUpdate('unit', 0);
      onStateUpdate('unit_name', '');
    }
  };
  const handleDelete = () => {
    onStateUpdate('activity_image', '');
    onStateUpdate('activity_image_obj', {});
  };

  const handleFileUpload = async (e) => {
    let fileList = e?.target?.files;
    if (fileList.length > 0) {
      const data = await handleUploadFile({ 0: fileList[0], length: 1 });
      onStateUpdate('activity_image', data[0].downloadUrl);
      onStateUpdate('activity_image_obj', data[0]);
    }
  };

  const isValidToSave = () => {
    const expertActivityCopy = JSON.parse(
      JSON.stringify(createNewActivityData),
    );
    const errorCopy = JSON.parse(JSON.stringify(error));
    let isValid = true;

    // checking activity name
    if (location?.state?.isCustomEdit || createNewActivityData?.isNew) {
      if (!expertActivityCopy?.activity_name?.length > 0) {
        isValid = false;
        errorCopy.activity_name = 'Please enter the activity name';
      } else {
        errorCopy.activity_name = '';
      }
    } else {
      errorCopy.activity_name = '';
    }

    // checking quantity
    if (createNewActivityData?.is_measurable) {
      if (!expertActivityCopy?.quantity > 0) {
        isValid = false;
        errorCopy.quantity = 'Please enter the quantity';
      } else {
        errorCopy.quantity = '';
      }
    } else {
      errorCopy.quantity = '';
    }

    // checking unit
    if (createNewActivityData?.is_measurable) {
      if (!expertActivityCopy?.unit > 0) {
        isValid = false;
        errorCopy.unit = 'Please select the unit';
      } else {
        errorCopy.unit = '';
      }
    } else {
      errorCopy.unit = '';
    }

    // checking activity days
    if (!expertActivityCopy?.activity_days.length > 0) {
      isValid = false;
      errorCopy.activity_days = 'Please select the activity days';
    } else {
      errorCopy.activity_days = '';
    }

    // checking activity time
    if (!expertActivityCopy?.activity_time > 0) {
      isValid = false;
      errorCopy.activity_time = 'Please select the activity time';
    } else {
      errorCopy.activity_time = '';
    }

    // checking goal
    if (!expertActivityCopy?.goal_id > 0) {
      isValid = false;
      errorCopy.goal_id = 'Please select the goal';
    } else {
      errorCopy.goal_id = '';
    }
    onStateUpdate('error', errorCopy);
    return isValid;
  };

  const handleReferenceClick = () => {
    navigate(expertRoutes.activityReference, {
      state: {
        notes: 'isReferences',
        category_id: location?.state?.category_id,
        isCustomEdit: location?.state?.isCustomEdit,
        isEdit: location?.state?.isEdit,
      },
    });
  };

  const onCancelActivity = () => {
    clearCreateNewActivityData();
    navigate(expertRoutes.planAddEditTool);
  };

  const onCreateActivity = async () => {
    const result = isValidToSave();
    if (result) {
      if (!location?.state?.isCustomEdit) {
        onStateUpdate('is_active', true);
        queryClient.invalidateQueries({
          queryKey: ['activityTemplateRequest-upsert-activity-request'],
        });
        if (!createNewActivityData?.is_measurable) {
          onStateUpdate('quantity', null);
          onStateUpdate('unit', null);
        }
        if (createNewActivityData?.isNew) {
          const response = await createActivity();
          if (response?.code === 200) {
            if (location?.state?.isEdit) {
              editPlanData();
            }

            createPlanData();
            clearCreateNewActivityData();
            navigate(expertRoutes.planAddEditTool);
          }
        } else {
          if (location?.state?.isEdit) {
            editPlanData();
          }
          createPlanData();
          clearCreateNewActivityData();
          navigate(expertRoutes.planAddEditTool);
        }
      } else {
        onEditCustomplan();
        clearCreateNewActivityData();
        navigate(expertRoutes.editActivityDetails);
      }
    }
  };

  useEffect(() => {
    queryClient.invalidateQueries({
      queryKey: ['master-get-activity-unit'],
    });
    queryClient.invalidateQueries({
      queryKey: ['get-mbf-goal-tag'],
    });
    getActivityUnits();
    getGoalTag();
    if (createNewActivityData?.category_id === '') {
      onStateUpdate('category_id', location?.state?.category_id);
      onStateUpdate(
        'category_name',
        location?.state?.category_id === '1'
          ? 'mind'
          : location?.state?.category_id === '2'
          ? 'body'
          : 'food',
      );
    }

    const imageUrl = createNewActivityData?.activity_image;
    const imageUrlRegex = /\.(jpeg|jpg|gif|png)$/i;
    const isValidImageUrl = imageUrlRegex.test(imageUrl);

    setIsImageURLValid(isValidImageUrl);
  }, []);

  useEffect(() => {
    let resArr = [];
    if (createNewActivityData?.activity_days.length === 7) {
      setActivityDaysText('day');
    } else if (createNewActivityData?.activity_days.length === 2) {
      for (const data of createNewActivityData?.activity_days) {
        if (data === '1' || data === '7') {
          resArr.push(data);
        }
      }
      if (resArr.length === 2) {
        setActivityDaysText('Weekends');
      }
    } else if (createNewActivityData?.activity_days.length === 5) {
      for (const data of createNewActivityData?.activity_days) {
        if (
          data === '2' ||
          data === '3' ||
          data === '4' ||
          data === '5' ||
          data === '6'
        ) {
          resArr.push(data);
        }
      }
      if (resArr.length === 5) {
        setActivityDaysText('Week days');
      }
    } else if (createNewActivityData?.activity_days.length < 7) {
      for (const data of createNewActivityData?.activity_days) {
        resArr.push(
          data === '1'
            ? 'Sun'
            : data === '2'
            ? 'Mon'
            : data === '3'
            ? 'Tue'
            : data === '4'
            ? 'Wed'
            : data === '5'
            ? 'Thu'
            : data === '6'
            ? 'Fri'
            : data === '7'
            ? 'Sat'
            : '',
        );
      }

      setActivityDaysText(resArr?.map((val) => ` ${val} `));
    }
  }, [createNewActivityData?.activity_days]);

  return (
    <Screenlayout
      isExpertAppBar
      appBarStyle={Addactivity_style.appBarStyleSx}
      backRequired
      backRoute={
        location?.state?.isEdit && !location?.state?.isCustomEdit
          ? expertRoutes?.planAddEditTool
          : location?.state?.isEdit && location?.state?.isCustomEdit
          ? expertRoutes?.editActivityDetails
          : expertRoutes?.selectActivity
      }
      stateValue={{ category_id: location?.state?.category_id }}
      backIconStyle={{ color: '#6552CC' }}
      rootStyle={{ backgroundColor: '#F7F7F7 ' }}
      title={location?.state?.isEdit ? 'Edit Activity' : 'Create Activity'}
      masterchildStyle={Addactivity_style.screenLayoutChildernSx}
      notshowFooter={false}
      stepperformWithAppbar={location?.state?.isEdit}
      stepperChildren={
        <Box>
          <Grid container>
            <Grid item>
              <Box height="44px" width="44px">
                <Avatar
                  sx={{
                    height: '44px',
                    width: '44px',
                    borderRadius: '8%',
                    alignSelf: 'center',
                    bgcolor: '#888888',
                  }}
                  src={
                    isImageURLValid ? createNewActivityData?.activity_image : ''
                  }
                >
                  {createNewActivityData?.activity_name.slice(0, 1) ?? 'A'}
                </Avatar>
              </Box>
            </Grid>
            <Grid item display="flex">
              <Typography
                ml={2}
                alignSelf="center"
                fontSize={14}
                fontWeight={600}
              >
                {createNewActivityData?.activity_name}
              </Typography>
            </Grid>
          </Grid>
        </Box>
      }
      footerStyle={{ borderTop: '0px', p: 1 }}
      footer={
        <Box
          sx={{
            height: '50px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            px: 2,
          }}
        >
          <Box
            sx={{
              height: '50px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              px: 2,
              gap: 3,
            }}
          >
            <Box>
              <Button
                onClick={() => onCancelActivity()}
                buttonStyle={Addactivity_style.backbuttonSx}
              >
                cancel
              </Button>
            </Box>
            <Box>
              <Button
                onClick={() => onCreateActivity()}
                buttonStyle={Addactivity_style.buttonSx}
                loading={loading}
              >
                {location?.state?.isEdit ? 'Update' : 'Create'}
              </Button>
            </Box>
          </Box>
        </Box>
      }
    >
      <Box sx={Addactivity_style.rootSx} className={`${className}`} {...rest}>
        <Stack
          sx={Addactivity_style.boxRootSx}
          mt={location?.state?.isEdit ? 14 : 7}
        >
          {/* ------------ACTIVITY NAME INPUT----------- */}
          {createNewActivityData?.isNew && (
            <Box sx={Addactivity_style.containerDivSx}>
              <Label
                rootStyle={Addactivity_style.labelSx}
                htmlFor="ActivityName"
                isRequired
              >
                Activity Name
              </Label>
              <Box mt={1}>
                <Input
                  id="activityName"
                  placeholder="Type activity name"
                  textFieldStyle={Addactivity_style.inputFieldSx}
                  onChange={(e) => {
                    onStateUpdate('activity_name', e.target.value);
                  }}
                  isError={error?.activity_name?.length > 0}
                  errorMessage={error?.activity_name}
                  value={createNewActivityData?.activity_name}
                  fullWidth
                />
              </Box>
            </Box>
          )}

          {/*  -------------ACTIVITY IMAGE UPLOAD FIELD ----------- */}
          {!location?.state?.isEdit && (
            <Box sx={Addactivity_style.containerDivSx}>
              <Label
                rootStyle={Addactivity_style.labelSx}
                htmlFor="Activityimage"
              >
                Activity image
              </Label>
              {activity_image_obj?.filename ? (
                <PatientReportUploadCard
                  showReportName
                  icon={<UploadCloseIcon />}
                  isUploadCard
                  reportName={activity_image_obj?.filename ?? ''}
                  uploadedFor={activity_image_obj?.size ?? ''}
                  uploadedData={[
                    {
                      file: `${activity_image_obj?.downloadUrl ?? ''}`,
                    },
                  ]}
                  IconOnClick={() => handleDelete(activity_image_obj?.filename)}
                  isLoading={loading}
                />
              ) : (
                <Box mt={1}>
                  <label htmlFor="uplodebtnv">
                    <Box sx={Addactivity_style.activeUploadFileSx}>
                      <Box
                        component="label"
                        sx={{
                          ...Addactivity_style.uploadIconSx,
                        }}
                      >
                        <UploadImageIcon />

                        <input
                          hidden
                          name="img"
                          accept="image/*"
                          multiple="multiple"
                          type="file"
                          id="activityUpload"
                          onChange={handleFileUpload}
                        />
                      </Box>
                      <Box>
                        <Typography sx={Addactivity_style.browseTextSx}>
                          Click to browse and upload
                        </Typography>
                      </Box>
                    </Box>
                  </label>
                </Box>
              )}
            </Box>
          )}

          {/* ----------------MEASURABLE SWITCH-------------- */}
          <Stack
            flexDirection="row"
            justifyContent="space-between"
            sx={Addactivity_style.measurableSx}
          >
            <Box sx={Addactivity_style.measurableContentSx}>
              <Box mt={0.4}>
                {' '}
                <AlarmClockIcon />
              </Box>

              <Typography pl={1}>
                {' '}
                Do you want to measure <br />
                this activity?
              </Typography>
            </Box>
            <Box>
              {' '}
              <Switch
                checked={createNewActivityData?.is_measurable}
                onChange={(e) => onStatusChange(e)}
                rootStyle={Addactivity_style.switchSx}
              />
            </Box>
          </Stack>

          {/* ----------------- ACTIVITY UNITS ------------- */}
          {createNewActivityData?.is_measurable && (
            <Box sx={Addactivity_style.containerDivSx}>
              <Label
                rootStyle={Addactivity_style.labelSx}
                htmlFor="Activityunits"
                isRequired
              >
                Activity units
              </Label>

              <Grid
                container
                sx={Addactivity_style.activityunitsSX}
                p={1}
                mt={1}
              >
                <Grid item xs={6} height="60%">
                  <Input
                    sx={Addactivity_style.textFieldStyle}
                    placeholder="0"
                    type="unit"
                    id="unit"
                    // isError={error?.quantity?.length > 0}
                    // errorMessage={error?.quantity}
                    value={createNewActivityData?.quantity}
                    onChange={(e) => {
                      onStateUpdate('quantity', parseInt(e.target.value, 10));
                    }}
                  />
                </Grid>

                <Grid
                  item
                  xs={2.5}
                  sx={{
                    borderLeft: '1px solid #E3E3E3',
                  }}
                >
                  {' '}
                </Grid>

                <Grid item xs={3.5}>
                  <Dropdown
                    fullWidth={false}
                    rootStyle={Addactivity_style.dropdownSX}
                    value={createNewActivityData?.unit}
                    datatestid="activityunits"
                    selectOption={activityUnits}
                    onChange={(value) => {
                      onStateUpdate('unit', value?.target?.value);
                      for (const val of activityUnits) {
                        if (val?.value === value?.target?.value) {
                          onStateUpdate('unit_name', val?.label);
                        }
                      }
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item sm={6} md={6} xs={6}>
                  {error?.quantity.length > 0 && (
                    <Typography fontSize={12} color="	#ff3333">
                      {error?.quantity}
                    </Typography>
                  )}
                </Grid>
                <Grid item sm={6} md={6} xs={6}>
                  {error?.unit?.length > 0 && (
                    <Typography fontSize={12} color="	#ff3333">
                      {error?.unit}
                    </Typography>
                  )}
                </Grid>
              </Grid>
            </Box>
          )}

          {/* --------------DAY SELECTION BUTTON-------------- */}
          <Box sx={Addactivity_style.DaycontainerBoxSx}>
            <Label
              rootStyle={Addactivity_style.labelSx}
              htmlFor="Do this activity on"
              isRequired
            >
              Do this activity on
            </Label>
            <Box mt={1}>
              <DaySelector
                buttonContent={Days}
                buttonStyle={{
                  '&:hover': {
                    background: '#fff',
                    border: '1px solid #EBEBEB',
                  },
                }}
                getDay={(newValue) => onStateUpdate('activity_days', newValue)}
                value={createNewActivityData?.activity_days ?? []}
                selectbuttonStyle={{
                  '&.Mui-selected': {
                    background: '#fff',
                    color: '#EE8438',
                    border: '1px solid #EE8438 !important',
                    '&:hover': {
                      background: '#fff',
                    },
                  },
                }}
              />
            </Box>
            {activityDaysText.length > 0 && (
              <Typography sx={Addactivity_style.TimeSelectText}>
                {`Every ${activityDaysText}`}
              </Typography>
            )}
          </Box>
          {error?.activity_days?.length > 0 && (
            <Typography fontSize={12} color="	#ff3333">
              {error?.activity_days}
            </Typography>
          )}

          {/* ----------TIME SELCTION TOGGLE------------------ */}

          <Box sx={Addactivity_style.containerBoxSx}>
            {' '}
            <Label
              rootStyle={Addactivity_style.labelSx}
              htmlFor=" Time of activity"
              isRequired
            >
              Time of activity
            </Label>
            <Box mt={1}>
              <ToggleButton
                RootStyle={{ flexwrap: 'wrap', flexFlow: 'wrap' }}
                options={DurationList}
                value={createNewActivityData?.activity_time}
                buttonactiveStyle={Addactivity_style.buttonactivenumSX}
                buttoninactiveStyle={Addactivity_style.buttoninactivenumSX}
                onChange={(e) => {
                  onStateUpdate('activity_time', e);
                  for (const val of DurationList) {
                    if (val?.value === e) {
                      onStateUpdate('activity_time_name', val?.name);
                    }
                  }
                }}
              />
            </Box>
            {createNewActivityData?.activity_time === 6 && (
              <Stack
                flexDirection="row"
                sx={{ backgroundColor: '#F7F7F7 ', borderRadius: '10px' }}
                pt={0.4}
              >
                <Box p={0.8}>
                  {' '}
                  <Typography pl={1} sx={Addactivity_style.timeInput}>
                    From
                  </Typography>
                  <Input
                    onChange={(event) =>
                      onStateUpdate('specific_time', event.target.value, 'from')
                    }
                    value={createNewActivityData?.specific_time?.from}
                    type="time"
                    textFieldStyle={Addactivity_style.inputFieldSx}
                  />
                </Box>

                <Divider
                  orientation="vertical"
                  flexItem
                  sx={{ height: '50px', mt: 1.5, mr: 2 }}
                />

                <Box pt={0.8}>
                  <Typography sx={Addactivity_style.timeInput}>To</Typography>
                  <Input
                    type="time"
                    onChange={(event) =>
                      onStateUpdate('specific_time', event.target.value, 'to')
                    }
                    value={createNewActivityData?.specific_time?.to}
                    textFieldStyle={Addactivity_style.inputFieldSx}
                  />
                </Box>
              </Stack>
            )}
          </Box>

          {error?.activity_time?.length > 0 && (
            <Typography fontSize={12} color="	#ff3333">
              {error?.activity_time}
            </Typography>
          )}

          {/* -------------DRAWER BUTOONS--------------------- */}
          <Box sx={Addactivity_style.notesContainerBoxSx}>
            <Box
              onClick={() =>
                navigate(expertRoutes.activityNotes, {
                  state: {
                    notes: 'isNotes',
                    category_id: location?.state?.category_id,
                    isCustomEdit: location?.state?.isCustomEdit,
                    isEdit: location?.state?.isEdit,
                  },
                })
              }
              sx={{ cursor: 'pointer' }}
            >
              {createNewActivityData?.activity_notes?.ops?.length > 0 ? (
                <ExpertEditNotesIcon />
              ) : (
                <ExpertNotesIcon />
              )}

              <Typography sx={Addactivity_style.NotesFontSx}>Notes</Typography>
            </Box>
            <Box
              onClick={() =>
                navigate(expertRoutes.activityNotes, {
                  state: {
                    notes: 'isDonts',
                    category_id: location?.state?.category_id,
                    isCustomEdit: location?.state?.isCustomEdit,
                    isEdit: location?.state?.isEdit,
                  },
                })
              }
              sx={{ cursor: 'pointer' }}
            >
              {createNewActivityData?.activity_dont?.ops?.length > 0 ? (
                <ExpertEditDontsIcons />
              ) : (
                <ExpertDontsIcons />
              )}

              <Typography
                sx={Addactivity_style.NotesFontSx}
              >{`Dont's`}</Typography>
            </Box>
            <Box
              onClick={() => handleReferenceClick()}
              sx={{ cursor: 'pointer' }}
            >
              {createNewActivityData?.activity_references?.length >= 0 &&
              createNewActivityData?.activity_references[0]?.linkName ? (
                <ExpertEditReferenceIcon />
              ) : (
                <ExpertReferenceIcon />
              )}

              <Typography sx={Addactivity_style.NotesFontSx}>
                Reference
              </Typography>
            </Box>
          </Box>

          <Box sx={Addactivity_style.containerDivSx}>
            <Label
              rootStyle={Addactivity_style.labelSx}
              htmlFor="Activityimage"
              isRequired
            >
              Activity goal
            </Label>

            <Box mt={2}>
              {' '}
              <ToggleButton
                options={goalTag}
                RootStyle={{ flexwrap: 'wrap', flexFlow: 'wrap' }}
                value={createNewActivityData?.goal_id}
                buttonactiveStyle={Addactivity_style.buttonactivenumSX}
                buttoninactiveStyle={Addactivity_style.buttoninactivenumSX}
                onChange={(e) => {
                  onStateUpdate('goal_id', e);
                  for (const val of goalTag) {
                    if (val?.value === e) {
                      onStateUpdate('goal_name', val?.name);
                    }
                  }
                }}
              />
            </Box>

            {error?.goal_id?.length > 0 && (
              <Typography fontSize={12} color="	#ff3333">
                {error?.goal_id}
              </Typography>
            )}
          </Box>

          {/* ------------------------------- */}
        </Stack>
      </Box>
    </Screenlayout>
  );
}

AddActivity.propTypes = {
  className: PropTypes.string,
};
