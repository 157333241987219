import { expertRoutes } from '@hc/routes';
import { useExpertOnboarding } from '@hc/store';
import {
  AngleRight,
  Button,
  Drawer,
  ExpertProfileBannerImage,
  LogoutIcon,
} from '@hc/ui/atoms';
import { ExpertLayout, ExpertProfileSkeleton } from '@hc/ui/components';
import { localStorageKeys, queryClient } from '@hc/utils';
import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { profile_style } from './style';

export default function ExpertProfile(props) {
  const { ...rest } = props;
  const navigate = useNavigate();

  const [openDrawer, setOpenDrawer] = useState(false);

  const { expertOnboardingState, clearOnboardState, getProfile, loading } =
    useExpertOnboarding((state) => ({
      expertOnboardingState: state.expertOnboardingState,
      getProfile: state.getProfile,
      clearOnboardState: state.clearOnboardState,
      loading: state.loading,
    }));

  const initialData = async () => {
    queryClient.invalidateQueries({
      queryKey: ['expert-profile-get'],
    });
    await getProfile();
  };

  const handleClick = () => {
    setOpenDrawer(true);
  };

  const onCancel = () => {
    setOpenDrawer(false);
  };

  const onLogoutClick = () => {
    localStorage.clear();
    clearOnboardState();
    navigate(expertRoutes?.signIn);
  };

  useEffect(() => {
    initialData();
  }, []);

  return (
    <ExpertLayout
      footBarRequired
      childrenStyle={{ backgroundColor: '#F7F7F7' }}
    >
      <Box sx={profile_style.rootSx} {...rest}>
        <Box>
          <Typography fontWeight={600}> Profile </Typography>
        </Box>
        {loading && (
          <Box mt={2}>
            <ExpertProfileSkeleton />
          </Box>
        )}
        {!loading && (
          <Box>
            <Box sx={profile_style.profileBannerSx}>
              <Grid container>
                <Grid
                  item
                  xs={7}
                  onClick={() => navigate(expertRoutes.editProfile)}
                  sm={7}
                  md={7}
                >
                  <Grid container display="flex" justifyContent="center">
                    <Grid item xs={12} sm={12} md={12} px={2} mt={3}>
                      <Typography fontWeight={600}>
                        {expertOnboardingState?.name ?? ''}
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      px={2}
                      mt={1}
                      display="flex"
                    >
                      {expertOnboardingState?.expertCategory.length > 0 &&
                        expertOnboardingState?.expertCategory?.map((val, i) => (
                          <Box key={i}>
                            {expertOnboardingState?.expertCategory.length ===
                            3 ? (
                              i === 0 ? (
                                <Typography
                                  fontSize={12}
                                  color="#888888"
                                  fontWeight={400}
                                >
                                  {val?.label} ,&nbsp;
                                </Typography>
                              ) : i === 1 ? (
                                <Typography
                                  fontSize={12}
                                  color="#888888"
                                  fontWeight={400}
                                >
                                  {val?.label} and&nbsp;
                                </Typography>
                              ) : (
                                <Typography
                                  fontSize={12}
                                  color="#888888"
                                  fontWeight={400}
                                >
                                  {val?.label}
                                </Typography>
                              )
                            ) : expertOnboardingState?.expertCategory.length ===
                              2 ? (
                              i === 0 ? (
                                <Typography
                                  fontSize={12}
                                  color="#888888"
                                  fontWeight={400}
                                >
                                  {val?.label} and&nbsp;
                                </Typography>
                              ) : (
                                <Typography
                                  fontSize={12}
                                  color="#888888"
                                  fontWeight={400}
                                >
                                  {val?.label}
                                </Typography>
                              )
                            ) : (
                              <Typography
                                fontSize={12}
                                color="#888888"
                                fontWeight={400}
                              >
                                {val?.label}
                              </Typography>
                            )}
                          </Box>
                        ))}
                      <Typography
                        fontSize={12}
                        color="#888888"
                        fontWeight={400}
                      >
                        &#160;Expert
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} px={2} mt={1}>
                      <Box sx={{ cursor: 'pointer' }} id="Edit">
                        <Typography
                          color="#6552CC"
                          fontWeight={600}
                          fontSize={12}
                        >
                          Edit{' '}
                          <AngleRight
                            rootStyle={{
                              color: '#6552CC',
                              height: '8px',
                            }}
                          />
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={5}
                  sm={5}
                  md={5}
                  display="flex"
                  justifyContent="end"
                  height="100%"
                >
                  <Box>
                    <ExpertProfileBannerImage
                      rootStyle={{ display: 'block' }}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <Box sx={profile_style.profileBannerSx}>
              <Box
                px={2}
                py={2.5}
                onClick={() => handleClick()}
                sx={{ cursor: 'pointer' }}
              >
                <Grid container display="flex" justifyContent="space-between">
                  <Grid item>
                    <Typography color="#F44F5A" fontSize={14} fontWeight={500}>
                      Logout
                    </Typography>
                  </Grid>
                  <Grid item>
                    <LogoutIcon
                      rootStyle={{ color: '#F44F5A', height: '16px' }}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Box>

            <Box sx={profile_style.profileVersionSx}>
              {`Version: ${localStorage.getItem(
                localStorageKeys.expertVersion
              )}`}
            </Box>
          </Box>
        )}
      </Box>

      <Drawer
        show={openDrawer}
        rootStyle={profile_style.drawerSx}
        childrenStyle={{ p: 0 }}
        onCloseDrawer={() => setOpenDrawer(false)}
        isStyledDrawer
        IsStyledDrawerContent={
          <Typography mb={2} fontSize={14}>
            Are you sure you want to{' '}
            <span style={{ fontWeight: 600 }}>Logout</span> ?
          </Typography>
        }
        headerStyle={{
          fontSize: '16px',
          fontWeight: 600,
          color: '#101010',
          textTransform: 'capitalize',
        }}
        footer={
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button onClick={onCancel} buttonStyle={profile_style.cancelBtnSx}>
              Cancel
            </Button>
            <Button
              onClick={onLogoutClick}
              buttonStyle={profile_style.applyBtnSx}
            >
              Logout
            </Button>
          </Box>
        }
      />
    </ExpertLayout>
  );
}

ExpertProfile.propTypes = {
  className: PropTypes.string,
};
