/* eslint-disable prefer-const */
/* eslint-disable no-restricted-syntax */
import { envConfig } from '@hc/config';
import { log } from '@hc/logger';
import {
  httpRequest,
  localStorageKeys,
  parseJwt,
  queryClient,
} from '@hc/utils';
import { toast } from 'react-hot-toast';
import { create } from 'zustand';

export const useExpertDraft = create((set, get) => ({
  draftState: {
    draftData: [],
    draftCount: '',
    planConstructedData: {},
    draftFilter: {
      search: '',
      gender_filter: '',
      date: '',
      date_filter: {
        start_date: '',
        end_date: '',
      },
      type_filter: '',
    },
  },
  loading: null,

  setDraftLocalStorage: () => {
    const { draftState } = get();
    localStorage.setItem(
      localStorageKeys?.draftState,
      JSON.stringify(draftState)
    );
  },

  getDraftDataLocalStorage: () => {
    const data = localStorage.getItem(localStorageKeys?.draftState);
    const jsonData = JSON.parse(data);
    if (jsonData) {
      set({
        draftState: jsonData,
      });
    }
  },
  getDraft: async (search, offset, isSearch) => {
    try {
      const { draftState, setDraftLocalStorage } = get();
      const { draftFilter } = draftState;
      const authToken = localStorage.getItem(localStorageKeys.authToken);
      const tokenData = parseJwt(authToken);
      set({ loading: true });
      const payload = {
        user_id: tokenData?.user_id ?? '',
        page: offset ?? 1,
        limit: 10,
        search: search ?? '',
        gender_filter: draftFilter?.gender_filter ?? '',
        date_filter: draftFilter?.date_filter ?? '',
        type_filter: draftFilter?.type_filter ?? '',
      };
      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['expert-draft', payload],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.api_url}/expert/plan-draft/get`,
            {
              ...payload,
            },
            true
          );
          return data;
        },
        staleTime: 120000,
      });

      let array = [];

      for (const val of data?.draft_plans) {
        let obj = {};
        let mind = tokenData?.is_mind_expert ? 'Inprogress' : 'Not yet';
        let body = tokenData?.is_body_expert ? 'Inprogress' : 'Not yet';
        let food = tokenData?.is_food_expert ? 'Inprogress' : 'Not yet';

        if (val?.mbf_user_plan?.mbf_expert_plan_statuses?.length > 0) {
          for (const object of val?.mbf_user_plan?.mbf_expert_plan_statuses) {
            if (object?.master_mbf_category?.id === 1) {
              mind =
                object?.created_by === tokenData?.user_profile_id
                  ? 'Inprogress'
                  : 'Completed';
            } else if (object?.master_mbf_category?.id === 2) {
              body =
                object?.created_by === tokenData?.user_profile_id
                  ? 'Inprogress'
                  : 'Completed';
            } else if (object?.master_mbf_category?.id === 3) {
              food =
                object?.created_by === tokenData?.user_profile_id
                  ? 'Inprogress'
                  : 'Completed';
            }
          }
        }

        obj = {
          ...val,
          name: val?.mbf_user?.profile_data?.name
            ? val?.mbf_user?.profile_data?.name
            : val?.mbf_user?.profile_data?.first_name,
          profile_pic: val?.mbf_user?.profile_data?.profile_pic,
          age: val?.mbf_user?.profile_data?.age,
          gender: val?.mbf_user?.profile_data?.gender,
          lastSavedDuration: val.updated_at,
          created_at: val.created_at,
          plan_type: val?.plan_type,
          mind,
          body,
          food,
        };
        array.push(obj);
      }

      const arrayData = isSearch ? array : [...draftState?.draftData, ...array];

      if (status?.code === 200) {
        const { draftState } = get();
        set({
          loading: false,
          draftState: {
            ...draftState,
            draftData: arrayData,
            draftCount: data?.count ?? 0,
          },
        });
      }
      setDraftLocalStorage();
      return status;
    } catch (err) {
      set({ loading: false });
      log('error', err);
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!'
      );
    }
  },

  updateDraftFilters: (key, value) => {
    const { setDraftLocalStorage } = get();

    const { draftState } = get();
    const { draftFilter } = draftState;
    set({
      draftState: {
        ...draftState,
        draftFilter: {
          ...draftFilter,
          [key]: value,
        },
      },
    });
    setDraftLocalStorage();
  },
  clearFilterState: () => {
    const { draftState, setDraftLocalStorage } = get();
    const { draftFilter } = draftState;

    set({
      draftState: {
        ...draftState,
        draftFilter: {
          ...draftFilter,
          gender_filter: '',
          date: '',
          date_filter: {
            start_date: '',
            end_date: '',
          },
          type_filter: '',
        },
      },
    });
    setDraftLocalStorage();
  },
}));
