/* eslint-disable jsx-a11y/anchor-is-valid */
import { expertRoutes } from '@hc/routes';
import { useExpertOnboarding } from '@hc/store';
import { localStorageKeys, parseJwt } from '@hc/utils';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { useNavigate, useLocation } from 'react-router-dom';
import { Button, ExpertLoginSplashLogo, Input, Label } from '../../../atoms';
import { expertCreateAccount_style } from './style';

function ExpertCreateAccount(props) {
  const {
    className = '',
    rootStyle = {},
    password = 'password',
    confirmPassword = 'confirmPassword',
    emailId = 'emailId',
    ...rest
  } = props;
  const authToken = localStorage.getItem(localStorageKeys.authToken);
  const tokenData = parseJwt(authToken);
  const navigate = useNavigate();
  const location = useLocation();
  const [showpassword, setPassword] = useState();
  const [showConfirmPassword, setConfirmPassword] = useState();
  const handleClickshowConfirmPassword = () => {
    setConfirmPassword(!showConfirmPassword);
  };

  const { expertOnboardingState, updateOnboarding, signUp, loading } =
    useExpertOnboarding((state) => ({
      expertOnboardingState: state.expertOnboardingState,
      signUp: state.signUp,
      loading: state.loading,
      updateOnboarding: state.updateOnboarding,
    }));

  console.log(expertOnboardingState, 'expertOnboardingState');

  const handleClickShowPassword = () => {
    setPassword(!showpassword);
  };

  const handleChange = (key, value) => {
    updateOnboarding(key, value);
  };

  const isValidToSave = () => {
    const expertUserCopy = JSON.parse(JSON.stringify(expertOnboardingState));
    const errorCopy = expertOnboardingState?.error;
    let isValid = true;

    // checking email
    const filter = /\S+@\S+\.\S+/;
    if (
      !expertUserCopy?.email?.length > 0 &&
      !filter.test(expertUserCopy?.email)
    ) {
      isValid = false;
      errorCopy.email = 'Please enter the valid mail';
    } else {
      errorCopy.email = '';
    }

    // checking password
    if (expertUserCopy?.password?.length >= 8) {
      if (!expertUserCopy?.password) {
        isValid = false;
        errorCopy.password = 'please enter the password..';
      } else if (
        !expertUserCopy?.password.match(
          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,}$/
        )
      ) {
        isValid = false;
        errorCopy.password =
          'Password must contain uppercase and lowercase letters, numbers, and special characters';
      } else {
        errorCopy.password = '';
      }
    } else {
      isValid = false;
      errorCopy.password = 'Password must be at least 8 characters long';
    }

    // checking confirm password

    if (!expertUserCopy?.confirmPassword?.length > 0) {
      isValid = false;
      errorCopy.confirmPassword = 'Please enter the confirm password';
    } else if (expertUserCopy?.confirmPassword !== expertUserCopy?.password) {
      isValid = false;
      errorCopy.confirmPassword =
        "Password doesn't matching, please check again";
    } else {
      errorCopy.confirmPassword = '';
    }
    updateOnboarding('error', errorCopy);
    return isValid;
  };

  const onSignUp = async () => {
    const result = isValidToSave();
    if (result) {
      const response = await signUp(tokenData?.user_id, location?.pathname);
      if (response.code === 200) {
        if (location?.pathname === expertRoutes?.createAccount) {
          navigate(expertRoutes?.addProfile);
        } else if (location?.pathname === expertRoutes?.resetPassword) {
          navigate(expertRoutes?.signIn);
        }
      }
    }
  };

  useEffect(() => {
    if (
      location?.pathname === expertRoutes?.createAccount &&
      !tokenData?.user_id
    ) {
      navigate(expertRoutes?.signIn);
    }
  }, [expertOnboardingState]);

  return (
    <Box
      sx={{
        ...expertCreateAccount_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Grid container rowSpacing={2}>
        <Grid item sm={12} md={12} lg={12} xs={12}>
          <ExpertLoginSplashLogo />
        </Grid>
        <Grid item sm={12} md={12} lg={12} xs={12}>
          <Typography variant="h5" sx={expertCreateAccount_style.titleSx}>
            {location?.pathname === expertRoutes?.createAccount
              ? 'Create account'
              : location?.pathname === expertRoutes?.resetPassword
              ? 'Reset password'
              : ''}
          </Typography>
        </Grid>
        <Grid item sm={12} md={12} lg={12} xs={12}>
          <Label htmlFor="Email" isRequired>
            Email
          </Label>
          <Input
            id={emailId}
            placeholder="Email"
            value={expertOnboardingState?.email ?? ''}
            disabled={tokenData?.user_id ? true : false}
            isError={expertOnboardingState?.error?.email?.length > 0}
            errorMessage={expertOnboardingState?.error?.email}
            onChange={(e) => handleChange('email', e.target.value)}
          />
        </Grid>
        <Grid item sm={12} md={12} lg={12} xs={12}>
          <Label htmlFor="password" isRequired>
            {location?.pathname === expertRoutes?.createAccount
              ? 'Create password'
              : location?.pathname === expertRoutes?.resetPassword
              ? 'New password'
              : ''}
          </Label>
          <Input
            id={password}
            type={showpassword ? 'text' : 'password'}
            onChange={(e) => handleChange('password', e.target.value)}
            isError={expertOnboardingState.error.password !== ''}
            errorMessage={expertOnboardingState.error.password}
            value={expertOnboardingState?.password ?? ''}
            endAdornment={
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => handleClickShowPassword()}
                edge="end"
              >
                {showpassword ? (
                  <MdVisibilityOff sx={expertCreateAccount_style.eyeSx} />
                ) : (
                  <MdVisibility sx={expertCreateAccount_style.eyeSx} />
                )}
              </IconButton>
            }
          />
        </Grid>
        <Grid item sm={12} md={12} lg={12} xs={12}>
          <Label htmlFor="confirmPassword" isRequired>
            Confirm password
          </Label>
          <Input
            id={confirmPassword}
            type={showConfirmPassword ? 'text' : 'password'}
            onChange={(e) => handleChange('confirmPassword', e.target.value)}
            isError={expertOnboardingState.error.confirmPassword !== ''}
            errorMessage={expertOnboardingState.error.confirmPassword}
            value={expertOnboardingState?.confirmPassword ?? ''}
            endAdornment={
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => handleClickshowConfirmPassword()}
                edge="end"
              >
                {showConfirmPassword ? (
                  <MdVisibilityOff sx={expertCreateAccount_style.eyeSx} />
                ) : (
                  <MdVisibility sx={expertCreateAccount_style.eyeSx} />
                )}
              </IconButton>
            }
          />
        </Grid>
        <Grid item mt={2} sm={12} md={12} lg={12} mb={3} xs={12}>
          <Button
            id="signUp"
            fullWidth
            onClick={() => onSignUp()}
            loading={loading}
            buttonStyle={expertCreateAccount_style.buttonsStyle}
          >
            {location?.pathname === expertRoutes?.createAccount
              ? 'Sign Up'
              : location?.pathname === expertRoutes?.resetPassword
              ? 'Reset '
              : ''}
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

ExpertCreateAccount.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  password: PropTypes.string,
  confirmPassword: PropTypes.string,
  emailId: PropTypes.string,
};

export { ExpertCreateAccount };
