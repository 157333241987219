export const expertPlanTemplateCard_style = {
  rootSx: {
    cursor: 'pointer',
  },
  cardRootSx: {
    borderRadius: '8px',
    border: '1px solid #E3E3E3',
  },

  cardTopContent: {
    bgcolor: '#F7F6FF',
    p: '11px',
    borderRadius: '8px',
  },
  cardBottomContent: {
    bgcolor: '#fff',
    p: '11px',
    borderRadius: '8px',
  },
};
