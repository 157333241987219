/* eslint-disable prefer-const */
/* eslint-disable no-restricted-syntax */
import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { CloseIcon, EditIcon } from '../../../atoms';
import { expertPlanCard_style } from './style';

function ExpertPlanCard(props) {
  const {
    className = '',
    rootStyle = {},
    data = {},
    onDelete = () => {},
    onEdit = () => {},
    viewOnly,
    ...rest
  } = props;

  const dayData = [
    {
      label: 'S',
      value: '1',
    },
    {
      label: 'M',
      value: '2',
    },
    {
      label: 'T',
      value: '3',
    },
    {
      label: 'W',
      value: '4',
    },
    {
      label: 'T',
      value: '5',
    },
    {
      label: 'F',
      value: '6',
    },
    {
      label: 'S',
      value: '7',
    },
  ];

  return (
    <Box
      sx={{
        ...expertPlanCard_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Box sx={expertPlanCard_style.cardRootSx}>
        <Grid container>
          <Grid item xs={2.8}>
            <Box
              sx={{
                width: '66px',
                backgroundColor: '#fff',
                color: '#0E1824',

                transition:
                  ' box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
                borderRadius: '4px',
                border: '1px solid #DBDBDB',
                overflow: 'hidden',
              }}
            >
              <Box
                sx={
                  viewOnly
                    ? { ...expertPlanCard_style.imgBox2Sx }
                    : { ...expertPlanCard_style.imgBoxSx }
                }
              >
                <img
                  style={
                    viewOnly
                      ? {
                          height: '63px',
                          objectFit: 'fill',
                        }
                      : {
                          opacity: '0.6',
                          height: '63px',
                          objectFit: 'fill',
                        }
                  }
                  src={data?.activity_image}
                  alt=""
                />

                <Box
                  sx={{ position: 'absolute', cursor: 'pointer' }}
                  onClick={() => onEdit(data?.id)}
                  alignSelf="center"
                >
                  {!viewOnly && (
                    <Box>
                      <EditIcon
                        rootStyle={{
                          height: '17px',
                          width: '17px',
                          color: '#fff',
                        }}
                      />
                    </Box>
                  )}
                </Box>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={9.2}>
            <Grid container rowSpacing={0.5}>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                display="flex"
                justifyContent="space-between"
              >
                <Box>
                  <Typography fontSize={14} fontWeight={600}>
                    {data?.activity_name}
                  </Typography>
                </Box>
                {!viewOnly && (
                  <Box
                    sx={{ cursor: 'pointer' }}
                    onClick={() => onDelete(data?.id)}
                  >
                    <CloseIcon rootStyle={{ color: '#EB6D13' }} />
                  </Box>
                )}
              </Grid>
              <Grid item xs={12} sm={12} md={12} display="flex">
                {data?.is_measurable && (
                  <Box>
                    <Typography fontSize={12} color="#888888">
                      {` ${data?.quantity ?? ''} ${data?.unit_name ?? ''}`}
                    </Typography>
                  </Box>
                )}
                <Box>
                  <Typography
                    pl={data?.is_measurable ? 1 : ''}
                    fontSize={12}
                    color="#888888"
                  >
                    {`• ${data?.goal_name}`}
                  </Typography>
                </Box>
              </Grid>
              <Grid
                item
                xs={12}
                sm={12}
                md={12}
                display="flex"
                justifyContent="space-between"
              >
                <Box>
                  <Typography fontSize={12} color="#888888">
                    {data?.activity_time_name}
                  </Typography>
                </Box>
                <Box display="flex">
                  {dayData.map((val, i) => (
                    <Typography
                      key={i}
                      pl={0.5}
                      fontSize={12}
                      color={
                        data?.activity_days.some((value) => val.value === value)
                          ? '#EE8438'
                          : '#888888'
                      }
                    >
                      {val?.label}
                    </Typography>
                  ))}
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

ExpertPlanCard.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  viewOnly: PropTypes.bool,
  data: PropTypes.object,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
};

export { ExpertPlanCard };
