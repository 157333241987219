/* eslint-disable array-callback-return */
import { Box, Stack, Typography } from '@mui/material';
import { RiCheckboxBlankCircleFill } from 'react-icons/ri';
import PropTypes from 'prop-types';
import { planDetailCard_style } from './style';
import { CalendarIcon } from '../../../atoms';

function PlanDetailCard(props) {
  const {
    className = '',
    rootStyle = {},
    onClickFun = () => {},
    Date = '',
    duration,
    durationType = '',
    activities = '',
    planName = '',
    draft = false,
    publish = false,
    category = [],
    ...rest
  } = props;

  const showCatogory = (arrData) => {
    let dataaa = [];
    if (arrData?.length === 1) {
      arrData?.[0] === '1'
        ? (dataaa = ['Mind'])
        : arrData?.[0] === '2'
        ? (dataaa = ['Body'])
        : arrData?.[0] === '3'
        ? (dataaa = ['Food'])
        : (dataaa = ['Mind']);
    } else if (arrData?.length === 2) {
      arrData?.map((val, i) => {
        i === 0
          ? dataaa.push(
              val === '1'
                ? 'Mind'
                : val === '2'
                ? 'Body'
                : val === '3'
                ? 'Food'
                : 'Mind',
            )
          : dataaa.push(
              val === '1'
                ? ' & Mind'
                : val === '2'
                ? ' & Body'
                : val === '3'
                ? ' & Food'
                : ' & Mind',
            );
      });
    } else if (arrData?.length === 3) {
      arrData?.map((val, i) => {
        i === 0
          ? dataaa.push(
              val === '1'
                ? 'Mind'
                : val === '2'
                ? 'Body'
                : val === '3'
                ? 'Food'
                : 'Mind',
            )
          : i === 1
          ? dataaa.push(
              val === '1'
                ? ', Mind'
                : val === '2'
                ? ', Body'
                : val === '3'
                ? ', Food'
                : ', Mind',
            )
          : dataaa.push(
              val === '1'
                ? ' & Mind'
                : val === '2'
                ? ' & Body'
                : val === '3'
                ? ' & Food'
                : ' & Mind',
            );
      });
    }
    return dataaa;
  };

  return (
    <Box
      sx={{
        ...planDetailCard_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Box
        sx={planDetailCard_style.cardContainerSx}
        px={1.5}
        pt={1}
        pb={0.8}
        onClick={onClickFun}
        id="defaultCard"
      >
        <Stack
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack flexDirection="row" alignItems="center">
            <CalendarIcon rootStyle={{ color: '#888888' }} />
            <Typography sx={planDetailCard_style.cardTitleSx}>
              &nbsp; create on
              <span style={planDetailCard_style.cardSubTitleSx}> {Date}</span>
            </Typography>
          </Stack>
          {draft && (
            <Typography sx={planDetailCard_style.draftTextSx}>
              {' '}
              In Draft
            </Typography>
          )}
          {publish && (
            <Typography sx={planDetailCard_style.publishTextSx}>
              {' '}
              Published
            </Typography>
          )}
        </Stack>
        <Stack mt={1.5}>
          <Typography sx={planDetailCard_style.planFontSx}>
            {' '}
            {planName}
          </Typography>{' '}
        </Stack>
        <Stack flexDirection="row" justifyContent="space-between" mt={0.4}>
          <Stack flexDirection="row" alignItems="center">
            <Typography sx={planDetailCard_style.monthFontSx}>
              {`${duration} ${durationType}`}
            </Typography>
            &nbsp;
            <RiCheckboxBlankCircleFill
              style={{ fontSize: '4px', ml: 1, pr: 2, color: 'grey' }}
            />
            &nbsp;
            <Typography sx={planDetailCard_style.activityFontSx}>
              {`${activities} activities`}
            </Typography>
          </Stack>
          <Typography sx={planDetailCard_style.activityTypeFont}>
            {' '}
            {showCatogory(category)}
          </Typography>{' '}
        </Stack>
      </Box>
    </Box>
  );
}

PlanDetailCard.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  onClickFun: PropTypes.func,
  Date: PropTypes.string,
  duration: PropTypes.any,
  durationType: PropTypes.string,
  activities: PropTypes.string,
  planName: PropTypes.string,
  draft: PropTypes.bool,
  publish: PropTypes.bool,
  category: PropTypes.array,
};

export { PlanDetailCard };
