/* eslint-disable jsx-a11y/anchor-is-valid */
import { Input, Label } from '@atoms';
import { expertRoutes } from '@hc/routes';
import { useExpertOnboarding } from '@hc/store';
import { ExpertLoginSplashLogo } from '@hc/ui/atoms/illustration/illustrationSetOne';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { MdVisibility, MdVisibilityOff } from 'react-icons/md';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '../../../atoms';
import { expertSignin_style } from './style';

function ExpertSignin(props) {
  const { className = '', rootStyle = {}, ...rest } = props;
  const [showpassword, setPassword] = useState();

  const navigate = useNavigate();

  const { expertOnboardingState, updateOnboarding, signIn, loading } =
    useExpertOnboarding((state) => ({
      expertOnboardingState: state.expertOnboardingState,
      signIn: state.signIn,
      loading: state.loading,
      updateOnboarding: state.updateOnboarding,
    }));

  const handleChange = (key, value) => {
    updateOnboarding(key, value);
  };

  const handleClickShowPassword = () => {
    setPassword(!showpassword);
  };

  const isValidToSave = () => {
    const expertUserCopy = JSON.parse(JSON.stringify(expertOnboardingState));
    const errorCopy = expertOnboardingState?.error;
    let isValid = true;

    // checking email
    const filter = /\S+@\S+\.\S+/;
    if (
      !expertUserCopy?.email?.length > 0 &&
      !filter.test(expertUserCopy?.email)
    ) {
      isValid = false;
      errorCopy.email = 'Please enter the valid mail';
    } else {
      errorCopy.email = '';
    }

    // checking password

    if (!expertUserCopy?.password) {
      isValid = false;
      errorCopy.password = 'please enter your password..';
    } else {
      errorCopy.password = '';
    }

    updateOnboarding('error', errorCopy);
    return isValid;
  };

  const onSignIn = async () => {
    const result = isValidToSave();
    if (result) {
      const response = await signIn();
      if (response.code === 200) {
        navigate(expertRoutes?.home);
      }
    }
  };

  return (
    <Box
      sx={{
        ...expertSignin_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      <Grid container rowSpacing={2}>
        <Grid item sm={12} md={12} lg={12} xs={12}>
          <ExpertLoginSplashLogo />
        </Grid>
        <Grid item sm={12} md={12} lg={12} xs={12}>
          <Typography variant="h5" sx={expertSignin_style.titleSx}>
            Welcome
          </Typography>
        </Grid>
        <Grid item sm={12} md={12} lg={12} xs={12}>
          <Label htmlFor="Email" isRequired>
            Email
          </Label>
          <Input
            id="emailId"
            placeholder="Email"
            value={expertOnboardingState?.email ?? ''}
            isError={expertOnboardingState?.error?.email?.length > 0}
            errorMessage={expertOnboardingState?.error?.email}
            onChange={(e) => handleChange('email', e.target.value)}
          />
        </Grid>
        <Grid item sm={12} md={12} lg={12} xs={12}>
          <Label htmlFor="password" isRequired>
            Password
          </Label>
          <Input
            id="password"
            // data-testid = 'password'
            placeholder="Password"
            type={showpassword ? 'text' : 'password'}
            onChange={(e) => handleChange('password', e.target.value)}
            isError={expertOnboardingState.error.password !== ''}
            errorMessage={expertOnboardingState.error.password}
            value={expertOnboardingState?.password ?? ''}
            endAdornment={
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => handleClickShowPassword()}
                edge="end"
              >
                {showpassword ? (
                  <MdVisibilityOff               
                    sx={expertSignin_style.eyeSx}
                  />
                ) : (
                  <MdVisibility                  
                    sx={expertSignin_style.eyeSx}
                  />
                )}
              </IconButton>
            }
          />
          <Link
            to={expertRoutes.forgotPassword}
            style={{ width: 'max-content', marginLeft: 'auto' }}
          >
            <Typography
              sx={expertSignin_style.forgotpasswordSx}
              variant="subtitle2"
            >
              Forgot password?
            </Typography>
          </Link>
        </Grid>
        <Grid item mt={2} sm={12} md={12} lg={12} xs={12}>
          <Button
            id="signIn"
            fullWidth
            onClick={() => onSignIn()}
            loading={loading}
            buttonStyle={expertSignin_style.buttonsStyle}
          >
            Sign in
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

ExpertSignin.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
};

export { ExpertSignin };
