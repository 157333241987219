/* eslint-disable no-lonely-if */
/* eslint-disable import/no-cycle */
/* eslint-disable no-param-reassign */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-unused-vars */
/* eslint-disable prefer-const */
import { envConfig } from '@hc/config';
import { getTimeZoneFnc } from '@hc/dayjs';
import { log } from '@hc/logger';
import {
  httpRequest,
  localStorageKeys,
  parseJwt,
  previewPlanDataContsruct,
  queryClient,
} from '@hc/utils';
import { toast } from 'react-hot-toast';
import { create } from 'zustand';
import { useCreateAndAddActivity } from './createAndAddActivity';
import { useExpertRequest } from './request';

export const usePlanAddEditTool = create((set, get) => ({
  planAddEditToolState: {
    planDurationData: {
      plan_name: '',
      duration_type: '',
      duration: '',
    },
    error: {
      plan_name: '',
      duration_type: '',
      duration: '',
    },
    isScratch: false,
    isDraft: false,
    isHistory: false,
    masterByMe: false,
    masterByDefault: false,
    calenderData: [],
    overAllConstructedPlanData: {},
    overAllPlanData: {},
    planData: {},
    dayActivityData: [],
    previuousExpertData: {},
    expert: {},
    userData: {},
    expertNotes: {
      mind: {
        isAddNotes: '',
        data: '',
        isNotesAdded: false,
        isAddedByMe: true,
      },
      body: {
        isAddNotes: '',
        data: '',
        isNotesAdded: false,
        isAddedByMe: true,
      },
      food: {
        isAddNotes: '',
        data: '',
        isNotesAdded: false,
        isAddedByMe: true,
      },
    },
  },
  loading: null,
  draftLoading: null,
  publishLoading: null,

  setLocalStorage: () => {
    const { planAddEditToolState } = get();
    localStorage.setItem(
      localStorageKeys?.planAddEditToolState,
      JSON.stringify(planAddEditToolState)
    );
  },
  getLocalStorage: () => {
    const data = localStorage.getItem(localStorageKeys?.planAddEditToolState);
    const jsonData = JSON.parse(data);
    if (jsonData) {
      set({
        planAddEditToolState: jsonData,
      });
    }
  },

  clearPlanDetailes: () => {
    const { planAddEditToolState, setLocalStorage } = get();
    const { planDurationData } = planAddEditToolState;
    set({
      planAddEditToolState: {
        ...planAddEditToolState,
        planDurationData: {
          ...planDurationData,
          plan_name: '',
          duration_type: '',
          duration: '',
        },
      },
    });
  },

  clearPlanAddEditState: () => {
    const { planAddEditToolState, setLocalStorage } = get();
    set({
      planAddEditToolState: {
        ...planAddEditToolState,
        planDurationData: {
          plan_name: '',
          duration_type: '',
          duration: '',
        },
        error: {
          plan_name: '',
          duration_type: '',
          duration: '',
        },
        isScratch: false,
        isDraft: false,
        isHistory: false,
        masterByMe: false,
        masterByDefault: false,
        calenderData: [],
        overAllConstructedPlanData: {},
        overAllPlanData: {},
        planData: {},
        dayActivityData: [],
        previuousExpertData: {},
        expert: {},
        userData: {},
        expertNotes: {
          mind: {
            isAddNotes: '',
            data: '',
            isNotesAdded: false,
            isAddedByMe: true,
          },
          body: {
            isAddNotes: '',
            data: '',
            isNotesAdded: false,
            isAddedByMe: true,
          },
          food: {
            isAddNotes: '',
            data: '',
            isNotesAdded: false,
            isAddedByMe: true,
          },
        },
      },
    });
    setLocalStorage();
  },

  onDurationUpdation: (key, value) => {
    const { planAddEditToolState, setLocalStorage } = get();
    const { planDurationData } = planAddEditToolState;

    set(() => ({
      planAddEditToolState: {
        ...planAddEditToolState,
        planDurationData: {
          ...planDurationData,
          [key]: value,
        },
      },
    }));
    setLocalStorage();
  },

  getPlanDetailsById: async (userPlanId, type) => {
    try {
      const { planAddEditToolState, onDurationUpdation, setLocalStorage } =
        get();
      const { planDurationData } = planAddEditToolState;
      set({ loading: true });
      const payload = {
        mbf_user_plan_id: userPlanId,
      };
      queryClient.invalidateQueries({
        queryKey: ['get-plan-data-by-plan-id'],
      });
      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['get-plan-data-by-plan-id'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.api_url}/expert/get-plan-data-by-plan-id`,
            {
              ...payload,
            },
            true
          );
          return data;
        },
        staleTime: 120000,
      });

      if (status?.code === 200) {
        if (type === 'planTemplatePreviousData') {
          set({
            loading: false,
            planAddEditToolState: {
              ...planAddEditToolState,
              previuousExpertData: data,
              planDurationData: {},
            },
          });
        } else if (type === 'previousData') {
          const constructPreviousPlanData = previewPlanDataContsruct(data);
          const { updateRequestState } = useExpertRequest.getState();
          onDurationUpdation('plan_name', data?.planData?.plan_name ?? '');
          onDurationUpdation(
            'duration_type',
            data?.planData?.duration_type ?? ''
          );
          onDurationUpdation('duration', data?.planData?.duration ?? '');
          updateRequestState('planConstructedData', constructPreviousPlanData);
          updateRequestState('planTemplateDataById', data);
          set({
            loading: false,
            planAddEditToolState: {
              ...planAddEditToolState,
              overAllConstructedPlanData: constructPreviousPlanData,
              planDurationData: {
                ...planDurationData,
                plan_name: data?.planData?.plan_name ?? '',
                duration_type: data?.planData?.duration_type ?? '',
                duration: data?.planData?.duration ?? '',
              },
              previuousExpertData: data,
            },
          });
        } else if (type === 'ScratchPlan') {
          const constructPreviousPlanData = previewPlanDataContsruct(data);
          const { updateRequestState } = useExpertRequest.getState();
          updateRequestState('planConstructedData', constructPreviousPlanData);
          updateRequestState('planTemplateDataById', data);
          set({
            loading: false,
            planAddEditToolState: {
              ...planAddEditToolState,
              overAllConstructedPlanData: constructPreviousPlanData,
              previuousExpertData: data,
            },
          });
        }
      }
      setLocalStorage();
      const result = {
        data,
        status,
      };
      return result;
    } catch (err) {
      set({ loading: false });
      log('error', err);
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!'
      );
    }
  },

  getUserProfile: async (userPlanId) => {
    try {
      const { planAddEditToolState, onDurationUpdation, setLocalStorage } =
        get();
      const { planDurationData } = planAddEditToolState;
      const authToken = localStorage.getItem(localStorageKeys.authToken);
      const tokenData = parseJwt(authToken);
      set({ loading: true });

      queryClient.invalidateQueries({
        queryKey: ['mbf-user-plan-details-get'],
      });
      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['mbf-user-plan-details-get'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'get',
            `${envConfig.api_url}/expert/mbf-user-plan/details/get?user_id=${
              tokenData?.user_id ?? ''
            }&user_plan_id=${userPlanId}`,
            {},
            true
          );
          return data;
        },
        staleTime: 120000,
      });

      if (status?.code === 200) {
        // -------------
      }
      setLocalStorage();
      const result = {
        data,
        status,
      };
      return result;
    } catch (err) {
      set({ loading: false });
      log('error', err);
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!'
      );
    }
  },

  constucttPublishOrDrftPlanPayload: (value, key) => {
    const authToken = localStorage.getItem(localStorageKeys.authToken);
    const tokenData = parseJwt(authToken);
    const { planAddEditToolState } = get();
    let result = {};
    let expertNoteArr = [];
    let expertStatusArr = [];
    let expertActivityArr = [];

    const dateData = [
      {
        label: 'SUN',
        value: 1,
      },
      {
        label: 'MON',
        value: 2,
      },
      {
        label: 'TUE',
        value: 3,
      },
      {
        label: 'WED',
        value: 4,
      },
      {
        label: 'THU',
        value: 5,
      },
      {
        label: 'FRI',
        value: 6,
      },
      {
        label: 'SAT',
        value: 7,
      },
    ];

    const resArr = [
      {
        parentKey: 'mind',
        expert_category_id: 1,
        expert: tokenData?.is_mind_expert,
      },
      {
        parentKey: 'body',
        expert_category_id: 2,
        expert: tokenData?.is_body_expert,
      },
      {
        parentKey: 'food',
        expert_category_id: 3,
        expert: tokenData?.is_food_expert,
      },
    ];

    for (const data of resArr) {
      if (planAddEditToolState?.userData?.[data?.parentKey] === 'Completed') {
        for (const objOf of planAddEditToolState?.previuousExpertData
          ?.noteData) {
          if (data?.expert_category_id === objOf?.expert_category_id) {
            let noteObj = objOf;
            expertNoteArr.push(noteObj);
          }
        }
      } else if (
        planAddEditToolState?.userData?.[data?.parentKey] === 'Inprogress'
      ) {
        if (
          planAddEditToolState?.expertNotes?.[data?.parentKey]?.isAddNotes &&
          planAddEditToolState?.expertNotes?.[data?.parentKey]?.isNotesAdded
        ) {
          if (
            planAddEditToolState?.overAllConstructedPlanData?.[
              data?.parentKey
            ]?.planDayData
              .map((val) => val.plan.length > 0)
              .filter((valid) => valid === true).length > 0
          ) {
            let noteObj = {
              id: '',
              expert_notes:
                planAddEditToolState?.expertNotes?.[data?.parentKey]?.data ??
                {},
              expert_category_id: data?.expert_category_id,
              expert_created_by: '',
              createdAt: '',
              updatedAt: '',
            };
            expertNoteArr.push(noteObj);
          }
        }
      }

      if (planAddEditToolState?.userData?.[data?.parentKey] === 'Completed') {
        for (const objOf of planAddEditToolState?.previuousExpertData
          ?.statusData) {
          if (data?.expert_category_id === objOf?.expert_category_id) {
            let statusObj = objOf;
            expertStatusArr.push(statusObj);
          }
        }
      } else if (
        planAddEditToolState?.userData?.[data?.parentKey] === 'Inprogress'
      ) {
        if (
          planAddEditToolState?.overAllConstructedPlanData?.[
            data?.parentKey
          ]?.planDayData
            .map((val) => val.plan.length > 0)
            .filter((valid) => valid === true).length > 0
        ) {
          let statusObj = {
            id: '',
            expert_category_id: data?.expert_category_id,
            status: value,
            expert_created_by: '',
            createdAt: '',
            updatedAt: '',
          };
          expertStatusArr.push(statusObj);
        }
      } else {
        if (planAddEditToolState?.masterByMe) {
          if (
            planAddEditToolState?.overAllConstructedPlanData?.[
              data?.parentKey
            ]?.planDayData
              .map((val) => val.plan.length > 0)
              .filter((valid) => valid === true).length > 0
          ) {
            let statusObj = {
              id: '',
              expert_category_id: data?.expert_category_id,
              status: value,
              expert_created_by: '',
              createdAt: '',
              updatedAt: '',
            };
            expertStatusArr.push(statusObj);
          }
        }
      }
    }

    for (const object of dateData) {
      let obj = {};
      let activityDataArr = [];
      for (const data of resArr) {
        if (
          (data?.expert &&
            planAddEditToolState?.userData?.[data?.parentKey] ===
              'Inprogress') ||
          planAddEditToolState?.userData?.[data?.parentKey] === 'Completed'
        ) {
          for (const val of planAddEditToolState?.overAllConstructedPlanData?.[
            data?.parentKey
          ]?.planDayData) {
            if (val?.day === object?.label) {
              for (const objectOf of val?.plan) {
                activityDataArr.push(objectOf);
              }
            }
          }
        } else {
          if (planAddEditToolState?.masterByMe) {
            for (const val of planAddEditToolState
              ?.overAllConstructedPlanData?.[data?.parentKey]?.planDayData) {
              if (val?.day === object?.label) {
                for (const objectOf of val?.plan) {
                  activityDataArr.push(objectOf);
                }
              }
            }
          }
        }
      }
      obj = {
        day_id: object?.value,
        expert_created_by: '',
        activity_json: activityDataArr,
      };
      expertActivityArr.push(obj);
    }

    if (key === 'history') {
      result = {
        user_id: tokenData?.user_id ?? '',
        mbf_user_plan_id:
          planAddEditToolState?.isDraft || planAddEditToolState?.isHistory
            ? planAddEditToolState?.userData?.plan_id ?? ''
            : planAddEditToolState?.userData?.id ?? '',
        expert_notes: expertNoteArr,
        plan_activity_days: expertActivityArr,
      };
    } else {
      result = {
        user_id: tokenData?.user_id ?? '',
        mbf_user_plan_id: planAddEditToolState?.isDraft
          ? planAddEditToolState?.userData?.plan_id ??
            planAddEditToolState?.userData?.id
          : planAddEditToolState?.userData?.id ?? '',
        plan_name: planAddEditToolState?.planDurationData?.plan_name ?? '',
        plan_duration: planAddEditToolState?.planDurationData.duration ?? '',
        plan_duration_type:
          planAddEditToolState?.planDurationData.duration_type === 'Days'
            ? 1
            : planAddEditToolState?.overAllPlanData?.planData?.duration_type ===
              'Weeks'
            ? 2
            : 3,
        expert_notes: expertNoteArr,
        expert_plan_statuses: expertStatusArr,
        plan_activity_days: expertActivityArr,
        timeZone: getTimeZoneFnc(),
      };
    }

    return result;
  },

  upsertPublishOrDraftPlan: async (value, key) => {
    const { setLocalStorage, constucttPublishOrDrftPlanPayload } = get();
    try {
      value === 1 ? set({ draftLoading: true }) : set({ publishLoading: true });
      const payload = !key
        ? constucttPublishOrDrftPlanPayload(value)
        : constucttPublishOrDrftPlanPayload(value, key);
      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['plan-save-publish'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            !key
              ? `${envConfig.api_url}/expert/plan/save-publish`
              : `${envConfig.api_url}/expert/plan/activities/edit`,
            {
              ...payload,
            },
            true
          );
          return data;
        },
        staleTime: 12000,
      });

      if (status?.code === 200) {
        toast.success(status?.message);
        value === 1
          ? set({ draftLoading: false })
          : set({ publishLoading: false });
      }
      setLocalStorage();
      return status;
    } catch (err) {
      value === 1
        ? set({ draftLoading: false })
        : set({ publishLoading: false });
      log('error', err);
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!'
      );
    }
  },

  upsertplanTemplate: async (value) => {
    const {
      setLocalStorage,
      planAddEditToolState,
      constucttPublishOrDrftPlanPayload,
    } = get();
    const { planDurationData } = planAddEditToolState;
    try {
      set({ loading: true });

      const result = constucttPublishOrDrftPlanPayload(value);
      const categoryId = [];
      const resArr = [];
      for (const val of result?.expert_plan_statuses) {
        categoryId.push(val?.expert_category_id);
      }

      for (const object of result?.plan_activity_days[0]?.activity_json) {
        let obj = {
          id: object?.id ?? '',
          activity_name: object?.activity_name ?? '',
          activity_image: object?.activity_image ?? '',
          category_id: object?.category_id ?? 0,
          category_name: object?.category_name ?? '',
          goal_id: object?.goal_id ?? 0,
          goal_name: object?.goal_name ?? '',
          is_measurable: object?.is_measurable ?? '',
          quantity: object?.quantity ?? 0,
          unit: object?.unit ?? null,
          unit_name: object?.unit_name ?? '',
          activity_days: object?.activity_days ?? [],
          activity_time: object?.activity_time ?? 0,
          activity_time_name: object?.activity_time_name ?? '',
          specific_time: object?.specific_time ?? { from: '', to: '' },
          activity_notes: object?.activity_notes,
          activity_dont: object?.activity_dont,
          activity_references: object?.activity_references,
        };
        resArr.push(obj);
      }

      const payload = {
        id: planAddEditToolState?.masterByMe
          ? planAddEditToolState?.overAllPlanData?.planData?.id
          : '',
        plan_name: planDurationData?.plan_name ?? '',
        category_id: categoryId,
        duration: planDurationData?.duration ?? '',
        duration_type:
          planDurationData?.duration?.duration_type === 'Days'
            ? 1
            : planDurationData?.duration?.duration_type === 'Weeks'
            ? 2
            : 3,
        is_active: true,
        status: 2,
        activity_data: resArr,
      };

      const { data, status } = await queryClient.fetchQuery({
        queryKey: ['planTemplate-upsert-plan'],
        queryFn: async () => {
          const { data } = await httpRequest(
            'post',
            `${envConfig.api_url}/planTemplate/upsert-plan`,
            {
              ...payload,
            },
            true
          );
          return data;
        },
        staleTime: 12000,
      });

      if (status?.code === 200) {
        set({ loading: false });
        if (status.message === 'Plan Name Already Exist') {
          toast.error(`${status?.message} ,Please edit the plan name`);
        } else {
          toast.success(status?.message);
        }
      }
      setLocalStorage();
      return status;
    } catch (err) {
      set({ loading: false });
      log('error', err);
      return toast.error(
        err?.response?.data?.status?.message ??
          err?.message ??
          'Something went wrong please try again!'
      );
    }
  },

  onExpertNotesUpdate: (parentKey, childKey, value) => {
    const { planAddEditToolState, setLocalStorage } = get();
    const { expertNotes } = planAddEditToolState;
    set({
      planAddEditToolState: {
        ...planAddEditToolState,
        expertNotes: {
          ...expertNotes,
          [parentKey]: {
            ...expertNotes?.[parentKey],
            [childKey]: value,
          },
        },
      },
    });
    setLocalStorage();
  },

  onUpdateToolData: (key, value) => {
    const { planAddEditToolState, setLocalStorage } = get();
    set({
      planAddEditToolState: {
        ...planAddEditToolState,
        [key]: value,
      },
    });
    setLocalStorage();
  },

  constructCalanderData: (value) => {
    const { planAddEditToolState, setLocalStorage } = get();
    let overAllDataCopy = JSON.parse(
      JSON.stringify(planAddEditToolState?.overAllConstructedPlanData)
    );
    let resArr = [];

    const calenderData = [
      {
        day: 'SUN',
        value: 1,
        taskCount: 0,
      },
      {
        day: 'MON',
        value: 2,
        taskCount: 0,
      },
      {
        day: 'TUE',
        value: 3,
        taskCount: 0,
      },
      {
        day: 'WED',
        value: 4,
        taskCount: 0,
      },
      {
        day: 'THU',
        value: 5,
        taskCount: 0,
      },
      {
        day: 'FRI',
        value: 6,
        taskCount: 0,
      },
      {
        day: 'SAT',
        value: 7,
        taskCount: 0,
      },
    ];

    overAllDataCopy?.[value]?.planDayData.forEach((data, index) => {
      const obj = {
        day: data?.day ?? '',
        value: index + 1,
        taskCount: data?.plan?.length,
      };
      resArr.push(obj);
    });

    set({
      planAddEditToolState: {
        ...planAddEditToolState,
        calenderData: resArr?.length > 0 ? resArr : calenderData,
      },
    });
    setLocalStorage();
  },

  constructDayActivityData: (value, i) => {
    const { planAddEditToolState, setLocalStorage } = get();
    let overAllDataCopy = JSON.parse(
      JSON.stringify(planAddEditToolState?.overAllConstructedPlanData)
    );

    let obj = {};
    overAllDataCopy?.[value]?.planDayData.forEach((data, index) => {
      if (index === i) {
        obj = {
          ...data,
        };
      }
    });
    set({
      planAddEditToolState: {
        ...planAddEditToolState,
        planData: {
          ...obj,
        },
      },
    });
    setLocalStorage();
  },

  deletePlanData: (planId, dayValue, plan) => {
    const { planAddEditToolState, setLocalStorage } = get();
    let overAllDataCopy = JSON.parse(
      JSON.stringify(planAddEditToolState?.overAllConstructedPlanData)
    );
    // let arr = [];
    // overAllDataCopy?.[plan]?.planDayData.forEach((data, index) => {
    //   if (dayValue === data?.day) {
    //     for (const val of data?.plan) {
    //       if (planId !== val?.id) {
    //         arr.push(val);
    //       }
    //     }
    //   }
    // });
    // overAllDataCopy?.[plan]?.planDayData.forEach((data, index, array) => {
    //   if (dayValue === data?.day) {
    //     array[index] = {
    //       ...data,
    //       plan: arr,
    //     };
    //   }
    // });
    overAllDataCopy?.[plan]?.planDayData.forEach((data, index, arr) => {
      let resArr = [];
      for (const object of data?.plan) {
        if (object?.id !== planId) {
          resArr.push(object);
        }
      }
      arr[index] = {
        ...data,
        plan: resArr,
      };
    });
    set({
      loading: false,
      planAddEditToolState: {
        ...planAddEditToolState,
        overAllConstructedPlanData: overAllDataCopy,
      },
    });

    set({
      loading: false,
      planAddEditToolState: {
        ...planAddEditToolState,
        overAllConstructedPlanData: overAllDataCopy,
      },
    });
    setLocalStorage();
  },
  createPlanData: () => {
    const { planAddEditToolState, constructDayActivityData, setLocalStorage } =
      get();
    const { activityState } = useCreateAndAddActivity.getState();
    const { createNewActivityData } = activityState;

    function capitalize(string) {
      return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
    }
    function lowerCase(string) {
      return string.charAt(0).toLowerCase() + string.slice(1).toLowerCase();
    }

    const calender = [
      {
        day: 'SUN',
        value: 1,
        taskCount: 0,
      },
      {
        day: 'MON',
        value: 2,
        taskCount: 0,
      },
      {
        day: 'TUE',
        value: 3,
        taskCount: 0,
      },
      {
        day: 'WED',
        value: 4,
        taskCount: 0,
      },
      {
        day: 'THU',
        value: 5,
        taskCount: 0,
      },
      {
        day: 'FRI',
        value: 6,
        taskCount: 0,
      },
      {
        day: 'SAT',
        value: 7,
        taskCount: 0,
      },
    ];

    let overAllDataCopy = {};

    if (
      !planAddEditToolState?.isScratch ||
      Object.keys(planAddEditToolState?.overAllConstructedPlanData).length > 0
    ) {
      overAllDataCopy = JSON.parse(
        JSON.stringify(planAddEditToolState?.overAllConstructedPlanData)
      );
    } else {
      overAllDataCopy = {
        mind: {
          planName: planAddEditToolState?.planDurationData?.plan_name,
          planDayData: [],
        },
        body: {
          planName: planAddEditToolState?.planDurationData?.plan_name,
          planDayData: [],
        },
        food: {
          planName: planAddEditToolState?.planDurationData?.plan_name,
          planDayData: [],
        },
      };
    }

    const calenderData = [];
    for (const object of createNewActivityData?.activity_days) {
      let obj = {
        day:
          object === '1'
            ? 'SUN'
            : object === '2'
            ? 'MON'
            : object === '3'
            ? 'TUE'
            : object === '4'
            ? 'WED'
            : object === '5'
            ? 'THU'
            : object === '6'
            ? 'FRI'
            : 'SAT',
        value: object,
      };
      calenderData.push(obj);
    }

    if (
      overAllDataCopy?.[lowerCase(createNewActivityData?.category_name)]
        ?.planDayData.length > 0
    ) {
      overAllDataCopy?.[
        lowerCase(createNewActivityData?.category_name)
      ]?.planDayData.forEach((data, index) => {
        for (const object of calenderData) {
          if (object?.day === data?.day) {
            let obj = {
              activityUnits: `${createNewActivityData?.quantity} ${createNewActivityData?.unit_name}`,
              activity_days: createNewActivityData?.activity_days ?? [],
              activity_dont: createNewActivityData?.activity_dont ?? {},
              activity_image: createNewActivityData?.activity_image ?? '',
              activity_name: createNewActivityData?.activity_name ?? '',
              activity_notes: createNewActivityData?.activity_notes ?? {},
              activity_references:
                createNewActivityData?.activity_references ?? [],
              activity_time: createNewActivityData?.activity_time ?? 0,
              activity_time_name:
                createNewActivityData?.activity_time_name ?? '',
              category_id: createNewActivityData?.category_id ?? 0,
              category_name: capitalize(createNewActivityData?.category_name),
              goal_id: createNewActivityData?.goal_id ?? 0,
              goal_name: createNewActivityData?.goal_name ?? '',
              id: createNewActivityData?.id ?? '',
              is_measurable: createNewActivityData?.is_measurable ?? false,
              plan: planAddEditToolState?.planDurationData.plan_name ?? '',
              quantity: createNewActivityData?.quantity ?? 0,
              specific_time: createNewActivityData?.specific_time ?? {},
              subActivityUnits: `${createNewActivityData?.quantity} ${createNewActivityData?.unit_name}`,
              unit: createNewActivityData?.unit ?? null,
              unit_name: createNewActivityData?.unit_name ?? '',
              isNew: createNewActivityData?.isNew,
            };
            data?.plan.push(obj);
          }
        }
      });
      set({
        loading: false,
        planAddEditToolState: {
          ...planAddEditToolState,
          overAllConstructedPlanData: overAllDataCopy,
        },
      });
    } else {
      for (const value of calender) {
        let obj = {
          day: value?.day,
          plan: [],
        };
        overAllDataCopy?.[
          createNewActivityData?.category_name
        ]?.planDayData.push(obj);
      }

      overAllDataCopy?.[
        lowerCase(createNewActivityData?.category_name)
      ]?.planDayData.forEach((data, index) => {
        for (const object of calenderData) {
          if (object?.day === data?.day) {
            let obj = {
              activityUnits: `${createNewActivityData?.quantity} ${createNewActivityData?.unit_name}`,
              activity_days: createNewActivityData?.activity_days ?? [],
              activity_dont: createNewActivityData?.activity_dont ?? {},
              activity_image: createNewActivityData?.activity_image ?? '',
              activity_name: createNewActivityData?.activity_name ?? '',
              activity_notes: createNewActivityData?.activity_notes ?? {},
              activity_references:
                createNewActivityData?.activity_references ?? [],
              activity_time: createNewActivityData?.activity_time ?? 0,
              activity_time_name:
                createNewActivityData?.activity_time_name ?? '',
              category_id: createNewActivityData?.category_id ?? 0,
              category_name: capitalize(createNewActivityData?.category_name),
              goal_id: createNewActivityData?.goal_id ?? 0,
              goal_name: createNewActivityData?.goal_name ?? '',
              id: createNewActivityData?.id ?? '',
              is_measurable: createNewActivityData?.is_measurable ?? false,
              plan: planAddEditToolState?.planDurationData.plan_name ?? '',
              quantity: createNewActivityData?.quantity ?? 0,
              specific_time: createNewActivityData?.specific_time ?? {},
              subActivityUnits: `${createNewActivityData?.quantity} ${createNewActivityData?.unit_name}`,
              unit: createNewActivityData?.unit ?? null,
              unit_name: createNewActivityData?.unit_name ?? '',
              isNew: createNewActivityData?.isNew,
            };
            data?.plan.push(obj);
          }
        }
      });
      set({
        loading: false,
        planAddEditToolState: {
          ...planAddEditToolState,
          overAllConstructedPlanData: overAllDataCopy,
        },
      });
    }
    constructDayActivityData(createNewActivityData?.category_name, 0);
    setLocalStorage();
  },
  addPlanData: () => {
    const { planAddEditToolState, constructDayActivityData, setLocalStorage } =
      get();
    const { activityState } = useCreateAndAddActivity.getState();
    const { selectedActivityData } = activityState;

    function capitalize(string) {
      return string.charAt(0).toLowerCase() + string.slice(1).toLowerCase();
    }

    const calender = [
      {
        day: 'SUN',
        value: 1,
        taskCount: 0,
      },
      {
        day: 'MON',
        value: 2,
        taskCount: 0,
      },
      {
        day: 'TUE',
        value: 3,
        taskCount: 0,
      },
      {
        day: 'WED',
        value: 4,
        taskCount: 0,
      },
      {
        day: 'THU',
        value: 5,
        taskCount: 0,
      },
      {
        day: 'FRI',
        value: 6,
        taskCount: 0,
      },
      {
        day: 'SAT',
        value: 7,
        taskCount: 0,
      },
    ];

    let overAllDataCopy = {};

    if (
      !planAddEditToolState?.isScratch ||
      Object.keys(planAddEditToolState?.overAllConstructedPlanData).length > 0
    ) {
      if (
        planAddEditToolState?.masterByMe &&
        Object.keys(planAddEditToolState?.overAllConstructedPlanData).length ===
          0
      ) {
        overAllDataCopy = {
          mind: {
            planName: planAddEditToolState?.planDurationData?.plan_name,
            planDayData: [],
          },
          body: {
            planName: planAddEditToolState?.planDurationData?.plan_name,
            planDayData: [],
          },
          food: {
            planName: planAddEditToolState?.planDurationData?.plan_name,
            planDayData: [],
          },
        };
      } else {
        overAllDataCopy = JSON.parse(
          JSON.stringify(planAddEditToolState?.overAllConstructedPlanData)
        );
      }
    } else {
      overAllDataCopy = {
        mind: {
          planName: planAddEditToolState?.planDurationData?.plan_name,
          planDayData: [],
        },
        body: {
          planName: planAddEditToolState?.planDurationData?.plan_name,
          planDayData: [],
        },
        food: {
          planName: planAddEditToolState?.planDurationData?.plan_name,
          planDayData: [],
        },
      };
    }
    if (
      overAllDataCopy?.[capitalize(selectedActivityData[0]?.category_name)]
        ?.planDayData.length > 0
    ) {
      for (const obj of selectedActivityData) {
        const calenderData = [];
        for (const object of obj?.activity_days) {
          let obj = {
            day:
              object === '1'
                ? 'SUN'
                : object === '2'
                ? 'MON'
                : object === '3'
                ? 'TUE'
                : object === '4'
                ? 'WED'
                : object === '5'
                ? 'THU'
                : object === '6'
                ? 'FRI'
                : 'SAT',
            value: object,
          };
          calenderData.push(obj);
        }

        overAllDataCopy?.[capitalize(obj?.category_name)]?.planDayData.forEach(
          (data, index) => {
            for (const object of calenderData) {
              if (object?.day === data?.day) {
                let resObj = {
                  activityUnits: `${obj?.quantity} ${obj?.unit_name}`,
                  activity_days: obj?.activity_days ?? [],
                  activity_dont: obj?.activity_dont ?? {},
                  activity_image: obj?.activity_image ?? '',
                  activity_name: obj?.activity_name ?? '',
                  activity_notes: obj?.activity_notes ?? {},
                  activity_references: obj?.activity_references ?? [],
                  activity_time: obj?.activity_time ?? 0,
                  activity_time_name: obj?.activity_time_name ?? '',
                  category_id: obj?.category_id ?? 0,
                  category_name: obj?.category_name,
                  goal_id: obj?.goal_id ?? 0,
                  goal_name: obj?.goal_name ?? '',
                  id: obj?.id ?? '',
                  is_measurable: obj?.is_measurable ?? false,
                  plan: `${obj?.activity_name} - ${obj?.unit} ${obj?.unit_name}, ${obj?.activity_time_name}`,
                  quantity: obj?.quantity ?? 0,
                  specific_time: obj?.specific_time ?? {},
                  subActivityUnits: `${obj?.quantity} ${obj?.unit_name}`,
                  unit: obj?.unit ?? null,
                  unit_name: obj?.unit_name ?? '',
                  isNew: obj?.isNew,
                };
                data?.plan.push(resObj);
              }
            }
          }
        );
      }
    } else {
      for (const value of calender) {
        let obj = {
          day: value?.day,
          plan: [],
        };
        overAllDataCopy?.[
          capitalize(selectedActivityData[0]?.category_name)
        ]?.planDayData.push(obj);
      }
      for (const obj of selectedActivityData) {
        const calenderData = [];
        for (const object of obj?.activity_days) {
          let obj = {
            day:
              object === '1'
                ? 'SUN'
                : object === '2'
                ? 'MON'
                : object === '3'
                ? 'TUE'
                : object === '4'
                ? 'WED'
                : object === '5'
                ? 'THU'
                : object === '6'
                ? 'FRI'
                : 'SAT',
            value: object,
          };
          calenderData.push(obj);
        }

        overAllDataCopy?.[capitalize(obj?.category_name)]?.planDayData.forEach(
          (data, index) => {
            for (const object of calenderData) {
              if (object?.day === data?.day) {
                let resObj = {
                  activityUnits: `${obj?.quantity} ${obj?.unit_name}`,
                  activity_days: obj?.activity_days ?? [],
                  activity_dont: obj?.activity_dont ?? {},
                  activity_image: obj?.activity_image ?? '',
                  activity_name: obj?.activity_name ?? '',
                  activity_notes: obj?.activity_notes ?? {},
                  activity_references: obj?.activity_references ?? [],
                  activity_time: obj?.activity_time ?? 0,
                  activity_time_name: obj?.activity_time_name ?? '',
                  category_id: obj?.category_id ?? 0,
                  category_name: obj?.category_name,
                  goal_id: obj?.goal_id ?? 0,
                  goal_name: obj?.goal_name ?? '',
                  id: obj?.id ?? '',
                  is_measurable: obj?.is_measurable ?? false,
                  plan: `${obj?.activity_name} - ${obj?.unit} ${obj?.unit_name}, ${obj?.activity_time_name}`,
                  quantity: obj?.quantity ?? 0,
                  specific_time: obj?.specific_time ?? {},
                  subActivityUnits: `${obj?.quantity} ${obj?.unit_name}`,
                  unit: obj?.unit ?? null,
                  unit_name: obj?.unit_name ?? '',
                  isNew: obj?.isNew,
                };
                data?.plan.push(resObj);
              }
            }
          }
        );
      }
    }

    set({
      loading: false,
      planAddEditToolState: {
        ...planAddEditToolState,
        overAllConstructedPlanData: overAllDataCopy,
      },
    });
    constructDayActivityData(
      capitalize(selectedActivityData[0].category_name),
      0
    );
    setLocalStorage();
  },
  editPlanData: () => {
    const {
      planAddEditToolState,
      addPlanData,
      constructDayActivityData,
      setLocalStorage,
    } = get();
    const { activityState } = useCreateAndAddActivity.getState();
    const { createNewActivityData } = activityState;

    function capitalize(string) {
      return string.charAt(0).toLowerCase() + string.slice(1).toLowerCase();
    }

    let overAllDataCopy = JSON.parse(
      JSON.stringify(planAddEditToolState?.overAllConstructedPlanData)
    );

    overAllDataCopy?.[
      capitalize(createNewActivityData?.category_name)
    ]?.planDayData.forEach((data, index, arr) => {
      let resArr = [];
      for (const object of data?.plan) {
        if (object?.id !== createNewActivityData?.id) {
          resArr.push(object);
        }
      }
      arr[index] = {
        ...data,
        plan: resArr,
      };
    });
    set({
      loading: false,
      planAddEditToolState: {
        ...planAddEditToolState,
        overAllConstructedPlanData: overAllDataCopy,
      },
    });
    constructDayActivityData(
      capitalize(createNewActivityData?.category_name),
      0
    );
    setLocalStorage();
  },
}));
