import {
  ExpertMasterIcon,
  ExpertRequestIcon,
  UserNameIcon
} from '@atoms/icons';
import { clinicalRoutes, expertRoutes } from '@hc/routes';
import { usePWANotificationEnabler } from '@hc/store/clinical/pwaNotificationPrompt';
import { localStorageKeys, parseJwt } from '@hc/utils';
import { Box, Grid, IconButton, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import isEqual from 'react-fast-compare';
import { toast } from 'react-hot-toast';
import { useLocation, useNavigate } from 'react-router-dom';
import { Animate, ExpertSplash, NotificationIcon } from '../../../atoms';
import { PWANotificationPrompt } from '../../clinical/pwaNoticationPrompt';
import { expertLayout_style } from './style';

function ExpertLayout(props) {
  const {
    className = '',
    appBarRequired,
    appBarStyle = {},
    childrenStyle = {},
    footBarRequired,
    rootStyle = {},
    footerStyle = {},
    children,
    appBarFirstChild,
    appbarSecChild,
    appBarSecChildRootStyle,
    appBarFirstChildRootStyle,
    ...rest
  } = props;

  const {
    pwaNotificationChecker,
    isPwa,
    deferredPrompt,
    setDeferredPrompt,
    notificationStatus,
    showPWANotificationPrompt,
    setShowPWANotificationPrompt,
    showedFirstTime,
    updatePwaNotificationStatus,
    loading: pwaNotificationCheckerLoading,
  } = usePWANotificationEnabler(
    (state) => ({
      pwaNotificationChecker: state.pwaNotificationChecker,
      updatePwaNotificationStatus: state.updatePwaNotificationStatus,
      isPwa: state.isPwa,
      loading: state.loading,
      deferredPrompt: state.deferredPrompt,
      setDeferredPrompt: state.setDeferredPrompt,
      showedFirstTime: state.showedFirstTime,
      notificationStatus: state.notificationStatus,
      showPWANotificationPrompt: state.showPWANotificationPrompt,
      setShowPWANotificationPrompt: state.setShowPWANotificationPrompt,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const authToken = localStorage.getItem(localStorageKeys.authToken);
  const tokenData = parseJwt(authToken);
  const location = useLocation();
  const navigate = useNavigate();
  const navItems = [
    {
      item: 'Requests',
      path: '/',
      icon: 'requests',
    },
    {
      item: 'Masters',
      path: '/master',
      icon: 'masters',
    },
    {
      item: 'Profile',
      path: '/profile',
      icon: 'userName',
    },
  ];

  const navIcons = {
    requests: ExpertRequestIcon,
    masters: ExpertMasterIcon,
    userName: UserNameIcon,
  };

  function handleInstallClick() {
    // Show the system install prompt

    if (!deferredPrompt)
      toast(
        'Click the Add to Home screen in the browser settings to get an app like experience'
      );
    deferredPrompt && deferredPrompt.prompt();

    // Wait for the user to respond to the prompt
    deferredPrompt?.userChoice &&
      deferredPrompt?.userChoice.then((choiceResult) => {
        if (choiceResult.outcome === 'accepted') {
          toast.success('App will be added to your home screen soon...');
        } else {
          toast('Get an app like experience by installing it!');
        }

        // Reset the deferredPrompt variable
        setDeferredPrompt(null);
      });
  }

  function enableNotification() {
    import('@hc/store').then((modules) => {
      const { enableNotification } = modules.useFirebase.getState();
      enableNotification(tokenData?.user_id).then((state) => {
        updatePwaNotificationStatus(isPwa, state);
      });
    });
  }

  // GET APPOINTMENT DATA
  useEffect(() => {
    pwaNotificationChecker();

    if (!showedFirstTime && !pwaNotificationCheckerLoading) {
      setTimeout(() => {
        if (
          !isPwa ||
          notificationStatus === 'denied' ||
          notificationStatus === 'default'
        ) {
          setShowPWANotificationPrompt(true);
        }
      }, [3000]);
    }
  }, [pwaNotificationCheckerLoading]);

  return (
    <Box
      sx={{
        pt: appBarRequired ? 9.2 : 2.5,
        pb: footBarRequired ? 9.2 : 2.5,
        px: 2.5,
        // height: '100vh',
        ...expertLayout_style.rootSx,
        ...rootStyle,
      }}
      className={`${className}`}
      {...rest}
    >
      {/* App bar */}
      {location.pathname === clinicalRoutes?.home && (
        <Animate
          entryAnimation="driveInTop"
          exitAnimation="driveOutBottom"
          duration="0.5s"
          show={showPWANotificationPrompt}
        >
          <Box sx={expertLayout_style.promptSx}>
            <PWANotificationPrompt
              onCloseClick={() => setShowPWANotificationPrompt(false)}
              appInstalled={isPwa}
              onEnable={enableNotification}
              notificationEnabled={notificationStatus === 'granted'}
              isblocked={notificationStatus === 'denied'}
              onInstall={handleInstallClick}
            />
          </Box>
        </Animate>
      )}

      {appBarRequired && (
        <Box>
          <Box sx={{ ...expertLayout_style.expertAppbarSx, ...appBarStyle }}>
            <Box
              sx={{
                ...expertLayout_style.totalScreenSx,
              }}
            >
              <Grid container display="flex" justifyContent="space-between">
                <Grid item>
                  <ExpertSplash
                    rootStyle={{ height: '38px', width: '150px' }}
                  />
                </Grid>
                <Grid item>
                  <IconButton
                    onClick={() => {
                      navigate(expertRoutes.notification);
                    }}
                  >
                    <NotificationIcon
                      rootStyle={{
                        color: '#fff',
                        width: '24.1px',
                        height: '25.69px',
                      }}
                    />
                  </IconButton>
                </Grid>
              </Grid>
            </Box>
          </Box>
          {location?.pathname === '/' && appBarFirstChild && (
            <Box
              sx={{
                ...expertLayout_style.app1stRootSx,
                ...appBarFirstChildRootStyle,
              }}
            >
              {appBarFirstChild && <Box>{appBarFirstChild}</Box>}
            </Box>
          )}
        </Box>
      )}

      {/* Children */}
      <Box
        sx={{
          backgroundColor: '#F7F7F7',
          ...expertLayout_style.childrenSx,
          ...childrenStyle,
        }}
      >
        <Box>{children}</Box>
      </Box>
      {footBarRequired && (
        <Box sx={{ ...expertLayout_style.footerNavBarSx, ...footerStyle }}>
          {navItems.map((navItem,i) => {
            const Icon = navIcons[navItem.icon];
            return (
              <Box
                key={i}
                sx={expertLayout_style.navItemSx}
                onClick={() => {
                  navigate(navItem.path);
                }}
                id={navItem.item}
              >
                <Icon
                  rootStyle={{
                    color:
                      navItem?.path === location?.pathname
                        ? 'primary.main'
                        : 'text.secondary',
                    ...(navItem.icon === 'userName' && {
                      bgcolor:
                        navItem?.path === location?.pathname ? '' : 'grey.100',
                      border: '1.5px solid',
                      borderColor:
                        navItem?.path === location?.pathname
                          ? 'primary.main'
                          : 'grey.600',
                    }),
                  }}
                  letter={tokenData?.name?.slice(0, 1).toUpperCase()}
                />
                <Typography
                  variant="body1"
                  color="initial"
                  sx={{
                    ...expertLayout_style.navItemTextSx,
                    color:
                      navItem?.path === location?.pathname
                        ? 'primary.main'
                        : 'text.secondary',
                  }}
                >
                  {navItem.item}
                </Typography>
              </Box>
            );
          })}
        </Box>
      )}
    </Box>
  );
}

ExpertLayout.propTypes = {
  className: PropTypes.string,
  rootStyle: PropTypes.object,
  appBarRequired: PropTypes.bool,
  appBarStyle: PropTypes.object,
  childrenStyle: PropTypes.object,
  footBarRequired: PropTypes.bool,
  footerStyle: PropTypes.object,
  children: PropTypes.any,
  appBarFirstChild: PropTypes.element,
  appbarSecChild: PropTypes.element,
  appBarSecChildRootStyle: PropTypes.object,
  appBarFirstChildRootStyle: PropTypes.object,
};

export { ExpertLayout };

