/* eslint-disable no-lonely-if */
import { expertRoutes } from '@hc/routes';
import { useExpertRequest, usePlanAddEditTool } from '@hc/store';
import { useExpertDraft } from '@hc/store/expert/draft';
import { useExpertHistory } from '@hc/store/expert/history';
import { Button, DataTabs, Drawer, ToggleButton } from '@hc/ui/atoms';
import {
  ExpertLayout,
  ExpertRequestCardList,
  ExpertVerification,
} from '@hc/ui/components';
import { localStorageKeys, parseJwt, queryClient } from '@hc/utils';
import { Box, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import isEqual from 'react-fast-compare';
import { useNavigate } from 'react-router-dom';
import { version } from '../../../package.json';
import { home_style } from './style';

export default function Home(props) {
  const { className = '', ...rest } = props;

  const authToken = localStorage.getItem(localStorageKeys.authToken);
  const tokenData = parseJwt(authToken);

  const navigate = useNavigate();

  const [openDrawer, setOpenDrawer] = useState(false);
  const [searchValue, setSetSearchValue] = useState('');
  const [searchDraftValue, SetDraftSearchValue] = useState('');
  const [searchHistoryValue, setHistorySearchValue] = useState('');
  const [requestOffset, setRequestOffset] = useState(1);
  const [draftOffset, setDraftOffset] = useState(1);
  const [historyOffset, setHistoryOffset] = useState(1);

  const observerTarget = useRef(null);

  // -----------------------REQUEST STORE--------------------------------
  const {
    getRequest,
    requestState,
    clearFilterState,
    getRequestById,
    updateFilters,
    updateUserStatus,
    updateRequestState,
    requestLoading,
  } = useExpertRequest(
    (state) => ({
      updateRequestState: state.updateRequestState,
      updateUserStatus: state.updateUserStatus,
      getRequestById: state.getRequestById,
      clearFilterState: state.clearFilterState,
      getRequest: state.getRequest,
      requestState: state.requestState,
      updateFilters: state.updateFilters,
      requestLoading: state.loading,
    }),
    (prev, curr) => isEqual(prev, curr),
  );
  // --------------------------------------------DRAFT STORE--------------------------------
  const { getDraft, draftState, updateDraftFilters, draftLoading } =
    useExpertDraft(
      (state) => ({
        getDraft: state.getDraft,
        draftState: state.draftState,
        updateDraftFilters: state.updateDraftFilters,
        clearDraftFilterState: state.clearDraftFilterState,
        draftLoading: state.loading,
      }),
      (prev, curr) => isEqual(prev, curr),
    );
  // -------------------------------------------HISTORY STORE-----------------------------------
  const {
    getHistory,
    historyState,
    getHistoryRenewalPlanById,
    historyLoading,
  } = useExpertHistory(
    (state) => ({
      getHistory: state.getHistory,
      getHistoryRenewalPlanById: state.getHistoryRenewalPlanById,
      historyState: state.historyState,
      updateHistoryFilters: state.updateHistoryFilters,
      historyLoading: state.loading,
    }),
    (prev, curr) => isEqual(prev, curr),
  );

  const { requestData, filters, tabIndex, requestCount } = requestState;
  const { draftData, draftCount } = draftState;
  const { historyData, historyCount } = historyState;

  // --------------------------------------PlanEditTool-----------------------------

  const { planAddEditToolState, getPlanDetailsById, onUpdateToolData } =
    usePlanAddEditTool(
      (state) => ({
        planAddEditToolState: state.planAddEditToolState,
        onUpdateToolData: state.onUpdateToolData,
        getPlanDetailsById: state.getPlanDetailsById,
      }),
      (prev, curr) => isEqual(prev, curr),
    );

  const filter = [
    {
      name: 'Gender',
      keyName: 'gender_filter',
      value: filters?.gender_filter ?? '',
      data: [
        {
          name: 'All',
          value: '',
        },
        {
          name: 'Male',
          value: 'male',
        },
        {
          name: 'Female',
          value: 'female',
        },
        {
          name: 'Others',
          value: 'others',
        },
      ],
    },
    {
      name: 'Request date',
      keyName: 'date_filter',
      value: filters?.date ?? '',
      data: [
        {
          name: 'All',
          value: '',
        },
        {
          name: 'Today',
          value: 'today',
        },
        {
          name: 'Yesterday',
          value: 'yesterday',
        },
      ],
    },
    {
      name: 'Request Type',
      keyName: 'type_filter',
      value: filters?.type_filter,
      data: [
        {
          name: 'All',
          value: '',
        },
        {
          name: 'New',
          value: 'New',
        },
        {
          name: 'Renewal',
          value: 'Renewal',
        },
      ],
    },
  ];

  const breaks = [
    {
      value: '1',
      label: 'New requests',
      count: ` (${requestCount ?? 0})`,
    },
    {
      value: '2',
      label: 'Under draft',
      count: ` (${draftCount ?? 0})`,
    },
    {
      value: '3',
      label: 'History',
      count: ` (${historyCount ?? 0})`,
    },
  ];

  const handleOpen = () => {
    setOpenDrawer(true);
  };

  const onCancel = () => {
    clearFilterState();
  };

  const setTabIndex = (value) => {
    updateRequestState('tabIndex', value);
  };

  const onApply = async () => {
    if (tabIndex === '1') {
      queryClient.invalidateQueries({
        queryKey: ['expert-request'],
      });
      await getRequest();
      setOpenDrawer(false);
    } else if (tabIndex === '2') {
      queryClient.invalidateQueries({
        queryKey: ['expert-draft'],
      });
      await getDraft('', 1, false);
      setOpenDrawer(false);
    }
  };

  // ------------------------------REQUEST -------------------
  const onChange = async (value) => {
    setSetSearchValue(value);
    updateFilters('search', value);

    if (filters?.search?.length > 0) {
      queryClient.invalidateQueries({
        queryKey: ['expert-request'],
      });
      await getRequest();
    }
  };

  // ---------------------------------DRAFT -------------------------------
  const onDraftChange = async (value) => {
    SetDraftSearchValue(value);
    queryClient.invalidateQueries({
      queryKey: ['expert-draft'],
    });
    await getDraft(value, 1, true);
  };

  // -------------------------------------HISTORY----------------------------
  const onHistoryChange = async (value) => {
    setHistorySearchValue(value);

    queryClient.invalidateQueries({
      queryKey: ['expert-history'],
    });
    await getHistory(value, 1, true);
  };

  const onRequestCardClick = async (profileId, val) => {
    onUpdateToolData('isHistory', false);
    onUpdateToolData('isDraft', false);
    onUpdateToolData('isScratch', true);
    onUpdateToolData('masterByMe', false);
    onUpdateToolData('masterByDefault', false);
    localStorage.removeItem(localStorageKeys?.requestState);
    localStorage.removeItem(localStorageKeys?.basicInfoState);
    const response = await getRequestById(profileId, val);
    if (response?.code === 200) {
      queryClient.invalidateQueries({
        queryKey: ['expert-update-status'],
      });
      const result = await updateUserStatus(profileId, 2);
      if (result?.code === 200) {
        navigate(expertRoutes.basicInfoSteper);
      }
    }
  };

  const FilterListChange = (key, value) => {
    if (key === 'date_filter') {
      if (tabIndex === '1') {
        updateFilters('date', value);
      } else if (tabIndex === '2') {
        updateDraftFilters('date', value);
      }
      let date;
      if (value === 'today') {
        date = {
          start_date: new Date().toISOString(),
          end_date: new Date().toISOString(),
        };
      } else if (value === 'yesterday') {
        const today = new Date();
        const yesterday = today.getTime() - 86400000;
        const yesterdayISO = new Date(yesterday).toISOString();
        date = {
          start_date: yesterdayISO,
          end_date: yesterdayISO,
        };
      } else if (value === '') {
        date = {
          start_date: '',
          end_date: '',
        };
      }
      if (tabIndex === '1') {
        updateFilters(key, date);
      } else if (tabIndex === '2') {
        updateDraftFilters(key, date);
      }
    } else {
      if (tabIndex === '1') {
        updateFilters(key, value);
      } else if (tabIndex === '2') {
        updateDraftFilters(key, value);
      }
    }
  };

  const onDraftCardClick = async (draftId, val) => {
    onUpdateToolData('userData', val);
    onUpdateToolData('isDraft', true);
    onUpdateToolData('isHistory', false);
    onUpdateToolData('isScratch', false);
    onUpdateToolData('masterByMe', false);
    onUpdateToolData('masterByDefault', false);
    queryClient.invalidateQueries({
      queryKey: ['get-plan-data-by-plan-id'],
    });

    const result = await getPlanDetailsById(draftId ?? '', 'previousData');
    if (result?.status?.code === 200) {
      if (
        result?.data?.planData?.plan_name?.length > 0 &&
        result?.data?.planData?.duration > 0 &&
        result?.data?.planData?.duration_type?.length > 0
      ) {
        navigate(expertRoutes?.planAddEditTool);
      } else {
        onRequestCardClick(draftId, val);
      }
    }
  };

  const onHistoryCardClick = async (historyId, val) => {
    onUpdateToolData('userData', val);
    onUpdateToolData('isHistory', true);
    onUpdateToolData('isDraft', false);
    onUpdateToolData('isScratch', false);
    onUpdateToolData('masterByMe', false);
    onUpdateToolData('masterByDefault', false);
    queryClient.invalidateQueries({
      queryKey: ['get-plan-data-by-plan-id'],
    });
    queryClient.invalidateQueries({
      queryKey: ['get-history-plan-details-by-id'],
    });
    await getHistoryRenewalPlanById(historyId);
    const result = await getPlanDetailsById(historyId ?? '', 'previousData');
    if (result?.status?.code === 200) {
      navigate(expertRoutes.editplan, {
        state: {
          isPlanEdit: true,
          isPublishPlan: false,
        },
      });
    }
  };

  const getInitialData = async (offset) => {
    queryClient.invalidateQueries({
      queryKey: ['expert-request'],
    });
    await getRequest(offset);
  };

  const getDraftInitialData = async (offset, value) => {
    queryClient.invalidateQueries({
      queryKey: ['expert-draft'],
    });

    await getDraft(searchDraftValue, offset, value);
  };

  const getHistoryInitialData = async (offset, value) => {
    queryClient.invalidateQueries({
      queryKey: ['expert-history'],
    });

    await getHistory(searchHistoryValue, offset, value);
  };

  useEffect(() => {
    import('@hc/store').then((modules) => {
      const { initialize, enableNotification } = modules.useFirebase.getState();
      initialize();
      enableNotification(tokenData?.user_id);
    });
  }, []);

  const getWhichExpert = () => {
    const obj = {
      is_mind_expert: tokenData?.is_mind_expert,
      is_body_expert: tokenData?.is_body_expert,
      is_food_expert: tokenData?.is_food_expert,
    };

    onUpdateToolData('expert', obj);
  };

  useEffect(() => {
    getInitialData(requestOffset);
    getDraftInitialData(draftOffset, true);
    getHistoryInitialData(historyOffset, true);
    localStorage.setItem(localStorageKeys.expertVersion, version);
    localStorage.removeItem(localStorageKeys?.basicInfoState);
    localStorage.removeItem(localStorageKeys?.requestState);
    localStorage.removeItem(localStorageKeys?.planAddEditToolState);
    localStorage.removeItem(localStorageKeys?.activityState);
    localStorage.removeItem(localStorageKeys?.draftState);
    if (!planAddEditToolState?.expert) {
      getWhichExpert();
    }
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          if (requestState.tabIndex === '1') {
            if (requestState?.requestData.length < requestCount) {
              setRequestOffset(requestOffset + 1);
              getInitialData(requestOffset + 1);
            }
          } else if (requestState?.tabIndex === '2') {
            if (draftState?.draftData?.length < draftCount) {
              setDraftOffset(draftOffset + 1);
              getDraftInitialData(draftOffset + 1, false);
            }
          } else if (requestState?.tabIndex === '3') {
            if (historyState?.historyData?.length < historyCount) {
              setHistoryOffset(historyOffset + 1);
              getHistoryInitialData(historyOffset + 1, false);
            }
          }
        }
      },
      { threshold: 1 },
    );
    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }
    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [
    observerTarget,
    tabIndex,
    requestState?.requestData,
    draftState?.draftData,
    historyState?.historyData,
  ]);

  return (
    <ExpertLayout
      appBarFirstChild={
        <Box>
          {tokenData?.is_verified && (
            <Box sx={{ overflowY: 'scroll', mt: 1 }}>
              <DataTabs
                isResponsive
                tabs={breaks}
                tabIndex={tabIndex}
                setTabIndex={setTabIndex}
                totalStyle={home_style.tabStyleSx}
                underTabSx={home_style.underTabStyleSx}
                tabStyle={home_style.tabSx}
                containerStyle={{
                  width: 425,
                  overflowY: 'scroll',
                }}
              />
            </Box>
          )}
        </Box>
      }
      appBarRequired
      footBarRequired
    >
      <Box className={`${className}`} {...rest}>
        <Box
          height={!tokenData?.is_verified ? '60vh' : ''}
          display="flex"
          bgcolor="#F7F7F7"
          justifyContent="center"
        >
          {!tokenData?.is_verified && (
            <Box alignSelf="center">
              <ExpertVerification />
            </Box>
          )}
        </Box>

        {tokenData?.is_verified && (
          <Box sx={home_style.cardListSx}>
            {tabIndex === '1' ? (
              <ExpertRequestCardList
                id="requestSearch"
                data={requestData}
                onOpenDrawer={handleOpen}
                onSearch={onChange}
                loading={requestLoading}
                showFilterDrop={
                  filters?.gender_filter?.length > 0 ||
                  filters?.type_filter?.length > 0 ||
                  filters?.date_filter?.start_date?.length > 0
                }
                onsearchValue={searchValue}
                onClick={onRequestCardClick}
              />
            ) : tabIndex === '2' ? (
              <ExpertRequestCardList
                id="defaultSearch"
                isDraftCard
                data={draftData}
                onOpenDrawer={handleOpen}
                onClick={onDraftCardClick}
                showFilterDrop={
                  filters?.gender_filter?.length > 0 ||
                  filters?.type_filter?.length > 0 ||
                  filters?.date_filter?.start_date?.length > 0
                }
                onSearch={onDraftChange}
                onsearchValue={searchDraftValue}
                loading={draftLoading}
              />
            ) : (
              <ExpertRequestCardList
                id="historySearch"
                isHistoryCard
                data={historyData}
                onClick={onHistoryCardClick}
                onSearch={onHistoryChange}
                onsearchValue={searchHistoryValue}
                loading={historyLoading}
                showFilterDrop={
                  filters?.gender_filter?.length > 0 ||
                  filters?.type_filter?.length > 0 ||
                  filters?.date_filter?.start_date?.length > 0
                }
              />
            )}
          </Box>
        )}
        <div ref={observerTarget} style={{ marginTop: '-20px', opacity: 0 }}>
          intersection
        </div>
      </Box>

      {/* Drawer */}
      <Drawer
        show={openDrawer}
        header="Filters"
        drawerRightClose
        onCloseDrawer={() => setOpenDrawer(false)}
        headerStyle={{
          fontSize: '16px',
          fontWeight: 600,
          color: '#101010',
          textTransform: 'capitalize',
        }}
        rootStyle={home_style.drawerSx}
        closeStyle={{ color: 'grey', height: '14px', width: '12px' }}
        footer={
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button onClick={onCancel} buttonStyle={home_style.cancelBtnSx}>
              Reset
            </Button>
            <Button
              onClick={onApply}
              loading={requestLoading}
              buttonStyle={home_style.applyBtnSx}
            >
              Apply
            </Button>
          </Box>
        }
      >
        {filter.map((val, i) => (
          <Box mt={1} key={i} mb={1}>
            <Typography fontSize={12} color="#5C6266" fontWeight={500}>
              {val?.name}
            </Typography>
            <Box sx={home_style.suggestionSx} display="flex">
              <Grid container display="flex" columnSpacing={3}>
                <Grid item>
                  <ToggleButton
                    options={val?.data}
                    value={val?.value}
                    RootStyle={{ overflow: 'scroll' }}
                    buttonactiveStyle={home_style.buttonActiveNumSx}
                    buttoninactiveStyle={home_style.buttonInactiveNumSx}
                    onChange={(e) => {
                      FilterListChange(val?.keyName, e);
                    }}
                  />
                </Grid>
              </Grid>
            </Box>
          </Box>
        ))}
      </Drawer>
    </ExpertLayout>
  );
}

Home.propTypes = {
  className: PropTypes.string,
};
