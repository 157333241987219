export const profileAdditionalInfo_style = {
  rootSx: {
    py: 2,
  },

  customAboutSx: {
    '& .MuiOutlinedInput-input': {
      height: '76px!important',
      fontSize: '16px',
      fontWeight: 500,
      p: 0,
    },
  },
  labelStyle: {
    color: '#262C33',
    lineHeight: '24px',
    fontWeight: 400,
    fontSize: '12px',
  },
  inputGroupSx: { display: 'grid', gap: 0.5, mb: 0.5, mt: 0.5 },
  dividerSx: {
    display: 'none',
  },
  qnsAnsSelectionHeadSx: {
    display: 'none',
  },
  listingSx: {
    pb: 1,
  },
  listSx: {
    pt: 1,
  },
  qnsAnsTotalSx: {
    pt: 0.5,
  },
  autocompeleteSx: {
    '& .MuiOutlinedInput-root': {
      fontSize: '14px',
      fontWeight: 400,
      lineHeight: '21px',
      paddingTop: '0px !important',
      paddingBottom: '0px !important',
      paddingLeft: '14px !important',
      paddingRight: '14px !important',
      bgcolor: '#fff',
      height: '40px!important',
      '& fieldset': {
        borderRadius: '8px',
        border: '1.5px solid',
        borderColor: 'border.main',
      },
      '&:hover fieldset': {
        borderColor: 'border.hover',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'border.focus',
      },
    },
  },

  listingCardSx: {
    backgroundColor: '#fff',
    border: '1.5px solid #E3E3E3',
    borderColor: '#E3E3E3',
  },
  disabledtextSx: {
    fontSize: '14px',
    fontWeight: 500,
    lineHeight: '21px',
    textAlign: 'left',
    color: '#888888',
    width: '100%',
    background: '#E3E3E3 0% 0% no-repeat padding-box',
    border: '1px solid #D5D5D5',
    borderRadius: '8px',
    p: 1,
  },
};
