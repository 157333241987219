/* eslint-disable prefer-const */
/* eslint-disable no-restricted-syntax */
import { expertRoutes } from '@hc/routes';
import { useCreateAndAddActivity, usePlanAddEditTool } from '@hc/store';
import { Button } from '@hc/ui/atoms';
import { ExpertPlanCard, Screenlayout } from '@hc/ui/components';
import { queryClient } from '@hc/utils';
import { Box, Grid } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import isEqual from 'react-fast-compare';
import { useLocation, useNavigate } from 'react-router-dom';
import { EditActivityDetails_Style } from './style';

export default function EditActivityDetails(props) {
  const { className = '', ...rest } = props;
  const navigate = useNavigate();
  const location = useLocation();

  const {
    activityState,
    clearActivitystate,
    clearCreateNewActivityData,
    onStateUpdate,
    onCardSelect,
  } = useCreateAndAddActivity(
    (state) => ({
      clearCreateNewActivityData: state.clearCreateNewActivityData,
      clearActivitystate: state.clearActivitystate,
      onStateUpdate: state.onStateUpdate,
      onCardSelect: state.onCardSelect,
      activityState: state.activityState,
    }),
    (prev, curr) => {
      isEqual(prev, curr);
      return false;
    }
  );

  const { addPlanData } = usePlanAddEditTool(
    (state) => ({
      addPlanData: state.addPlanData,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const { selectedActivityData } = activityState;

  const onEdit = (activityId) => {
    let obj = {};
    for (const data of selectedActivityData) {
      if (activityId === data?.id) {
        obj = {
          id: data?.id,
          activity_name: data?.activity_name,
          activity_image: data?.activity_image,
          category_id: data?.category_id,
          goal_id: data?.goal_id,
          goal_name: data?.goal_name,
          is_measurable: data?.is_measurable,
          quantity: data?.quantity,
          unit: data?.unit,
          activity_days: data?.activity_days,
          activity_time: data?.activity_time,
          specific_time: data?.specific_time,
          activity_notes: data?.activity_notes,
          activity_references: data?.activity_references,
          activity_dont: data?.activity_dont,
          is_active: data?.is_active,
          activity_time_name: data?.activity_time_name,
          category_name: data?.category_name,
          unit_name: data?.unit_name,
          isNew: data?.isNew,
        };
        onStateUpdate('createNewActivityData', obj);
      }
    }
    navigate(expertRoutes?.addActivity, {
      state: {
        category_id: location?.state?.category_id,
        isEdit: true,
        isCustomEdit: true,
      },
    });
  };

  const onDelete = (activityId) => {
    onCardSelect(activityId);
  };

  const onAddPlan = async () => {
    await addPlanData();
    queryClient.invalidateQueries({
      queryKey: ['get-activity-by-category-idt'],
    });
    clearActivitystate();
    navigate(expertRoutes?.planAddEditTool);
  };

  const onAddMoreActivity = () => {
    navigate(expertRoutes?.selectActivity, {
      state: { category_id: location?.state?.category_id },
    });
  };

  useEffect(() => {
    clearCreateNewActivityData();
  }, []);

  return (
    <Screenlayout
      isExpertAppBar
      appBarStyle={EditActivityDetails_Style.appBarStyleSx}
      backRequired
      backRoute={expertRoutes?.selectActivity}
      stateValue={{category_id: location?.state?.category_id }}
      backIconStyle={{ color: '#6552CC' }}
      rootStyle={{ backgroundColor: '#FCFBFF' }}
      title="Edit activity details"
      masterchildStyle={EditActivityDetails_Style.screenLayoutChildernSx}
      notshowFooter={false}
      footerStyle={{ borderTop: '0px', p: 1 }}
      footer={
        <Grid
          container
          columnSpacing={3}
          sx={{
            height: '50px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            px: 2,
          }}
        >
          <Grid item xs={6}>
            <Button
              onClick={onAddMoreActivity}
              buttonStyle={EditActivityDetails_Style.backbuttonSx}
            >
              Add more activities
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              onClick={onAddPlan}
              buttonStyle={EditActivityDetails_Style.buttonSx}
            >
              Add to plan
            </Button>
          </Grid>
        </Grid>
      }
    >
      <Box
        sx={EditActivityDetails_Style.rootSx}
        className={`${className}`}
        {...rest}
      >
        <Box mt={7} py={1} px={2}>
          {selectedActivityData?.length > 0 &&
            selectedActivityData?.map((val, i) => (
              <Box key={i}>
                <ExpertPlanCard
                  data={val}
                  onEdit={onEdit}
                  onDelete={onDelete}
                />
              </Box>
            ))}
        </Box>
      </Box>
    </Screenlayout>
  );
}

EditActivityDetails.propTypes = {
  className: PropTypes.string,
};
