export const Addactivity_style = {
  rootSx: { mt: 7, backgroundColor: '#F7F7F7 ' },
  appBarStyleSx: {
    backgroundColor: 'purple.A100',
  },
  screenLayoutChildernSx: {
    pt: 3,
    pb: 8.5,
  },

  buttonSx: {
    background: ' #6552CC',
    borderRadius: '8px',
    width: '160px',
    textTransform: 'capitalize',
    color: ' #FFFFFF',
    '&:hover': {
      background: ' #6552CC',
    },
  },
  backbuttonSx: {
    width: '160px',
    background: '#F3F0FF',
    textTransform: 'capitalize',
    color: ' #6552CC',
    '&:hover': {
      background: '#F3F0FF',
    },
  },
  expertAppbarSx: {
    pl: 2.5,
    pr: 2.5,
    py: 2,
    boxSizing: 'border-box',
    bgcolor: '#fff',
    borderRadius: '0px 0px 24px 24px',
    boxShadow: '0px 0px 10px #00000029',
    position: 'fixed',
    top: '5%',
    zIndex: '1000',
    left: 0,
    right: 0,
    width: '100%',
    maxWidth: {
      sm: 425,
    },
    mx: {
      sm: 'auto',
    },
  },

  headerSx: {
    fontSize: '16px',
    fontWeight: 600,
    color: '#101010',
    textTransform: 'capitalize',
    paddingTop: '70px',
  },
  inputFieldSx: {
    marginTop: '4px',
    borderRadius: '8px',
    backgroundColor: '#F7F7F7 ',

    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '8px',
        border: '0px solid',
        borderColor: 'transparent',
      },
      '&:hover fieldset': {
        borderColor: 'transparent',
      },
      '&.Mui-disabled fieldset': {
        borderColor: 'transparent',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'transparent',
      },
    },
  },
  boxRootSx: {
    gap: 2,
    px: 3,
    pb: 1,
    pt: '14px',
  },
  inputGroupSx: { display: 'grid', gap: 0.5 },

  rootchildrenSx: {
    textAlign: 'center',
    border: '1px solid',
    borderColor: 'grey.400',
    borderRadius: '8px',
    mr: 1,
  },
  genderSx: {
    display: 'flex',
    justifyContent: 'start',
  },
  selectAvailabilityButtonSx: {
    backgroundColor: 'primary.main',
    color: '#fff',
  },
  unSelectAvailabilityButtonSx: {
    fontSize: '14px',
    fontWeight: 'medium',
    color: 'primaryTints.A100',
  },

  addMemberSx: {
    textTransform: 'capitalize',
    height: '40px',
    width: '124px',
    borderRadius: '4px',
  },
  labelSx: {
    color: 'text.label',
    pb: 1,
  },
  activeUploadFileSx: {
    border: '2px dashed',
    borderColor: '#DBDBDB',
    // opacity: '48%',
    height: '132px',
    // marginTop: 2,

    borderRadius: '8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
  },
  browseTextSx: {
    fontSize: '14px',
    color: '#5B48BD',
    opacity: '48%',
    textAlign: 'center',
    marginTop: '12px',
    cursor: 'pointer',
  },
  uploadIconSx: {
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  measurableSx: {
    background: ' #FFFFFF',
    // border: ' 1px solid #DBEAE8',
    borderRadius: '8px',
    p: 2,
  },
  switchSx: {
    '& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track': {
      backgroundColor: '#EE8438',
      opacity: 1,
    },
  },
  measurableContentSx: {
    display: 'flex',
  },
  buttonactivenumSX: {
    border: '1px solid',
    minWidth: '36px',
    minHeight: '40px',
    borderRadius: '10px',
    padding: '5px 10px',
    backgroundColor: '#EE8438',
    color: '#fff',
    '&:hover': {
      backgroundColor: '#EE8438',
      color: '#fff',
    },
  },
  buttoninactivenumSX: {
    border: '1px solid ',
    borderColor: '#E3E3E3',
    borderRadius: '10px',
    minWidth: '36px',
    minHeight: '40px',
    padding: '5px 10px',
    '&:hover': {
      backgroundColor: '#fff',
    },
  },

  togglebuttoninactiveSX: {
    color: '#5C6266',
    border: '1px solid ',
    borderColor: 'transparent',
    minWidth: '36px',
    minHeight: '40px',
    borderRadius: '8px',
    padding: '5px 10px',
    backgroundColor: '#E7FCF9',
    '&:hover': {
      color: '#5C6266',
      backgroundColor: '#E7FCF9',
    },
  },
  togglebuttonactiveSX: {
    color: 'primary.main',
    backgroundColor: '#E7FCF9',
    borderColor: 'primary.main',
    border: '1px solid ',
    borderRadius: '10px',
    minWidth: '36px',
    minHeight: '40px',
    padding: '5px 10px',
    '&:hover': {
      color: 'primary.main',
      backgroundColor: '#E7FCF9',
    },
  },

  containerBoxSx: {
    background: ' #FFFFFF',
    // border: ' 1px solid #DBEAE8',
    borderRadius: '8px',
    p: 1.5,
  },
  DaycontainerBoxSx: {
    background: ' #FFFFFF',
    // border: ' 1px solid #DBEAE8',
    borderRadius: '8px',
    pl: 1.4,
    py: 1.4,
  },
  containerDivSx: {
    background: ' #FFFFFF',

    borderRadius: '8px',
    p: 1.5,
  },
  notesContainerBoxSx: {
    background: ' #FFFFFF',
    // border: ' 1px solid #DBEAE8',
    borderRadius: '8px',
    p: 2,
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    height: '13vh',
  },

  onTimeSx: {
    textAlign: 'left',
    font: '12px/18px Poppins',
    fontWeight: '500',
    letterSpacing: '0px',
    color: '#000',
    py: 1,
  },

  TimeSelectText: {
    textAlign: 'left',
    font: '12px/18px Poppins',
    fontWeight: '500',
    letterSpacing: '0px',
    color: '#888888',
    pt: 0.5,
  },
  SaveButtonSx: {
    bgcolor: 'primary.main',
    textTransform: 'capitalize',
    color: '#fff',
    borderRadius: '8px',
    '&:hover': {
      bgcolor: 'primary.main',
    },
  },
  cancleButtonSx: {
    border: '1px solid',
    borderColor: 'primary.main',
    color: 'primary.main',
    textTransform: 'capitalize',
    borderRadius: '8px',
    bgcolor: '#fff',
    '&:hover': {
      bgcolor: 'primaryTints.200',
    },
  },
  textFieldStyle: {
    opacity: 1,

    '& .MuiOutlinedInput-input': {
      width: '100%',
      fontWeight: 600,
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: '8px',
        border: 'none',
      },
    },
    '& .MuiFormHelperText-root': {
      mx: 0,
    },
  },
  dropdownSX: {
    '.MuiPaper-root': {
      left: 4,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none',
    },
  },
  activityunitsSX: {
    display: 'flex',
    backgroundColor: '#F7F7F7 ',

    borderRadius: '8px',
  },
  NotesFontSx: {
    fontSize: '12px',
    fontWeight: 600,
    textAlign: 'center',
    color: '#0E1824',
  },
  timeInput: {
    textAlign: 'left',
    font: '12px/18px Poppins',
    fontWeight: '500',
    letterSpacing: '0px',
    color: '#888888',
  },
};
