export const profilePersonalInfo_style = {
  rootSx: {
    py: 2,
  },
  customtextSx: {
    bgcolor: '#fff',
    borderRadius: '8px',
    '& .MuiOutlinedInput-input': {
      height: '40px!important',
      fontSize: '16px',
      fontWeight: 500,
      color: '#0E1824',
      bgcolor: '#fff',
      lineHeight: '25px',
      p: 0,
    },
  },

  labelStyle: {
    color: '#262C33',
    lineHeight: '24px',
    fontWeight: 400,
    fontSize: '12px',
  },
  inputGroupSx: { display: 'grid', gap: 0.5, mb: 0.5 },
};
