/* eslint-disable no-restricted-syntax */
/* eslint-disable prefer-const */
import { expertRoutes } from '@hc/routes';
import {
  useBasicInfoStates,
  useExpertRequest,
  usePlanAddEditTool,
} from '@hc/store';
import {
  Button,
  ChoosePlanHistroyImage,
  ChoosePlanNewImage,
  ChoosePlanTemplateImage,
  CommonSteper,
  Drawer,
  Input,
  Label,
  MembersRightIcon,
  ToggleButton,
} from '@hc/ui/atoms';
import {
  ExpertChoosePlanCard,
  ExpertPlanTemplateCard,
  ExpertPreviousPlanCard,
  ExpertPreviousPlanSkeletonCard,
  ExpertTemplateCardSkeleton,
  HealthConditionChips,
  PreviousPlansCard,
  QuestionAnsList,
  Screenlayout,
  VitalsCard,
} from '@hc/ui/components';
import { localStorageKeys, parseJwt, queryClient } from '@hc/utils';
import { Avatar, Box, Divider, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import isEqual from 'react-fast-compare';
import { toast } from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import { new_request_stepper_style } from './style';

export default function NewRequestStepper(props) {
  const { className = '', ...rest } = props;

  const navigate = useNavigate();

  const [templateOffset, setTemplateOffset] = useState(0);
  const [previousPlanOffset, setPreviousPlanOffset] = useState(0);
  const [openBackDrawer, setOpenBackDrawer] = useState(false);

  const observerTarget = useRef(null);

  const {
    requestState,
    getPlanCount,
    getTemplateDataById,
    getTemplateList,
    getReqDataLocalStorage,
    getPreviousPlanList,
    loading,
    updateUserStatus,
    templateLoading,
  } = useExpertRequest(
    (state) => ({
      updateUserStatus: state.updateUserStatus,
      getPreviousPlanList: state.getPreviousPlanList,
      getReqDataLocalStorage: state.getReqDataLocalStorage,
      getTemplateDataById: state.getTemplateDataById,
      getTemplateList: state.getTemplateList,
      requestState: state.requestState,
      getPlanCount: state.getPlanCount,
      loading: state.loading,
      templateLoading: state.templateLoading,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const {
    planAddEditToolState,
    onDurationUpdation,
    getPlanDetailsById,
    onUpdateToolData,
    clearPlanDetailes,
  } = usePlanAddEditTool(
    (state) => ({
      clearPlanDetailes: state.clearPlanDetailes,
      planAddEditToolState: state.planAddEditToolState,
      onDurationUpdation: state.onDurationUpdation,
      onUpdateToolData: state.onUpdateToolData,
      getPlanDetailsById: state.getPlanDetailsById,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const { planDurationData, error, expert } = planAddEditToolState;

  const { basicInfoState, onStateUpdate, getLocalStorage } = useBasicInfoStates(
    (state) => ({
      basicInfoState: state.basicInfoState,
      getLocalStorage: state.getLocalStorage,
      onStateUpdate: state.onStateUpdate,
    }),
    (prev, curr) => isEqual(prev, curr)
  );

  const { requestDataById, planCounts, planTemplateData, previousPlanData } =
    requestState;

  const [openDrawer, setOpenDrawer] = useState(false);
  const [openPreviousPlanDrawer, setOpenPreviousPlanDrawer] = useState(false);
  const [openPlanScratchDrawer, setOpenPlanScratchDrawer] = useState(false);
  const [drawerData, setDrawerData] = useState({
    plan_name: planDurationData?.plan_name,
    duration_type: planDurationData?.duration_type,
    duration: planDurationData?.duration,
  });
  const authToken = localStorage.getItem(localStorageKeys.authToken);
  const tokenData = parseJwt(authToken);

  const steps = ['Basic info', 'Q / A', 'Choose plan'];

  const icons = {
    1: 1,
    2: 2,
    3: 3,
  };

  const choosePlanData = [
    {
      type: 'Previous plan',
      cardImage: <ChoosePlanHistroyImage />,
      cardHeader: 'Choose from previous plans',
      cardContent: 'plans',
      count: planCounts?.previousPlanCount ?? 0,
    },
    {
      type: 'Scratch plan',
      cardImage: <ChoosePlanNewImage />,
      cardHeader: 'Create plan from scratch',
      cardContent: 'Create a custom plan based on client needs',
      count: '',
    },
    {
      type: 'Templates',
      cardImage: <ChoosePlanTemplateImage />,
      cardHeader: 'Choose plan from templates',
      cardContent: 'templates available',
      count: planCounts?.planTemplateCount ?? 0,
    },
  ];

  const filters = [
    {
      name: 'Days',
      value: 'Days',
    },
    {
      name: 'Weeks',
      value: 'Weeks',
    },
    {
      name: 'Months',
      value: 'Months',
    },
  ];

  const onNext = () => {
    if (basicInfoState?.steperValue === 1) {
      setOpenDrawer(true);
    } else {
      onStateUpdate('steperValue', basicInfoState?.steperValue + 1);
    }
  };

  const isValidToSave = () => {
    const errorCopy = JSON.parse(JSON.stringify(error));
    let isValid = true;

    // checking plan name
    if (!drawerData?.plan_name.length > 0) {
      isValid = false;
      errorCopy.plan_name = 'Please enter the plan name';
    } else {
      errorCopy.plan_name = '';
    }

    // checking plan duratio
    if (!drawerData?.duration_type > 0) {
      isValid = false;
      errorCopy.duration_type = 'Please select the duration type ';
    } else {
      errorCopy.duration_type = '';
    }

    // checking plan duration name
    if (!drawerData?.duration > 0) {
      isValid = false;
      errorCopy.duration = 'Please enter the duration';
    } else {
      errorCopy.duration = '';
    }
    onUpdateToolData('error', errorCopy);
    return isValid;
  };

  const onScratchPlanNext = async () => {
    const result = isValidToSave();
    if (result) {
      onDurationUpdation('plan_name', drawerData?.plan_name);
      onDurationUpdation('duration_type', drawerData?.duration_type);
      onDurationUpdation('duration', drawerData?.duration);
      setOpenDrawer(false);
      onUpdateToolData('isScratch', true);
      queryClient.invalidateQueries({
        queryKey: ['get-plan-data-by-plan-id'],
      });
      const result = await getPlanDetailsById(
        requestDataById?.id ?? '',
        'ScratchPlan'
      );
      if (result?.status?.code === 200) {
        navigate(expertRoutes.planAddEditTool);
      }
    }
  };

  const updateRequestStatus = async () => {
    queryClient.invalidateQueries({
      queryKey: ['expert-update-status'],
    });
    const result = await updateUserStatus(requestState?.requestDataById?.id, 1);
    if (result?.code === 200) {
      navigate(expertRoutes.home);
    }
  };

  const onLayoutBack = () => {
    setOpenBackDrawer(true);
  };

  const onBack = async () => {
    if (basicInfoState?.steperValue === 0) {
      setOpenBackDrawer(true);
    } else {
      onStateUpdate('steperValue', basicInfoState?.steperValue - 1);
    }
  };

  window.addEventListener('popstate', async () => {    
    if (basicInfoState?.steperValue === 0) {
      setOpenBackDrawer(true);
    }
  });

  const onProceed = async () => {
    queryClient.invalidateQueries({
      queryKey: [' expert-plan-count'],
    });
    const response = await getPlanCount(requestDataById?.mbf_users_id);
    if (response?.code === 200) {
      onStateUpdate('steperValue', basicInfoState?.steperValue + 1);
      setOpenDrawer(false);
    }
  };

  const onCancel = () => {
    setOpenDrawer(false);
  };

  const onChoosePlanClick = async (type) => {
    if (type === 'Scratch plan') {
      clearPlanDetailes();
      setOpenPlanScratchDrawer(true);
    }
    if (type === 'Previous plan') {
      if (planCounts?.previousPlanCount !== '0') {
        queryClient.invalidateQueries({
          queryKey: ['previous-plan-list'],
        });

        const response = await getPreviousPlanList(previousPlanOffset, false);
        if (response?.code === 200) {
          onStateUpdate('previousPlan', true);
        }
      } else {
        toast.error("You haven't any previous plan");
      }
    }
    if (type === 'Templates') {
      if (planCounts?.planTemplateCount !== '0') {
        queryClient.invalidateQueries({
          queryKey: ['expert-plan-template-list'],
        });
        onStateUpdate('planTemplate', true);
        await getTemplateList(templateOffset, false);
        // if (response?.code === 200) {

        // }
      } else {
        toast.error("You haven't any  plan templates");
      }
    }
  };

  const onPreviousCardClick = async (id) => {
    onUpdateToolData('isScratch', false);
    queryClient.invalidateQueries({
      queryKey: ['get-plan-data-by-plan-id'],
    });
    const result = await getPlanDetailsById(id ?? '', 'previousData');
    if (result?.status?.code === 200) {
      navigate(expertRoutes.editplan, {
        state: {
          isPlanEdit: false,
          isPublishPlan: false,
        },
      });
    }
  };

  const templateCardValidation = (object) => {
    let obj = object;
    let error = {
      isValid: true,
      message: '',
    };

    if (
      expert?.is_mind_expert &&
      !expert?.is_body_expert &&
      !expert?.is_food_expert
    ) {
      if (parseInt(obj.mindActivityCount, 10) === 0) {
        error.isValid = false;
        error.message =
          'Please select another template because the mind activity for this card is 0.';
      } else {
        error.message = '';
      }
    } else if (
      expert?.is_body_expert &&
      !expert?.is_mind_expert &&
      !expert?.is_food_expert
    ) {
      if (parseInt(obj.bodyActivityCount, 10) === 0) {
        error.isValid = false;
        error.message =
          'Please select another template because the body activity for this card is 0.';
      } else {
        error.message = '';
      }
    } else if (
      expert?.is_food_expert &&
      !expert?.is_body_expert &&
      !expert?.is_mind_expert
    ) {
      if (parseInt(obj.foodActivityCount, 10) === 0) {
        error.isValid = false;
        error.message =
          'Please select another template because the food activity for this card is 0.';
      } else {
        error.message = '';
      }
    } else if (
      expert?.is_mind_expert &&
      expert?.is_body_expert &&
      !expert?.is_food_expert
    ) {
      if (
        parseInt(obj.mindActivityCount, 10) === 0 &&
        parseInt(obj.bodyActivityCount, 10) === 0
      ) {
        error.isValid = false;
        error.message =
          'Please select another template because the mind and body activities for this card is 0.';
      } else {
        error.message = '';
      }
    } else if (
      expert?.is_mind_expert &&
      expert?.is_food_expert &&
      !expert?.is_body_expert
    ) {
      if (
        parseInt(obj.mindActivityCount, 10) === 0 &&
        parseInt(obj.foodActivityCount, 10) === 0
      ) {
        error.isValid = false;
        error.message =
          'Please select another template because the mind and food activities for this card is 0.';
      } else {
        error.message = '';
      }
    } else if (
      expert?.is_body_expert &&
      expert?.is_food_expert &&
      !expert?.is_mind_expert
    ) {
      if (
        parseInt(obj.bodyActivityCount, 10) === 0 &&
        parseInt(obj.foodActivityCount, 10) === 0
      ) {
        error.isValid = false;
        error.message =
          'Please select another template because the body and food activities for this card is 0.';
      } else {
        error.message = '';
      }
    } else if (
      expert?.is_mind_expert &&
      expert?.is_body_expert &&
      expert?.is_food_expert
    ) {
      if (
        parseInt(obj.mindActivityCount, 10) === 0 &&
        parseInt(obj.bodyActivityCount, 10) === 0 &&
        parseInt(obj.foodActivityCount, 10) === 0
      ) {
        error.isValid = false;
        error.message =
          'Please select another template because the mind,body and food activities for this card is 0.';
      } else {
        error.message = '';
      }
    }
    return error;
  };

  const onPlanTemplateCardClick = async (planId, object) => {
    onUpdateToolData('isScratch', false);
    const validation = templateCardValidation(object);
    if (validation?.isValid) {
      queryClient.invalidateQueries({
        queryKey: ['get-plan-data-by-plan-id'],
      });
      const result = await getPlanDetailsById(
        requestDataById?.id ?? '',
        'planTemplatePreviousData'
      );
      if (result?.status?.code === 200) {
        const response = await getTemplateDataById(planId, result?.data);
        if (response?.code === 200) {
          navigate(expertRoutes.editplan, {
            state: {
              isPlanEdit: false,
              isPublishPlan: false,
            },
          });
        }
      }
    } else {
      toast.error(validation?.message);
    }
  };

  const toFindBMICategory = (BMI_Value) => {
    if (parseFloat(BMI_Value) < 18.5) {
      return 'Underweight';
    }
    if (parseFloat(BMI_Value) > 18.5 && parseFloat(BMI_Value) < 25) {
      return 'Normal';
    }
    if (parseFloat(BMI_Value) > 25 && parseFloat(BMI_Value) < 30) {
      return 'Overweight';
    }
    if (parseFloat(BMI_Value) > 30) {
      return 'Obesity';
    }
    return 'Normal';
  };

  const getWhichExpert = () => {
    const obj = {
      is_mind_expert: tokenData?.is_mind_expert,
      is_body_expert: tokenData?.is_body_expert,
      is_food_expert: tokenData?.is_food_expert,
    };

    onUpdateToolData('expert', obj);
  };

  const handleChange = (value, limit) => {
    if (limit) {
      if (value.length > limit) {
        return;
      }
    }
    // HANDLE PROFILE DATA CAHNGE
    setDrawerData({
      ...drawerData,
      duration: value,
    });
  };

  useEffect(() => {
    getLocalStorage();
    getReqDataLocalStorage();
    getWhichExpert();
    clearPlanDetailes();
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          if (basicInfoState?.steperValue > 1) {
            if (
              basicInfoState?.steperValue === 2 &&
              basicInfoState?.planTemplate &&
              planTemplateData?.data?.length < planTemplateData?.dataCount
            ) {
              setTemplateOffset(templateOffset + 1);
              queryClient.invalidateQueries({
                queryKey: ['expert-plan-template-list'],
              });

              getTemplateList(templateOffset + 1, true);
            } else if (
              basicInfoState?.steperValue === 2 &&
              basicInfoState?.previousPlan &&
              previousPlanData.length < planCounts?.previousPlanCount
            ) {
              setPreviousPlanOffset(previousPlanOffset + 1);
              queryClient.invalidateQueries({
                queryKey: ['previous-plan-list'],
              });

              getPreviousPlanList(previousPlanOffset + 1, true);
            }
          }
        }
      },
      { threshold: 1 }
    );
    if (observerTarget.current) {
      observer.observe(observerTarget.current);
    }
    return () => {
      if (observerTarget.current) {
        observer.unobserve(observerTarget.current);
      }
    };
  }, [observerTarget, basicInfoState, previousPlanData, planTemplateData]);

  return (
    <Screenlayout
      title={
        basicInfoState?.previousPlan && basicInfoState?.steperValue === 2
          ? 'Choose from previous plan'
          : requestDataById?.mbf_user?.profile_data?.name ?? ''
      }
      isExpertAppBar
      previousPlan={basicInfoState?.previousPlan}
      planTemplate={basicInfoState?.planTemplate}
      backRequired
      updateRequestStatus={() => onLayoutBack()}
      setSteperValue={onStateUpdate}
      notshowFooter={
        basicInfoState?.steperValue === 2
          ? basicInfoState?.previousPlan
            ? !basicInfoState?.previousPlan
            : basicInfoState?.planTemplate
            ? !basicInfoState?.planTemplate
            : true
          : false
      }
      steperValue={basicInfoState?.steperValue}
      stepperformWithAppbar
      backRoute={expertRoutes?.home}
      backIconStyle={{ color: '#7A67D9' }}
      isShowChildFooter={
        basicInfoState?.steperValue === 0 &&
        requestDataById?.plan_type !== 'New'
      }
      isDrawerFooter
      footerStyle={{ boxShadow: 0 }}
      drawerChildren={
        <Box>
          {basicInfoState?.steperValue === 0 &&
            requestDataById?.plan_type !== 'New' && (
              <Grid container columnSpacing={2}>
                <Grid item xs={10} sm={10} md={10}>
                  <Grid container>
                    <Grid item xs={12} sm={12} md={12} display="flex">
                      <Typography color="#888888" fontSize={12}>
                        Previous plan •
                      </Typography>
                      <Typography
                        color="#25C460"
                        fontSize={12}
                        ml={1}
                      >{`Completion ${requestDataById?.overall_percentage}%`}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12}>
                      <Typography fontSize={14} fontWeight={500}>
                        {`${requestDataById?.plan_data?.plan_name} - ${requestDataById?.plan_data?.duration} ${requestDataById?.plan_data?.duration_type}`}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={2} sm={2} md={2}>
                  <Box
                    sx={{
                      transform: 'rotate(270deg)',
                      ml: '10px',
                      mt: '-9px',
                      cursor: 'pointer',
                    }}
                    onClick={() => setOpenPreviousPlanDrawer(true)}
                  >
                    <MembersRightIcon
                      circleFill="#F3F0FF"
                      arrowColor="#6552CC"
                    />
                  </Box>
                </Grid>
              </Grid>
            )}
        </Box>
      }
      footerdDrawerChildren={
        <Box>
          {!basicInfoState?.previousPlan && !basicInfoState?.planTemplate && (
            <Grid container columnSpacing={2}>
              <Grid item xs={6} sm={6} md={6}>
                <Button
                  onClick={onBack}
                  sx={new_request_stepper_style.buttonSx}
                >
                  Back
                </Button>
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <Button
                  onClick={onNext}
                  buttonStyle={{ textTransform: 'capitalize' }}
                >
                  Next
                </Button>
              </Grid>
            </Grid>
          )}
          {basicInfoState?.previousPlan && (
            <Box>
              <Button
                onClick={() => onStateUpdate('previousPlan', false)}
                sx={new_request_stepper_style.buttonSx}
              >
                Change Plan Type
              </Button>
            </Box>
          )}
          {basicInfoState?.planTemplate && (
            <Box>
              <Button
                onClick={() => onStateUpdate('planTemplate', false)}
                sx={new_request_stepper_style.buttonSx}
              >
                Change Plan Type
              </Button>
            </Box>
          )}
        </Box>
      }
      stepperChildren={
        <CommonSteper
          step={basicInfoState?.steperValue}
          icons={icons}
          stepText={steps}
        />
      }
    >
      <Box pt={14} pb={4} className={`${className}`} {...rest}>
        {basicInfoState?.steperValue === 0 && (
          <Grid container>
            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              display="flex"
              justifyContent="center"
            >
              <Avatar
                sx={{ height: '74px', width: '74px', alignSelf: 'center' }}
                src={requestDataById?.mbf_user?.profile_data?.profile_pic ?? ''}
              >
                {requestDataById?.mbf_user?.profile_data?.name.slice(0, 1) ??
                  'A'}
              </Avatar>
            </Grid>
            <Grid item xs={12} sm={12} md={12} textAlign="center">
              <Typography fontSize={18} mt={1.5} fontWeight={600}>
                {requestDataById?.mbf_user?.profile_data?.name ?? ''}
              </Typography>

              <Typography
                mt={0.8}
                fontSize={12}
                color="#888888"
              >{`${requestDataById?.mbf_user?.profile_data?.gender} / ${requestDataById?.mbf_user?.profile_data?.age} yrs`}</Typography>
            </Grid>
            <Grid p={2.5} item xs={12} sm={12} md={12}>
              <VitalsCard
                data={{
                  height: requestDataById?.mbf_user?.profile_data?.height ?? '',
                  weight: requestDataById?.mbf_user?.profile_data?.weight ?? '',
                  bmi: requestDataById?.mbf_user?.profile_data?.bmi ?? '',
                  blood:
                    requestDataById?.mbf_user?.profile_data?.master_blood_group
                      ?.id ?? '',
                  bloodGroupName:
                    requestDataById?.mbf_user?.profile_data?.master_blood_group
                      ?.blood_group ?? '',
                  bmiCategory:
                    requestDataById?.mbf_user?.profile_data?.height ?? ''
                      ? toFindBMICategory(
                          requestDataById?.mbf_user?.profile_data?.height ?? ''
                        )
                      : '',
                  blood_pressure: requestDataById?.mbf_user?.profile_data
                    ?.blood_pressure
                    ? requestDataById?.mbf_user?.profile_data?.blood_pressure
                    : '-',

                  temp_in_f: requestDataById?.mbf_user?.profile_data?.temp_in_f
                    ? requestDataById?.mbf_user?.profile_data?.temp_in_f
                    : '-',
                  puls_bpm: requestDataById?.mbf_user?.profile_data?.puls_bpm
                    ? requestDataById?.mbf_user?.profile_data?.puls_bpm
                    : '-',
                  o2_level: requestDataById?.mbf_user?.profile_data?.o2_level
                    ? requestDataById?.mbf_user?.profile_data?.o2_level
                    : '-',
                }}
              />
            </Grid>
            <Grid px={2.5} item xs={12} sm={12} md={12}>
              <HealthConditionChips
                userData={requestDataById?.mbf_user?.profile_data ?? {}}
              />
            </Grid>
          </Grid>
        )}

        {basicInfoState?.steperValue === 1 && (
          <Box px={2}>
            <QuestionAnsList
              data={requestDataById?.pre_evaluation_and_goals_json ?? {}}
            />
          </Box>
        )}

        {basicInfoState?.steperValue === 2 && (
          <Box px={2}>
            {!basicInfoState?.previousPlan && !basicInfoState?.planTemplate && (
              <Box>
                {choosePlanData.map((value, index) => (
                  <Box key={index} pb={1.5}>
                    <ExpertChoosePlanCard
                      onClick={() => onChoosePlanClick(value?.type)}
                      cardImage={value?.cardImage}
                      cardHeader={value?.cardHeader}
                      cardContent={value?.cardContent}
                      count={value?.count}
                    />
                  </Box>
                ))}
              </Box>
            )}

            {basicInfoState?.previousPlan && !basicInfoState?.planTemplate && (
              <Box>
                <Typography fontSize={12} color="#5C6266" mb={1}>
                  Choose from previous plans
                </Typography>
                {!loading && (
                  <Box>
                    {previousPlanData?.map((val, i) => (
                      <Box pb={2} key={i}>
                        <PreviousPlansCard
                          data={val}
                          onClick={() => onPreviousCardClick(val?.id)}
                        />
                      </Box>
                    ))}
                  </Box>
                )}

                {loading &&
                  Array(5)
                    .fill(1)
                    .map((i) => (
                      <Box key={i} py={0.5}>
                        <ExpertPreviousPlanSkeletonCard />
                      </Box>
                    ))}
              </Box>
            )}
            {!basicInfoState?.previousPlan && basicInfoState?.planTemplate && (
              <Box>
                <Typography fontSize={12} color="#5C6266" mb={1}>
                  {`${planTemplateData?.dataCount}- templates available`}
                </Typography>
                {planTemplateData?.data?.map((val, i) => (
                  <Box pb={2} key={i}>
                    <ExpertPlanTemplateCard
                      data={val}
                      onClick={() => onPlanTemplateCardClick(val?.id, val)}
                    />
                  </Box>
                ))}

                {templateLoading &&
                  Array(5)
                    .fill(1)
                    .map((i) => (
                      <Box key={i} py={0.5}>
                        <ExpertTemplateCardSkeleton />{' '}
                      </Box>
                    ))}
              </Box>
            )}
            <div
              ref={observerTarget}
              style={{ marginTop: '-20px', opacity: 0 }}
            >
              intersection
            </div>
          </Box>
        )}
      </Box>

      {/* Plan proceed Drawer */}
      <Drawer
        show={openDrawer}
        rootStyle={new_request_stepper_style.drawerSx}    
        childrenStyle={{ p: 0 }}
        onCloseDrawer={() => setOpenDrawer(false)}
        isStyledDrawer
        IsStyledDrawerContent={
          <Typography fontSize={14}>
            Proceeding further will allot
            <span style={{ fontWeight: 600 }}>
              {' '}
              {requestDataById?.mbf_user?.profile_data?.name}&nbsp;
            </span>
            as your client. Do you wish to create plan?
          </Typography>
        }
        isStyledDrawerRootSx={{ border: 0 }}
        headerStyle={{
          fontSize: '16px',
          fontWeight: 600,
          color: '#101010',
          textTransform: 'capitalize',
        }}  
        footer={
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button
              onClick={onCancel}
              disabled={loading === true}
              buttonStyle={new_request_stepper_style.cancelBtnSx}
            >
              Cancel
            </Button>
            <Button
              onClick={onProceed}
              loading={loading}
              buttonStyle={new_request_stepper_style.applyBtnSx}
            >
              Proceed
            </Button>
          </Box>
        }
      />

      {/* Previous plan Drawer */}
      <Drawer
        show={openPreviousPlanDrawer}
        rootStyle={new_request_stepper_style.drawerSx}
        header="Previous plan"
        drawerRightClose
        childrenStyle={{ p: 0 }}
        onCloseDrawer={() => setOpenPreviousPlanDrawer(false)}
        headerStyle={{
          fontSize: '16px',
          fontWeight: 600,
          color: '#101010',
          textTransform: 'capitalize',
        }}
        closeStyle={{ color: 'grey', height: '14px', width: '12px' }}
      >
        <Box p={2} sx={{ height: 450, overflowX: 'scroll' }}>
          <Typography pb={1.5} fontSize={14} fontWeight={500}>
            {`${requestDataById?.plan_data?.plan_name} - ${requestDataById?.plan_data?.duration} ${requestDataById?.plan_data?.duration_type}`}
          </Typography>
          <Box pb={2}>
            <ExpertPreviousPlanCard
              overAllProgress={requestDataById?.overall_percentage ?? 0}
              data={requestDataById?.mbf_percentage ?? []}
            />
          </Box>
          <Box pb={1}>
            <Typography color="#888888" fontSize={12} fontWeight={500}>
              Client feedback on plan
            </Typography>
          </Box>
          <Box pb={1.5}>
            <QuestionAnsList data={requestDataById?.feedback_json ?? {}} />
          </Box>
        </Box>
      </Drawer>

      {/* Plan from scratch drawer */}

      <Drawer
        show={openPlanScratchDrawer}
        rootStyle={new_request_stepper_style.drawerSx}
        header="Plan from scratch"
        drawerRightClose
        childrenStyle={{ p: 0 }}
        onCloseDrawer={() => setOpenPlanScratchDrawer(false)}
        headerStyle={{
          fontSize: '16px',
          fontWeight: 600,
          color: '#101010',
          textTransform: 'capitalize',
        }}
        closeStyle={{ color: 'grey', height: '14px', width: '12px' }}
        footer={
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button
              onClick={() => setOpenPlanScratchDrawer(false)}
              disabled={loading === true}
              buttonStyle={new_request_stepper_style.cancelBtnSx}
            >
              Cancel
            </Button>
            <Button
              onClick={() => onScratchPlanNext()}
              loading={loading}
              buttonStyle={new_request_stepper_style.applyBtnSx}
            >
              Next
            </Button>
          </Box>
        }
      >
        <Box p={2}>
          <Grid container>
            <Grid item xs={12} pb={2} sm={12} md={12}>
              <Label>Name of the plan</Label>
              <Box mt={0.5}>
                <Input
                  value={drawerData?.plan_name}
                  onChange={(e) =>
                    setDrawerData({ ...drawerData, plan_name: e.target?.value })
                  }
                  placeholder="Give a name to identify in future"
                  isError={error?.plan_name?.length > 0}
                  errorMessage={error?.plan_name}
                />
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={12}>
              <Label>Plan Duration</Label>
              <Box display="flex">
                <Box width={161} mt={0.5}>
                  <Input
                    value={drawerData?.duration}
                    placeholder="Type duration"
                    type="number"
                    onChange={(e) => handleChange(e.target.value, 2)}
                    isError={error?.duration?.length > 0}
                    errorMessage={error?.duration}
                  />
                </Box>
                <Box px={0.5}>
                  <Divider
                    orientation="vertical"
                    sx={{ height: '32px', mt: 1 }}
                  />
                </Box>

                <Box>
                  <Grid container display="flex" columnSpacing={1}>
                    <Grid item>
                      <ToggleButton
                        options={filters}
                        value={drawerData?.duration_type}
                        RootStyle={{ overflow: 'scroll' }}
                        buttonactiveStyle={
                          new_request_stepper_style.buttonActiveNumSx
                        }
                        buttoninactiveStyle={
                          new_request_stepper_style.buttonInactiveNumSx
                        }
                        onChange={(e) =>
                          setDrawerData({
                            ...drawerData,
                            duration_type: e,
                          })
                        }
                      />

                      {error?.duration_type.length > 0 && (
                        <Typography fontSize={12} color="	#ff3333">
                          {error?.duration_type}
                        </Typography>
                      )}
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Drawer>
      <Drawer
        show={openBackDrawer}
        rootStyle={new_request_stepper_style.drawerSx}
        childrenStyle={{ p: 0 }}
        onCloseDrawer={() => setOpenBackDrawer(false)}
        isStyledDrawer
        IsStyledDrawerContent={
          <Typography mb={2} fontSize={14}>
            Are you sure you want to move this plan to request ?
          </Typography>
        }
        headerStyle={{
          fontSize: '16px',
          fontWeight: 600,
          color: '#101010',
          textTransform: 'capitalize',
        }}
        footer={
          <Box sx={{ display: 'flex', gap: 2 }}>
            <Button
              onClick={() => setOpenBackDrawer(false)}
              buttonStyle={new_request_stepper_style.cancelBtnSx}
            >
              Cancel
            </Button>
            <Button
              onClick={() => updateRequestStatus()}
              buttonStyle={new_request_stepper_style.applyBtnSx}
            >
              Ok
            </Button>
          </Box>
        }
      />
    </Screenlayout>
  );
}

NewRequestStepper.propTypes = {
  className: PropTypes.string,
};
